import React from 'react'
import Map from '../../components/common/map/Map'
import SEO from '../../components/common/seo/SEO'
import { useViews } from '../../hooks/views/useViews'
import { Box, Grid, Typography, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Clients from '../../components/common/clients/Clients'
import Banderole from '../../components/common/banderoles/Banderole'
import { useBanderole } from '../../hooks/banderole/useBanderoles'
import Comity from '../../components/estiam/Comity'
import ComityMembers from '../../components/estiam/ComityMembers'
import Formations from '../../components/estiam/Formations'
import EstiamPresentation from '../../components/estiam/EstiamPresentation'
import colors from '../../assets/styles/styles.module.scss'

const useStyles = makeStyles()({
  title: {
    position: 'relative',
    textTransform: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    fontWeight: 'bold',
  },
})

export default function EstiamPage() {
  const { view } = useViews('ESTIAM')
  const { metaTitle, metaDescription } = view ?? {}
  const { classes } = useStyles()
  const { pageBanderole } = useBanderole('ESTIAM')
  const { imageUrl, title } = pageBanderole ?? {}

  return (
    <>
      <SEO
        metaDescription={
          metaDescription ??
          "L'école informatique éstiam propose deux titres, Bac+3 et Bac+5 ! Des formations innovantes tournées vers la professionnalisation."
        }
        metaTitle={metaTitle ?? "éstiam, école d'informatique et du numérique"}
      />
      <Banderole
        customImageUrl={imageUrl}
        customTitle={title}
        displayContactUsButton={false}
        category={'NOTRE ÉCOLE'}
      />

      <Container maxWidth='lg'>
        <EstiamPresentation />
        <Formations />
        <Comity />
      </Container>

      <ComityMembers />

      <Box mt={10} sx={{ background: colors.purpleGradient }}>
        <Grid container justifyContent='center'>
          <Typography color={colors.white} className={classes.title} mt={6} mb={6}>
            Les entreprises partenaires
          </Typography>
        </Grid>

        <Clients title={false} partnerType={'ESTIAM'} />
      </Box>
      <Map zoom={5} focus={[46.83103516976079, 2.620461836373336]} />
    </>
  )
}
