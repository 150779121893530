import React from 'react'
import RouterLink from '../common/router-link/RouterLink'
import { Button, Grid, Typography } from '@mui/material'
import classes from './JobOfferCard.module.scss'

interface IJobOfferCard {
  slug: string
  title: string
  jobLocation?: string
  category: string
}

export default function JobOfferCard({ slug, title, jobLocation, category }: IJobOfferCard) {
  return (
    <Grid
      component={RouterLink}
      to={`/offre-demploi-42c/${slug}`}
      container
      className={classes.box}
      justifyContent='center'
      alignItems='center'
      item
    >
      <Grid item container>
        <Grid
          container
          sx={{
            height: '100px',
          }}
          alignItems='center'
        >
          <Typography
            color='textPrimary'
            variant='body1'
            mb={1}
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} sx={{ height: '80px' }} alignItems='center'>
          <Grid item xs={6} sm={5}>
            <Button size='small' variant='contained' className={classes.button}>
              Découvrir
            </Button>
          </Grid>
          <Grid item xs={6} sm={7}>
            <Grid item ml={1}>
              <Typography
                variant='body2'
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: jobLocation + ' - ' + category,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
