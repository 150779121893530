import React from 'react'

import { Grid, Typography, IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import colors from '../../../assets/styles/styles.module.scss'
import { FAQ as FAQType } from '../../../shared/types/job-offer/JobOffer'

interface IFAQ {
  FAQs: FAQType[]
}

export default function FAQ({ FAQs }: IFAQ) {
  const toggle = async (id: number) => {
    const reponse = document.getElementById('reponse' + id)
    const currentVal = reponse?.style.display

    if (reponse) {
      currentVal == 'block' ? (reponse.style.display = 'none') : (reponse.style.display = 'block')
    }
  }

  return (
    <Grid container mt={6} ml={0}>
      <Grid item xs={12} sx={{ border: `2px solid ${colors.orange}` }}>
        <Grid sx={{ borderBottom: `2px solid ${colors.orange}` }} p={2}>
          <Typography variant='body1' fontWeight='bold' color='textPrimary'>
            Questions et réponses sur l'offre
          </Typography>
        </Grid>

        {FAQs.map(({ _id: key, question, response }, id) => {
          return (
            <Grid
              container
              key={key}
              alignItems='center'
              sx={
                FAQs.length == id + 1
                  ? { padding: 2 }
                  : { borderBottom: '1px solid black', padding: 2 }
              }
            >
              <Grid item xs={11}>
                <Typography
                  variant='body2'
                  color='black'
                  fontWeight='bold'
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => toggle(id)}>
                  <KeyboardArrowDownIcon style={{ fontSize: '15px' }} />
                </IconButton>
              </Grid>
              <Grid id={'reponse' + id} sx={{ display: 'none', transform: 'rotate(0deg)' }}>
                <Typography
                  variant='body2'
                  color='black'
                  mt={3}
                  dangerouslySetInnerHTML={{
                    __html: response,
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
