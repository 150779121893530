import useSWR from 'swr'
import { MapMarker } from '../../shared/types/map-marker/MapMarker'

const FETCH_MAP_MARKERS_URL = `${process.env.REACT_APP_BASE_URL}/map-markers`

export async function fetchAllMapMarkers(): Promise<MapMarker[]> {
  const response = await fetch(`${FETCH_MAP_MARKERS_URL}/all`)
  return await response.json()
}

export function useMapMarkers() {
  const { data: mapMarkers } = useSWR<MapMarker[]>('api/map-markers/all', fetchAllMapMarkers)

  return { mapMarkers }
}
