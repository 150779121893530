import React, { useEffect, useState } from 'react'
import RouterLink from '../common/router-link/RouterLink'

import { Button, Grid, Typography, useMediaQuery } from '@mui/material'

import { theme } from '../../assets/themes/theme'
import { useLocation } from 'react-router-dom'
import classes from './ActivitySectorCard.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IActivitySectorCard {
  slug: string
  subTitle: string
  subDescription?: string
  imageUrl: string
  gifUrl: string
  highlight: boolean
}

export default function ActivitySectorCard({
  slug,
  subTitle,
  subDescription,
  imageUrl,
  gifUrl,
  highlight,
}: IActivitySectorCard) {
  const location = useLocation()

  const [img, setImg] = useState(imageUrl)

  const [displayedSubDescription, setDisplayedSubDescription] = useState('')

  const isSmFormat = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (subDescription) {
      setDisplayedSubDescription(subDescription)
    }
  }, [subDescription])

  return (
    <Grid
      container
      item
      component={RouterLink}
      to={`${location.pathname}/${slug}`}
      xl={10}
      xs={12}
      sx={{ margin: 'auto' }}
      onMouseEnter={async () => setImg(gifUrl)}
      onMouseLeave={async () => setImg(imageUrl)}
    >
      <Grid
        item
        sm={6}
        xs={12}
        sx={{ position: 'relative', cursor: 'pointer' }}
        className={isSmFormat ? '' : classes.cardHeight}
      >
        {imageUrl && (
          <LazyLoadImage
            effect='opacity'
            height='100%'
            width='100%'
            style={{ objectFit: 'cover' }}
            src={img}
            alt={"Image du secteur d'activité : " + subTitle}
          />
        )}
      </Grid>
      <Grid
        container
        alignContent='center'
        item
        sm={6}
        xs={12}
        className={highlight ? classes.cardTextSBS : classes.cardText}
      >
        <Grid container alignItems='center'>
          <Typography
            variant='h6'
            className={highlight ? classes.subTitleSBS : classes.subTitle}
            dangerouslySetInnerHTML={{
              __html: subTitle,
            }}
          />
        </Grid>
        <Grid container alignItems='center'>
          <Typography
            variant='body2'
            mt={1}
            className={highlight ? classes.subDescriptionSBS : classes.subDescription}
            dangerouslySetInnerHTML={{
              __html: displayedSubDescription,
            }}
          />
        </Grid>
        <Button
          size='small'
          variant='contained'
          className={highlight ? classes.btnSBS : classes.button}
        >
          Découvrir
        </Button>
      </Grid>
    </Grid>
  )
}
