import React from 'react'

import { Button, Link, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../../assets/styles/styles.module.scss'

interface IActualitiesBtn {
  link: string
  text: string
}

const useStyles = makeStyles()({
  button: {
    padding: '7px',
    minHeight: '30px',
    width: '100%',
    borderRadius: 0,
    color: colors.white,
    border: `1.5px solid ${colors.white}`,
    position: 'relative',
    fontWeight: 'bold',
    fontFamily: 'IBMPlexSans',
    textTransform: 'uppercase',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      backgroundColor: colors.white,
      border: `1.5px solid ${colors.white}`,
    },
  },
})

export default function ActualitiesBtn({ link, text }: IActualitiesBtn) {
  const { classes } = useStyles()

  return (
    <Grid item xs={10} sm={8}>
      <Link href={link} target='_blank' underline='none'>
        <Button variant='outlined' className={classes.button}>
          {text}
        </Button>
      </Link>
    </Grid>
  )
}
