import React from 'react'

import { Container, Grid, useMediaQuery } from '@mui/material'
import FooterSocial from '../components/common/footer/FooterSocial'
import FooterMenu from '../components/common/footer/FooterMenu'
import FilialesLogos from '../components/common/footer/FilialesLogos'
import EcovadisLogo from '../components/common/footer/EcovadisLogo'
import Copyright from '../components/common/footer/Copyright'
import colors from '../assets/styles/styles.module.scss'
import { theme } from '../assets/themes/theme'

export default function Footer() {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <footer
      style={{
        padding: '10px',
        background: colors.orangeGradient,
        color: colors.white,
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '1500px' }}>
        <Grid container mt={2}>
          <FooterSocial />
          <FooterMenu />
          <FilialesLogos />
          {!isMobileFormat && <EcovadisLogo />}
          <Copyright />
        </Grid>
      </Container>
    </footer>
  )
}
