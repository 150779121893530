import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

interface IParagraph {
  text: string
  textColor: 'black' | 'white'
  title?: string
}

export default function Paragraph({ text, title, textColor }: IParagraph) {
  const [displayedTitle, setDisplayedTitle] = useState('')
  const [displayedText, setDisplayedText] = useState('')

  useEffect(() => {
    setDisplayedText(text ?? '')
  }, [text])

  useEffect(() => {
    setDisplayedTitle(title ?? '')
  }, [title])

  return (
    <Box mb={3}>
      {title && (
        <Typography
          variant='h4'
          fontWeight='bold'
          color='textPrimary'
          dangerouslySetInnerHTML={{
            __html: displayedTitle,
          }}
        />
      )}
      <Typography
        mt={title ? 4 : 0}
        variant='body2'
        color={title ? 'black' : textColor === 'white' ? '#FFF' : 'black'}
        dangerouslySetInnerHTML={{ __html: displayedText ?? '' }}
      />
    </Box>
  )
}
