import React from 'react'
import IconButton from '@mui/material/IconButton'

interface ISocialLogo {
  link: string
  children: React.ReactNode
}

export default function SocialLogo({ link, children }: ISocialLogo) {
  return (
    <IconButton
      style={{
        display: 'flex',
        cursor: 'pointer',
      }}
      href={link}
      target='_blank'
      size='small'
      color='inherit'
    >
      {children}
    </IconButton>
  )
}
