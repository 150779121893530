import React, { useEffect, useState } from 'react'

import {
  Grid,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Autocomplete,
  TextField,
  SelectChangeEvent,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import { useJobOffers, useJobOffersFilter } from '../../../hooks/job-offers/useJobOffers'
import { uniq } from 'lodash'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../assets/styles/styles.module.scss'

export const useStyles = makeStyles()({
  inputs: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
  },
  noBorder: {
    border: 'none',
    outline: 'none',
    borderRadius: '0px',
  },
  border: {
    border: `1px solid ${colors.white}`,
    outline: `1px solid ${colors.white}`,
    borderRadius: '0px',
  },
  inputCV: {
    color: colors.white,
  },
  input: {
    color: 'black',
  },

  option: {
    color: 'black',
    '&[data-focus="true"]': {
      backgroundColor: 'black',
      color: colors.red,
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#eeeeee',
      color: colors.red,
    },
  },
})

type filterJobOfferFormType = {
  search: string
  jobLocation: string
  category: string
}

const initFilterJobOfferForm = {
  search: '',
  jobLocation: '',
  category: '',
}

interface IJobOffersFilter {
  matchingCV?: boolean
}

export default function JobOffersFilter({ matchingCV }: IJobOffersFilter) {
  const { classes } = useStyles()

  const { jobOffers } = useJobOffers()

  const [filterJobOfferValues, setFilterJobOfferValues] =
    useState<filterJobOfferFormType>(initFilterJobOfferForm)

  const { mutateFilterJobOffer } = useJobOffersFilter(filterJobOfferValues)

  const getFilteredOffers = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    setFilterJobOfferValues({
      ...filterJobOfferValues,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    if (filterJobOfferValues) {
      mutateFilterJobOffer()
    }
  }, [filterJobOfferValues, mutateFilterJobOffer])

  return (
    <form style={{ width: '100%' }}>
      {!matchingCV && (
        <Grid
          item
          container
          pt={5}
          pb={8}
          sx={{
            textAlign: 'center',
            background: 'linear-gradient(25deg, #ff0033, #ff5500)',
          }}
          xs={12}
        >
          <Grid item xs={12} mt={1} mb={3}>
            <Typography variant='h5' color='white' fontWeight='bold'>
              Découvrez les offres d'emploi
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={9}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            mt={2}
            mb={2}
            justifyContent='center'
            alignContent='center'
            sx={{ margin: 'auto' }}
          >
            <Grid item xs={12} sm={10} md={4} lg={5} xl={4} ml={0.3} mr={0.3} mt={1} mb={1}>
              <FormControl className={classes.inputs}>
                <TextField
                  onChange={getFilteredOffers}
                  variant='outlined'
                  sx={{ backgroundColor: 'white' }}
                  placeholder='Le poste recherché'
                  autoFocus
                  name='search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon sx={{ color: 'black' }} />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.noBorder,
                      input: classes.input,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} md={2} lg={2} xl={2} ml={0.3} mr={0.3} mt={1} mb={1}>
              <FormControl sx={{ width: '15%' }} className={classes.inputs} variant='standard'>
                <Autocomplete
                  sx={{ backgroundColor: 'white' }}
                  freeSolo
                  onInputChange={(event, newInputValue: string) => {
                    setFilterJobOfferValues({
                      ...filterJobOfferValues,
                      jobLocation: newInputValue,
                    })
                  }}
                  options={uniq(jobOffers?.map(({ jobLocation }) => jobLocation))}
                  classes={{ option: classes.option }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onChange={getFilteredOffers}
                        name='jobLocation'
                        variant='outlined'
                        {...params}
                        fullWidth
                        placeholder='Localisation'
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position='start'>
                                <LocationOnOutlinedIcon sx={{ color: 'black' }} />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                          classes: {
                            notchedOutline: classes.noBorder,
                            input: classes.input,
                          },
                        }}
                      />
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} md={2} lg={2} xl={2} ml={0.3} mr={0.3} mt={1} mb={1}>
              <FormControl className={classes.inputs} sx={{ width: '15%' }}>
                <Select
                  displayEmpty
                  name='category'
                  input={<OutlinedInput />}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 0,
                    color: '#9e9e9e',
                  }}
                  onChange={getFilteredOffers}
                  defaultValue={'x'}
                >
                  <MenuItem value='x' disabled className={classes.input}>
                    Type de contrat
                  </MenuItem>
                  <MenuItem value='' className={classes.input}>
                    Tout
                  </MenuItem>
                  {uniq(jobOffers?.map(({ category }) => category)).map((category) => (
                    <MenuItem key={category} value={category} className={classes.input}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
      {matchingCV && (
        <Grid container justifyContent='center' direction='column'>
          <Grid
            container
            item
            xs={12}
            sx={{ padding: '25px', background: colors.red }}
            justifyContent='center'
            direction='column'
            mb={2}
          >
            <Grid item xs={12}>
              <Typography color='white' fontWeight='bold' variant='body1' fontSize='16px' mb={2}>
                Localisation
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={9}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              mt={2}
              mb={2}
              justifyContent='center'
              alignContent='center'
              sx={{ margin: 'auto' }}
            >
              <Grid item xs={12} mt={1}>
                <FormControl fullWidth className={classes.inputs} variant='standard'>
                  <Autocomplete
                    sx={{
                      backgroundColor: 'transparent',
                      color: 'white',
                    }}
                    freeSolo
                    onInputChange={(event, newInputValue: string) => {
                      setFilterJobOfferValues({
                        ...filterJobOfferValues,
                        jobLocation: newInputValue,
                      })
                    }}
                    options={uniq(jobOffers?.map(({ jobLocation }) => jobLocation))}
                    classes={{ option: classes.option }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          onChange={getFilteredOffers}
                          name='jobLocation'
                          variant='outlined'
                          {...params}
                          fullWidth
                          size='small'
                          placeholder='Rechercher'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                <InputAdornment position='start'>
                                  <LocationOnOutlinedIcon sx={{ color: colors.white }} />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                            classes: {
                              notchedOutline: classes.border,
                              input: classes.inputCV,
                            },
                          }}
                        />
                      )
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{ padding: '25px', background: colors.red }}
            justifyContent='center'
            direction='column'
            my={2}
          >
            <Grid item xs={12}>
              <Typography color='white' fontWeight='bold' variant='body1' fontSize='16px' mb={2}>
                Type de contrat
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={9}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              mt={2}
              mb={2}
              justifyContent='center'
              alignContent='center'
              sx={{ margin: 'auto' }}
            >
              <Grid item xs={12} mt={1}>
                <FormControl className={classes.inputs} fullWidth>
                  <Select
                    displayEmpty
                    size='small'
                    name='category'
                    input={<OutlinedInput />}
                    sx={{
                      backgroundColor: 'transparent',
                      borderRadius: 0,
                      border: `1px solid ${colors.white}`,
                      color: '#ffffff73',
                    }}
                    onChange={getFilteredOffers}
                    defaultValue={'x'}
                  >
                    <MenuItem value='x' disabled className={classes.input}>
                      Contrat recherché
                    </MenuItem>
                    <MenuItem value='' className={classes.input}>
                      Tout
                    </MenuItem>
                    {uniq(jobOffers?.map(({ category }) => category)).map((category) => (
                      <MenuItem key={category} value={category} className={classes.input}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  )
}
