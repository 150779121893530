import RouterLink from '../router-link/RouterLink'
import { Button, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../assets/styles/styles.module.scss'
import { theme } from '../../../assets/themes/theme'

interface IBanderoleButton {
  href: string
  text: string
}

export default function BanderoleButton({ href, text }: IBanderoleButton) {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const useStyles = makeStyles()(() => {
    return {
      button: {
        position: 'relative',
        background: colors.orangeGradient,
        fontFamily: 'IBMPlexSans',
        padding: '5px 10px',
        height: '41px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: 0,
        textTransform: 'uppercase',
        width: isMobileFormat ? '100%' : '',
        '&:hover': {
          color: colors.orange,
          boxShadow: 'none',
          background: '#fff',
          border: `1px solid ${colors.orange}`,
        },
      },
    }
  })

  const { classes } = useStyles()
  return (
    <Button variant='outlined' className={classes.button} component={RouterLink} to={href}>
      {text}
    </Button>
  )
}
