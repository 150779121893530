import React, { useEffect, useState } from 'react'

import {
  Alert,
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { theme } from '../../assets/themes/theme'

import { ContactForm as ContactFormType } from '../../shared/types/contact/ContactForm'
import { useContact } from '../../hooks/contact/useContact'

import { makeStyles } from 'tss-react/mui'
import colors from '../../assets/styles/styles.module.scss'
import ReactGA from 'react-ga4'

export const useStyles = makeStyles()({
  contactInput: {
    '& input': {
      color: 'black',
      borderBottom: ' 1px solid black',
    },
    '& label': { color: 'gray' },
    width: '80%',
  },

  messageInput: {
    width: '90% !important',
    marginTop: '5px',
    '& fieldset': {
      borderRadius: '0px',
      borderColor: 'black',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      color: 'black',
    },
  },

  contactInputMobile: {
    '& input': {
      color: 'black',
      borderBottom: ' 1px solid black',
    },
    '& label': { color: 'gray' },
    width: '90%',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '0px !important',
  },

  buttonText: {
    background: colors.orangeGradient,
    boxShadow: 'none',
    color: colors.white,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    padding: '5px',
    marginTop: '10px',
    height: '42px',
    width: '110px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: 0,
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
})

const initialFormValues: ContactFormType = {
  firstName: '',
  lastName: '',
  email: '',
  object: '',
  message: '',
}

export default function ContactForm() {
  const { addContact } = useContact()

  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  const [contactFormValues, setContactFormValues] = useState<ContactFormType>(initialFormValues)

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)

  const [isDisplayedAlert, setIsDisplayedAlert] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success')
  const [alertMessage, setAlertMessage] = useState<string>('')

  async function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setContactFormValues({
      ...contactFormValues,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    setDisabledSubmitButton(false)
    Object.values(contactFormValues).forEach((value) => {
      if (!value.length) {
        setDisabledSubmitButton(true)
      }
    })
  }, [contactFormValues])

  async function submitHandler() {
    const response = await addContact(contactFormValues)

    if (response.statusCode) {
      setIsDisplayedAlert(true)
      setAlertSeverity('error')
      setAlertMessage(
        response.message == 'email must be an email'
          ? 'Veuillez saisir une adresse email valide !'
          : response.message == 'Internal server error'
          ? 'Erreur serveur interne'
          : response.message
      )
    } else {
      setIsDisplayedAlert(true)
      setAlertSeverity('success')
      setAlertMessage('Votre message a été bien envoyé ')
      setContactFormValues(initialFormValues)
      ReactGA.event({
        action: 'contact_form',
        category: 'CONTACT',
        label: 'contact_form',
      })
    }
  }

  return (
    <Container maxWidth='xl' disableGutters sx={{ marginBottom: '100px' }}>
      <Grid container justifyContent='center'>
        <FormControl>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xl={11} xs={10}>
              <Collapse in={isDisplayedAlert} sx={{ width: '90%', margin: 'auto' }}>
                <Alert severity={alertSeverity}>{alertMessage}</Alert>
              </Collapse>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid container item spacing={4} xl={11} xs={10}>
              <Grid container item xs={6} justifyContent='center' alignItems='center'>
                <TextField
                  value={contactFormValues.firstName}
                  id='standard-basic'
                  label='PRÉNOM'
                  required
                  name='firstName'
                  onChange={onChangeHandler}
                  variant='standard'
                  className={classes.contactInput}
                />
              </Grid>
              <Grid container item xs={6} justifyContent='center' alignItems='center'>
                <TextField
                  value={contactFormValues.lastName}
                  id='standard-basic'
                  label='NOM'
                  required
                  name='lastName'
                  onChange={onChangeHandler}
                  variant='standard'
                  className={classes.contactInput}
                />
              </Grid>
              <Grid
                container
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                justifyContent='center'
                alignItems='center'
              >
                <TextField
                  value={contactFormValues.email}
                  id='standard-basic'
                  label='ADRESSE MAIL'
                  name='email'
                  required
                  onChange={onChangeHandler}
                  variant='standard'
                  className={isMobileFormat ? classes.contactInputMobile : classes.contactInput}
                />
              </Grid>
              <Grid
                container
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                justifyContent='center'
                alignItems='center'
              >
                <TextField
                  value={contactFormValues.object}
                  id='standard-basic'
                  label='OBJET'
                  required
                  name='object'
                  onChange={onChangeHandler}
                  variant='standard'
                  className={isMobileFormat ? classes.contactInputMobile : classes.contactInput}
                />
              </Grid>
              <Grid container item xs={12} justifyContent='center' alignItems='center'>
                <Grid item xs={11} ml={{ xl: 2.4, lg: 2, md: 1.5, sm: 1.3, xs: 0.5 }}>
                  <Typography textAlign='left' color='black' variant='body1'>
                    MESSAGE
                  </Typography>
                </Grid>
                <TextField
                  value={contactFormValues.message}
                  className={classes.messageInput}
                  name='message'
                  required
                  onChange={onChangeHandler}
                  multiline
                  minRows={6}
                  maxRows={6}
                />
              </Grid>
              <Grid container item xs={12} justifyContent='center' alignItems='center'>
                <Grid item xs={11} ml={{ xl: 2.4, lg: 2, md: 1.5, sm: 1.3, xs: 0.5 }}>
                  <Button
                    onClick={submitHandler}
                    disabled={disabledSubmitButton}
                    variant='contained'
                    className={classes.buttonText}
                  >
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Container>
  )
}
