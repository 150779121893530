/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import Map from '../../components/common/map/Map'
import JobOffersBottomBanderole from '../../components/common/job-offers/JobOffersBottomBanderole'
import JobOfferCard from '../../components/job-offers/JobOfferCard'
import { Grid, Box, Typography } from '@mui/material'
import MatchCVResultBanderole from '../../components/match-cv-result/MatchCVResultBanderole'
import InfiniteScroll from 'react-infinite-scroller'
import { JobOffer } from '../../shared/types/job-offer/JobOffer'
import JobOffersFilter from '../../components/job-offers/common/JobOffersFilter'
import SEO from '../../components/common/seo/SEO'
import { useLocation } from 'react-router-dom'

export default function MatchCVResultPage() {
  const [hasMore, setHasMore] = React.useState(true)
  const [displayedJobOffers, setDisplayedJobOffers] = React.useState<JobOffer[]>([])

  const location = useLocation()
  const filteredJobOffers: JobOffer[] = location.state.filteredJobOffers

  function displayMore() {
    if (filteredJobOffers) {
      const toAddElementsLength =
        filteredJobOffers.length - displayedJobOffers.length > 10
          ? 10
          : filteredJobOffers.length - displayedJobOffers.length

      if (displayedJobOffers.length < filteredJobOffers.length) {
        setTimeout(() => {
          setDisplayedJobOffers([
            ...displayedJobOffers,
            ...filteredJobOffers?.slice(
              displayedJobOffers.length,
              displayedJobOffers.length + toAddElementsLength
            ),
          ])
        }, 1500)
      } else {
        setHasMore(false)
      }
    }
  }

  React.useEffect(() => {
    if (filteredJobOffers) {
      if (filteredJobOffers.length <= 10) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setDisplayedJobOffers([...filteredJobOffers?.slice(0, 10)])
    }
  }, [filteredJobOffers])

  return (
    <>
      <SEO />
      <Grid container>
        <MatchCVResultBanderole offersLength={filteredJobOffers ? filteredJobOffers.length : 0} />
        <Grid
          container
          item
          xl={6}
          lg={8}
          xs={10}
          justifyContent='center'
          m={'3rem auto 5rem auto'}
          spacing={2}
        >
          <Grid
            container
            item
            lg={4}
            md={6}
            xs={12}
            justifyContent='center'
            alignItems={'flex-start'}
          >
            <JobOffersFilter matchingCV />
          </Grid>
          <Grid container item lg={8} md={6} xs={12} justifyContent='center'>
            <InfiniteScroll
              loadMore={displayMore}
              hasMore={hasMore}
              loader={
                filteredJobOffers ? (
                  <Box display='flex' alignItems='center' justifyContent='center' key={'loader'}>
                    <Typography variant='body1' color='textPrimary' fontWeight='bold' mt={3}>
                      Chargement en cours ...
                    </Typography>
                  </Box>
                ) : (
                  <Box display='flex' alignItems='center' justifyContent='center' key={'loader'} />
                )
              }
            >
              <Grid container justifyContent='center' spacing={2}>
                {filteredJobOffers?.map(({ slug, jobLocation, title, category }) => (
                  <Grid
                    item
                    container
                    sm={6}
                    md={4}
                    key={slug}
                    minWidth={{ xs: '250px', sm: '280px' }}
                  >
                    <JobOfferCard
                      slug={slug}
                      title={title}
                      jobLocation={jobLocation}
                      category={category}
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                color='black'
                mt={4}
                textAlign='center'
                fontWeight='bold'
              >
                {filteredJobOffers ? filteredJobOffers.length : '0'} résultats pour votre recherche
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <JobOffersBottomBanderole />
        <Map businessType='42C' zoom={3} focus={[39.369673648067455, 2.8468790869562737]} />
      </Grid>
    </>
  )
}
