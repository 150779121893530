import { useState, MouseEvent } from 'react'
import RouterLink from '../router-link/RouterLink'
import {
  Link as MuiLink,
  Box,
  MenuItem,
  Menu,
  Divider,
  Typography,
  useMediaQuery,
  Stack,
  IconButton,
  Toolbar,
  AppBar,
  Grid,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { makeStyles } from 'tss-react/mui'
import { theme } from '../../../assets/themes/theme'
import { FaFacebookF, FaYoutube, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import SocialLogoNavbar from './SocialLogo'

import { useNavbarElements } from '../../../hooks/navbar-elements/useNavbarElements'
import colors from '../../../assets/styles/styles.module.scss'

import {
  BLOG_URL,
  INSTAGRAM_URL,
  FACEBOOK_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  YOUTUBE_URL,
} from '../../../shared/constants/social-media-links'

const useStyles = makeStyles()({
  menuLinks: {
    padding: '8px',
    fontWeight: 'bold ',
    fontSize: '12px',
    margin: '2px',
    display: 'block',
    color: colors.orange,
  },
  social: {
    color: colors.white,
    padding: '3px',
    height: '25px',
    width: '25px',
  },
  mobileSocial: {
    color: colors.orange,
    padding: '2px',
    height: '20px',
    width: '20px',
  },
  link: {
    color: colors.white,
    fontSize: '13px',
  },
  menuMobile: {
    flex: 'auto',
  },
})

export default function Navbar() {
  const { navbarElements } = useNavbarElements()
  const { classes } = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  function handleMobileMenuOpen(event: MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'

  const isMediumFormat = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('sm'))

  const socialLinks = [
    {
      logo: <FaFacebookF className={isMediumFormat ? classes.mobileSocial : classes.social} />,
      link: FACEBOOK_URL,
    },
    {
      logo: <FaLinkedin className={isMediumFormat ? classes.mobileSocial : classes.social} />,
      link: LINKEDIN_URL,
    },
    {
      logo: <FaTwitter className={isMediumFormat ? classes.mobileSocial : classes.social} />,
      link: TWITTER_URL,
    },
    {
      logo: <FaYoutube className={isMediumFormat ? classes.mobileSocial : classes.social} />,
      link: YOUTUBE_URL,
    },
    {
      logo: <FaInstagram className={isMediumFormat ? classes.mobileSocial : classes.social} />,
      link: INSTAGRAM_URL,
    },
  ]
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {navbarElements?.map(({ _id: id, title, link }) => {
        return (
          <MenuItem key={id}>
            <Typography
              component={RouterLink}
              to={link}
              className={classes.menuLinks}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </MenuItem>
        )
      })}
      {isMobileFormat ? (
        <MenuItem>
          <RouterLink to='/on-parle-de-nous'>
            <Typography className={classes.menuLinks}>On parle de nous !</Typography>
          </RouterLink>
        </MenuItem>
      ) : (
        ''
      )}
      <Grid container sx={{ display: { lg: 'none' } }}>
        <MenuItem>
          {socialLinks.map((social, key) => {
            return (
              <SocialLogoNavbar key={key} link={social.link}>
                {social.logo}
              </SocialLogoNavbar>
            )
          })}
        </MenuItem>
      </Grid>
    </Menu>
  )

  return (
    <>
      <AppBar
        position={isMediumFormat ? 'sticky' : 'static'}
        elevation={1}
        sx={{
          background: colors.purpleGradient,
        }}
      >
        <Toolbar
          style={{
            minHeight: '40px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
            ml={{ xs: 1, sm: 4 }}
          >
            <Grid container alignItems='center' component={RouterLink} to='/'>
              <LazyLoadImage
                effect='opacity'
                alt='Logo 42c'
                src='/logos-42c/42c_logo_blanc.png'
                width={isMobileFormat ? '35px' : '45px'}
                height={isMobileFormat ? '35px' : '45px'}
              />
            </Grid>
          </Box>
          <Stack
            direction='row'
            spacing={{ xs: 0.5, sm: 3 }}
            className={isMediumFormat ? classes.menuMobile : ''}
          >
            <MenuItem>
              <Typography component={RouterLink} to='/qui-sommes-nous' className={classes.link}>
                Nous rejoindre
              </Typography>
            </MenuItem>
            <MenuItem>
              <MuiLink href={BLOG_URL} className={classes.link} target='_blank' underline='none'>
                Blog
              </MuiLink>
            </MenuItem>
            {!isMobileFormat && (
              <MenuItem>
                <RouterLink to='/on-parle-de-nous'>
                  <Typography className={classes.link}>On parle de nous !</Typography>
                </RouterLink>
              </MenuItem>
            )}

            <Box mr={10} sx={{ display: { xs: 'none', md: 'flex', color: colors.white } }}>
              {socialLinks.map((social, key) => {
                return (
                  <SocialLogoNavbar link={social.link} key={key}>
                    {social.logo}
                  </SocialLogoNavbar>
                )
              })}
            </Box>
          </Stack>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Divider
              style={{ background: colors.white, margin: 'auto 10px', height: '25px' }}
              orientation='vertical'
              flexItem
            />
            <IconButton
              size='large'
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MenuIcon sx={{ color: colors.white }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  )
}
