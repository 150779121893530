import React from 'react'
import SEO from '../../components/common/seo/SEO'
import Banderole from '../../components/common/banderoles/Banderole'
import SecondBanderole from '../../components/common/banderoles/SecondBanderole'
import MultiSolutions from '../../components/multi-solutions/all/MultiSolutions'
import SectoralBusinessSolutions from '../../components/sectoral-business-solutions/SectoralBusinessSolutions'
import Clients from '../../components/common/clients/Clients'
import SuccessStories from '../../components/common/success-stories/SuccessStories'
import Actualities from '../../components/common/actualities/all/Actualities'
import CustomFooter from '../CustomFooter'

import { Box, Grid, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'
import { homePage } from '../../shared/constants/pages-titles'
import { useViews } from '../../hooks/views/useViews'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  boxLg: {
    height: '425px',
    marginBottom: '12em',
    padding: '2rem 0',
    paddingTop: '50px',
    background: '#f5f5f5',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.red,
    color: 'white',
  },
})

export default function HomePage() {
  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  const { view } = useViews('HOME')

  const { metaTitle, metaDescription } = view ?? {}

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />

      <Banderole />

      <Box component='section'>
        <SecondBanderole />
      </Box>

      <Box component='section'>
        <MultiSolutions />
        {!isMobileFormat && (
          <Grid
            container
            item
            xs={12}
            sx={{
              background: colors.red,
              position: 'relative',
              top: { md: -60, lg: -50, xl: -70 },
            }}
            p={isMobileFormat ? '8vh' : '17vh 10vh 12vh 10vh'}
            mb={3}
          >
            <SectoralBusinessSolutions />
          </Grid>
        )}
      </Box>
      <Box>
        <Clients title={true} partnerType={'42C'} />
      </Box>

      <Box
        pb={isMobileFormat ? 1 : 10}
        sx={{
          background: colors.orangeGradient,
        }}
      >
        <Box component='section' className={isMobileFormat ? '' : classes.boxLg}>
          <SuccessStories />
        </Box>

        {!isMobileFormat && (
          <Box component='section' pt={1} pb={1}>
            <Actualities />
          </Box>
        )}
      </Box>
      <CustomFooter page={homePage} />
    </>
  )
}
