import React from 'react'
import MultiSolutionsPage from '../parts/pages/MultiSolutionsPage'
import { useMultiSolutionBySlug } from '../hooks/multi-solutions/useMultiSolutions'
import { useParams } from 'react-router'
import SubPage from '../parts/pages/SubPage'

import { MultiSolution } from '../shared/types/multi-solution/MultiSolution'

export default function MultiSolutions() {
  return <MultiSolutionsPage />
}

export function MultiSolutionSingle() {
  const { slug = '' } = useParams()
  const { multiSolution } = useMultiSolutionBySlug(slug)
  const [multiSolutionData, setMultiSolutionData] = React.useState<undefined | MultiSolution>(
    multiSolution
  )
  React.useEffect(() => {
    setMultiSolutionData(multiSolution)
  }, [multiSolution, slug])

  return (
    <>
      {multiSolutionData ? (
        <SubPage content={multiSolutionData} category='MULTI-SOLUTIONS' />
      ) : null}
    </>
  )
}
