import React from 'react'

import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../assets/styles/styles.module.scss'

interface IMainTitle {
  text: string
  titleColor: 'orange' | 'white'
}

export default function MainTitle({ text, titleColor }: IMainTitle) {
  const useStyles = makeStyles()({
    title: {
      textTransform: 'uppercase',
      color: titleColor === 'orange' ? colors.orange : colors.white,
    },
  })
  const { classes } = useStyles()

  return (
    <Box mb={3} component='header'>
      <Typography variant='h5' align='left' className={classes.title}>
        <strong>/ {text.split(/[\s-]+/)[0]}</strong>
        {text.replace(text.split(/[\s-]+/)[0], '')}
      </Typography>
    </Box>
  )
}
