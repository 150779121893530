import React from 'react'
import strippedString from '../../../shared/utils/strippedString'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

interface ISEO {
  metaTitle?: string
  metaDescription?: string
  ogTitle?: string
  imageUrl?: string
  description?: string
  ogCard?: boolean
}

export default function SEO({
  metaTitle,
  metaDescription,
  ogTitle,
  imageUrl,
  description,
  ogCard,
}: ISEO) {
  const location = useLocation()

  return (
    <Helmet>
      <title>{metaTitle ?? '42c'}</title>
      <meta
        name='description'
        content={
          metaDescription ??
          "42c est un cabinet de conseil et d'expertise IT spécialisé dans le cloud, la cybersécurité ou encore la data. Plus de 1 000 projets réalisés !"
        }
      />
      {ogCard && (
        <>
          <meta property='og:title' content={ogTitle} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={'https://www.42c.fr' + location.pathname} />
          <meta property='og:image' content={imageUrl} />
          <meta property='og:image:type' content='image/jpeg' />
          <meta property='og:description' content={strippedString(description ?? '')} />
        </>
      )}
    </Helmet>
  )
}
