import React from 'react'

import { Grid, Typography } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IJoinUsJobCard {
  title: string
  imageUrl: string
}

export default function JoinUsJobCard({ title, imageUrl }: IJoinUsJobCard) {
  return (
    <Grid item container xs={12} sm={6} md={4} spacing={2} justifyContent='center'>
      {imageUrl && (
        <Grid item container justifyContent='end' alignItems='center' xs={4}>
          <Grid item xs={6} md={6} sm={7}>
            <LazyLoadImage
              effect='opacity'
              src={imageUrl}
              alt='Icône métiers'
              width={'100%'}
              height={'100%'}
            />
          </Grid>
        </Grid>
      )}

      <Grid item xs={8} container justifyContent='center' alignItems='center'>
        <Typography
          variant='h5'
          fontWeight='bold'
          color='white'
          textAlign='left'
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </Grid>
    </Grid>
  )
}
