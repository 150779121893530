import React from 'react'
import RouterLink from '../../router-link/RouterLink'
import { Typography } from '@mui/material'

import styles from './JobOffer.module.scss'

interface IJobOffer {
  title: string
  slug: string
  description: string
  contract: string
  category: 'CDI' | 'CDD'
  jobLocation: string
}

export default function JobOffer({
  title,
  slug,
  description,
  contract,
  category,
  jobLocation,
}: IJobOffer) {
  return (
    <RouterLink to={`/offre-demploi-42c/${slug}`}>
      <div className={styles.jobOffer}>
        <div className={styles.triangleBottomRight} />
        <div className={styles.rectangleLeft}>
          <Typography
            variant='h6'
            gutterBottom
            component='div'
            className={styles.titleJob}
            dangerouslySetInnerHTML={{
              __html: title ?? '',
            }}
          />

          <Typography className={styles.subtitleJob}>
            {category} - {contract} - {jobLocation}
          </Typography>

          <Typography
            className={styles.descriptionJob}
            dangerouslySetInnerHTML={{
              __html: description ?? '',
            }}
          />
        </div>
      </div>
    </RouterLink>
  )
}
