import React, { useEffect, useState } from 'react'

import { Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { theme } from '../../../assets/themes/theme'

import { useBanderole } from '../../../hooks/banderole/useBanderoles'

import BanderoleButton from './BanderoleButton'

interface IBanderole {
  customTitle?: string
  customImageUrl?: string
  displayContactUsButton?: boolean
  category?: string
  subpage?: boolean
}

export default function Banderole({
  customImageUrl,
  customTitle,
  displayContactUsButton = true,
  category,
  subpage,
}: IBanderole) {
  const { pageBanderole } = useBanderole('HOME')
  const { imageUrl, title } = pageBanderole ?? {}

  const [displayedTitle, setDisplayedTitle] = useState('')

  useEffect(() => {
    if (customTitle || title) {
      setDisplayedTitle(customTitle ?? title ?? '')
    }
  }, [customTitle, title])

  const isVideo = customImageUrl
    ? customImageUrl?.split('.').pop() === 'mp4'
    : imageUrl?.split('.').pop() === 'mp4'

  const is1440 = useMediaQuery('(min-width:1440px)')
  const is1024 = useMediaQuery('(min-width:1024px)')
  const is900 = useMediaQuery('(min-width:900px)')
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  function getBanderoleVideoHeight(): string {
    if (is1440) {
      return '85vh'
    } else if (is1024) {
      return '85vh'
    } else if (is900) {
      return '75vh'
    } else {
      return '95vh'
    }
  }

  const useStyles = makeStyles()({
    videoBackground: {
      display: 'flex',
      position: 'absolute',
      width: isMobileFormat ? '' : '100%',
      height: isMobileFormat ? '100vh' : '',
      right: 0,
      top: 0,
      bottom: 0,
      left: 0,
    },
    categoryPage: {
      alignItems: 'end',
      paddingBottom: '10vh',
      paddingTop: '20vh',
    },
    isNotCategory: { alignItems: 'center' },
  })

  const { classes } = useStyles()

  return (
    <Container
      style={{
        background: `${theme.palette.secondary.main} url(${
          customImageUrl ?? imageUrl
        }) center center / cover no-repeat `,
        position: 'relative',
        height: isVideo ? getBanderoleVideoHeight() : '90vh',
        display: 'flex',
        overflow: 'hidden',
      }}
      className={category ? classes.categoryPage : classes.isNotCategory}
      maxWidth={false}
    >
      {isVideo && (
        <video
          autoPlay
          muted
          loop
          playsInline
          controlsList='nofullscreen'
          disablePictureInPicture
          className={classes.videoBackground}
        >
          <source src={customImageUrl ?? imageUrl} type='video/mp4' />
        </video>
      )}

      <Grid
        container
        spacing={2}
        justifyContent='flex-end'
        alignItems={isMobileFormat ? 'flex-end' : 'center'}
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Grid
            container
            item
            xs={11}
            sm={9}
            md={8}
            sx={{
              position: !category && isMobileFormat ? 'absolute' : '',
              top: !category && isMobileFormat ? '10vh' : '',
            }}
          >
            {category && (
              <Typography
                variant='body2'
                sx={{
                  fontSize: {
                    md: '1.1vw',
                    sm: '1.5vw',
                    xs: '3.7vw',
                  },
                  color: 'white',
                }}
                textTransform='uppercase'
                align='left'
              >
                <strong>/ {category.split(/[\s-]+/)[0]}</strong>
                {category.replace(category.split(/[\s-]+/)[0], '')}
              </Typography>
            )}

            <Grid item xs={12} sm={11} md={11} xl={category ? 11 : 10} container>
              <Typography
                variant={subpage ? 'h4' : undefined}
                sx={{
                  fontSize: subpage
                    ? undefined
                    : {
                        md: '1.2vw',
                        sm: '2vw',
                        xs: '3.7vw',
                      },
                }}
                color='white'
                fontWeight={subpage ? 'bold' : undefined}
                dangerouslySetInnerHTML={{
                  __html: displayedTitle ?? (title as string),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              lg={9}
              xl={9}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-end' },
              }}
            >
              {displayContactUsButton && (
                <BanderoleButton text={'/ Nous contacter'} href='/contact' />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
