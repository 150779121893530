import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'
import { useDropzone } from 'react-dropzone'

import { useApplication } from '../../hooks/apply/useApplication'
import { useJobOffers } from '../../hooks/job-offers/useJobOffers'

import { ApplyForm as ApplyFormType } from '../../shared/types/apply/ApplyForm'

import { JobOffer } from '../../shared/types/job-offer/JobOffer'
import { IApplyPage } from '../../parts/pages/ApplyPage'
import { makeStyles } from 'tss-react/mui'
import ReactGA from 'react-ga4'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const useStyles = makeStyles()({
  option: {
    color: 'black',
  },

  contactInput: {
    '& input': {
      color: 'black',
      borderBottom: ' 1px solid black',
    },
    '& label': { color: 'gray' },
    width: '80%',
  },

  contactInputMobile: {
    '& input': {
      color: 'black',
      borderBottom: ' 1px solid black',
    },
    '& label': { color: 'gray' },
    width: '90%',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '0px !important',
  },

  buttonText: {
    background: colors.orangeGradient,
    boxShadow: 'none',
    color: colors.white,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    padding: '5px',
    marginTop: '10px',
    height: '42px',
    width: '110px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-flex',
    textTransform: 'uppercase',
    borderRadius: 0,
    lineHeight: '0px',
    alignItems: 'center',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },

  button: {
    background: colors.orangeGradient,
    border: `1px solid ${colors.orangeGradient}`,
    padding: '5px',
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: 'bold',
    color: colors.white,
    borderRadius: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    margin: 'auto',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
})

const initialFormValues: ApplyFormType = {
  firstName: '',
  lastName: '',
  email: '',
  file: '',
  jobOffer: '',
}

export default function ApplyForm({ jobOfferId }: IApplyPage) {
  const { classes } = useStyles()
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }
  const { addApplication } = useApplication()
  const { jobOffers } = useJobOffers()

  const [isSelectedJobOfferExists, setIsSelectedJobOfferExists] = useState<JobOffer>()

  useEffect(() => {
    if (jobOfferId && (jobOffers?.length || 0) > 0) {
      setIsSelectedJobOfferExists(jobOffers?.find((offer) => offer._id === jobOfferId))
    }
  }, [jobOfferId, jobOffers])

  const [applicationFormValues, setApplicationFormValues] =
    useState<ApplyFormType>(initialFormValues)
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
  const [isDraggedFile, setIsDraggedFile] = useState(false)
  const [isDisplayedAlert, setIsDisplayedAlert] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success')
  const filesAccepted = [
    'application/pdf',
    'image/*',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ]
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noClick: true,
    multiple: false,
    maxSize: 20971520,
    accept: {
      filesAccepted,
    },
  })

  const [alertMessage, setAlertMessage] = useState<string>('')

  async function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setApplicationFormValues({
      ...applicationFormValues,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    if (acceptedFiles.length) {
      setApplicationFormValues({
        ...applicationFormValues,
        file: acceptedFiles[0],
      })
      setIsDraggedFile(true)
    } else {
      setIsDraggedFile(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  useEffect(() => {
    setDisabledSubmitButton(false)
    Object.keys(applicationFormValues).forEach((key) => {
      const obj: any = { ...applicationFormValues }
      if (key !== 'jobOffer' && !obj[key]) {
        setDisabledSubmitButton(true)
      }
    })
  }, [applicationFormValues])

  useEffect(() => {
    setApplicationFormValues({
      ...initialFormValues,
      jobOffer: isSelectedJobOfferExists ? (jobOfferId as string) : '',
    })
  }, [isSelectedJobOfferExists, jobOfferId])

  async function submitHandler() {
    try {
      setLoading(true)
      await addApplication(applicationFormValues)
      setLoading(false)
      setIsDisplayedAlert(true)
      setAlertSeverity('success')
      setAlertMessage('Votre candidature a été bien envoyée ')
      setApplicationFormValues(initialFormValues)
      setIsDraggedFile(false)
      ReactGA.event({
        action: 'job_form',
        category: 'JOB',
        label: 'job_offers',
      })
      setTimeout(() => {
        setIsDisplayedAlert(false)
      }, 3000)
    } catch (error: any) {
      setLoading(false)
      setIsDisplayedAlert(true)
      setAlertSeverity('error')
      setAlertMessage(
        error.message == 'email must be an email'
          ? 'Veuillez saisir une adresse email valide !'
          : error.message == 'Internal server error'
          ? 'Erreur serveur interne'
          : error.message
      )
    }
  }

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth='xl' disableGutters sx={{ marginBottom: '100px' }}>
      <FormControl>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xl={10} lg={9} md={9} sm={10} xs={10}>
            <Collapse in={isDisplayedAlert} sx={{ width: '90%', margin: 'auto' }}>
              <Alert severity={alertSeverity}>{alertMessage}</Alert>
            </Collapse>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid container item spacing={4} xl={10} lg={9} md={9} sm={10} xs={10}>
            <Grid container item xs={6} justifyContent='center' alignItems='center'>
              <TextField
                value={applicationFormValues.firstName}
                id='standard-basic'
                label='PRÉNOM'
                name='firstName'
                onChange={onChangeHandler}
                variant='standard'
                required
                className={classes.contactInput}
              />
            </Grid>
            <Grid container item xs={6} justifyContent='center' alignItems='center'>
              <TextField
                value={applicationFormValues.lastName}
                id='standard-basic'
                label='NOM'
                name='lastName'
                onChange={onChangeHandler}
                variant='standard'
                required
                className={classes.contactInput}
              />
            </Grid>
            <Grid
              container
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              justifyContent='center'
              alignItems='center'
            >
              <TextField
                value={applicationFormValues.email}
                id='standard-basic'
                label='ADRESSE MAIL'
                name='email'
                onChange={onChangeHandler}
                variant='standard'
                required
                className={isMobileFormat ? classes.contactInputMobile : classes.contactInput}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                alignItems: 'end',
                display: 'flex',
              }}
            >
              {jobOffers?.length === 0 ? (
                ''
              ) : (
                <Grid container justifyContent={'center'}>
                  <FormControl fullWidth>
                    <Grid container justifyContent={'center'}>
                      <Select
                        disabled={!!isSelectedJobOfferExists}
                        name='jobOffer'
                        id='label-job-offer'
                        variant='standard'
                        displayEmpty
                        onChange={(event: SelectChangeEvent) =>
                          onChangeHandler(event as React.ChangeEvent<HTMLInputElement>)
                        }
                        value={applicationFormValues.jobOffer}
                        sx={{
                          width: { md: '80%', xs: '90%' },
                          '& label': { color: 'gray' },
                        }}
                      >
                        <MenuItem value=''>Candidature spontanée</MenuItem>
                        {isSelectedJobOfferExists ? (
                          <MenuItem
                            key={isSelectedJobOfferExists._id}
                            value={isSelectedJobOfferExists._id}
                            className={classes.option}
                          >
                            {isSelectedJobOfferExists.title}
                          </MenuItem>
                        ) : (
                          jobOffers?.map(({ title, _id }) => (
                            <MenuItem key={_id} value={_id} className={classes.option}>
                              {title}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </Grid>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container item justifyContent='center' alignItems='center' mt={5}>
              <Grid
                item
                xs={11}
                mt={4}
                pt={6}
                pb={3}
                textAlign='center'
                sx={{
                  background: 'white',
                  border: '1px grey solid',
                }}
                {...getRootProps()}
              >
                <Grid item xs={12}>
                  <LazyLoadImage
                    effect='opacity'
                    src='/img/cloudUploadIcon.png'
                    width='100px'
                    height='80px'
                    alt='Icône de téléchargement'
                  />
                </Grid>
                <Grid item xs={7} sm={3} md={2} mt={2} mr={'auto'} ml={'auto'}>
                  <Typography variant='body2' color='black' fontWeight='bold' textAlign='center'>
                    Glisser-déposer votre CV (max: 20 Mo) dans cette zone ou
                  </Typography>
                  <Grid item xs={12} mt={2}>
                    <label htmlFor='up'>
                      <Button variant='outlined' component='label' className={classes.button}>
                        Charger un fichier (max: 20 Mo)
                        <input
                          type='file'
                          name='file'
                          onChange={onChangeHandler}
                          hidden
                          {...getInputProps()}
                        />
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                {isDraggedFile && (
                  <Grid item xs={12}>
                    <Typography mt={2} fontWeight='bold' variant='body2' color='textPrimary'>
                      Fichier chargé :
                    </Typography>
                    {acceptedFiles.map((file: any) => (
                      <Typography mt={1} variant='body2' color='textPrimary' key={file.path}>
                        - {file.path}
                      </Typography>
                    ))}
                  </Grid>
                )}

                <Grid container justifyContent='center' mt={5}>
                  <Grid item xs={11}>
                    <FormControlLabel
                      label={
                        <Typography variant='body2' color='gray'>
                          Vous acceptez la politique de confidentialité et de partager avec nos
                          services vos données personnelles présentes sur votre CV. L’analyse de vos
                          données permettra de trouver les offres en adéquation avec vos
                          expériences, métiers, compétences...
                        </Typography>
                      }
                      control={
                        <Checkbox
                          size='small'
                          sx={{ color: 'gray' }}
                          checked={isChecked}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} justifyContent='center' alignItems='center'>
              <Grid item xs={11}>
                <LoadingButton
                  onClick={submitHandler}
                  disabled={isChecked ? disabledSubmitButton || loading : true}
                  loading={loading}
                  variant='contained'
                  className={classes.buttonText}
                >
                  {!loading && 'Envoyer'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  )
}
