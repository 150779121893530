import React from 'react'

import { Typography, Grid, Link } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import styled from '@emotion/styled'
import { makeStyles } from 'tss-react/mui'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Marker as LeafletMarker, Popup } from 'react-leaflet'
import { Icon } from 'leaflet'
import colors from '../../../assets/styles/styles.module.scss'

interface IMarker {
  latitude: number
  longitude: number
  firstAddress: string
  secondAddress?: string
  city: string
  country: string
  phone?: string
  logoUrl: string
}

const StyledPop = styled(Popup)`
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }
`

const useStyles = makeStyles()(() => {
  return {
    content: {
      display: 'block',
      fontSize: '11px',
      margin: '0 !important',
      color: 'black',
    },
    contact: {
      display: 'flex',
      fontSize: '11px',
      color: colors.orange,
      fontWeight: 'bold',
      alignItems: 'center',
      margin: '10px 0 0 0 !important',
    },
    email: { color: `${colors.orange} !important` },
  }
})

const marker = new Icon({
  iconUrl: 'https://i.ibb.co/QHrLHvq/mapIcon.png',
  iconSize: [30, 30],
})

export default function Marker({
  latitude,
  longitude,
  firstAddress,
  secondAddress,
  city,
  country,
  phone,
  logoUrl,
}: IMarker) {
  const { classes } = useStyles()

  return (
    <LeafletMarker position={[latitude, longitude]} icon={marker}>
      <StyledPop>
        <Grid m={1}>
          <Grid item m={'auto'} xs={6}>
            {logoUrl && (
              <LazyLoadImage
                effect='opacity'
                src={logoUrl}
                alt='Logo 42c'
                width={128}
                height={128}
              />
            )}
          </Grid>
          <Grid mt={2}>
            <Typography className={classes.content}>{firstAddress}</Typography>
            <Typography className={classes.content}>{secondAddress}</Typography>
            <Typography className={classes.content} pt={1}>
              {city}
            </Typography>
            <Typography className={classes.content}> {country}</Typography>
            <Typography className={classes.contact}>
              <PhoneIcon
                sx={{
                  color: colors.orange,
                  width: '12px',
                  height: '12px',
                  marginRight: '5px',
                }}
              />
              <Link href={'tel:' + phone} className={classes.email} underline='none'>
                {phone}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </StyledPop>
    </LeafletMarker>
  )
}
