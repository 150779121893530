import React from 'react'
import { Grid, Link } from '@mui/material'

interface IFooterSocialLogo {
  link: string
  children: React.ReactNode
}

export default function FooterSocialLogo({ link, children }: IFooterSocialLogo) {
  return (
    <Grid item xs={12}>
      <Link target='_blank' href={link} rel='noreferrer noopener' underline='none'>
        {children}
      </Link>
    </Grid>
  )
}
