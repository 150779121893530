import React from 'react'
import RouterLink from '../../router-link/RouterLink'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'

import { useStyles } from './JobOffersStyle'
import JobOffer from '../single/JobOffer'

import { useJobOffers, useJobOffersFilter } from '../../../../hooks/job-offers/useJobOffers'
import { useViews } from '../../../../hooks/views/useViews'
import { homePage } from '../../../../shared/constants/pages-titles'
import { theme } from '../../../../assets/themes/theme'

interface IJobOffers {
  page?: string
}

export default function JobOffers({ page }: IJobOffers) {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { view } = useViews('JOB_OFFER')
  const { title } = view ?? {}
  const { jobOffers } = useJobOffers()
  const { favoriteJobOffers } = useJobOffers()
  const { filteredJobOffers } = useJobOffersFilter({
    page,
  })

  const displayedJobOffers =
    page === homePage && favoriteJobOffers?.length
      ? favoriteJobOffers
      : page && filteredJobOffers?.length
      ? filteredJobOffers
      : jobOffers

  const { classes } = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        {title && (
          <Typography
            variant='h5'
            color='textPrimary'
            align='left'
            mb={1}
            sx={{ textTransform: 'uppercase' }}
          >
            <strong>/ </strong>
            {title.split(/[\s-]+/)[0]}
            <strong>{title.replace(title.split(/[\s-]+/)[0], '')}</strong>
          </Typography>
        )}
        {displayedJobOffers?.length
          ? displayedJobOffers
              ?.map(
                ({ _id: id, slug, title, littleDescription, contract, category, jobLocation }) => (
                  <Grid item key={id} xs={12} sm={12} md={10} lg={10} xl={10} mt={3}>
                    <JobOffer
                      title={title}
                      slug={slug}
                      description={littleDescription}
                      contract={contract}
                      category={category}
                      jobLocation={jobLocation}
                    />
                  </Grid>
                )
              )
              .reverse()
              .slice(0, isMobileFormat ? 3 : 4)
          : ''}
        <Grid component={RouterLink} to='/offre-demploi-42c' item container md={10} mt={2}>
          <Button className={classes.buttonText}>Voir plus d'offres</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
