import React, { useEffect, useState } from 'react'

import { Grid, Typography, Container } from '@mui/material'
import SectionTitle from '../common/custom-titles/SectionTitle'

import { useJoinUs } from '../../hooks/joins-us/useJoinUs'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function Adventure() {
  const { joinUsData = [] } = useJoinUs()
  const { title, description, imageUrl } = joinUsData[0] ?? {}

  const [displayedDescription, setDisplayedDescription] = useState('')

  useEffect(() => {
    if (description) {
      setDisplayedDescription(description)
    }
  }, [description])

  return (
    <Container maxWidth='lg' disableGutters>
      <Grid container justifyContent='center' alignItems='center' mt={10} mb={7}>
        <Grid item lg={11} xs={10} container spacing={7}>
          <Grid item md={6} xs={12}>
            <SectionTitle text='Notre Aventure' titleColor='orange' />
            <Typography
              variant='body2'
              mt={4}
              fontWeight='bold'
              color='black'
              dangerouslySetInnerHTML={{
                __html: title ?? '',
              }}
            />
            <Typography
              variant='body2'
              color='black'
              mt={2}
              dangerouslySetInnerHTML={{
                __html: displayedDescription ?? '',
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} mt={6} container justifyContent='center'>
            <Grid item>
              {imageUrl && (
                <LazyLoadImage
                  effect='opacity'
                  src={imageUrl}
                  alt='Image de notre aventure'
                  width={'375px'}
                  height={'400px'}
                  style={{ objectFit: 'cover' }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
