import useSWR from 'swr'
import { Expert } from '../../shared/types/expert/Expert'

const FETCH_EXPERTS_URL = `${process.env.REACT_APP_BASE_URL}/expert`

export async function fetchAllExperts(): Promise<Expert[]> {
  const response = await fetch(`${FETCH_EXPERTS_URL}/all`)
  return await response.json()
}

export async function fetchExpertsBySector(expertSector: string): Promise<Expert[]> {
  const response = await fetch(`${FETCH_EXPERTS_URL}/filter?sector=${expertSector}`)
  return await response.json()
}

export function useExperts() {
  const { data: experts } = useSWR<Expert[]>('api/expert/all', fetchAllExperts)

  return { experts }
}

export function useExpertsBySector(expertSector: string) {
  const { data: experts } = useSWR<Expert[]>(`/api/expert/filter?sector=${expertSector}`, () =>
    fetchExpertsBySector(expertSector)
  )

  return { experts }
}
