import React from 'react'

import { Grid, Typography } from '@mui/material'
import RouterLink from '../router-link/RouterLink'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  matchingBtn: {
    height: '50px',
    width: '200px',
    transform: 'rotate(-270deg)',
    backgroundColor: colors.red,
    border: `1px solid ${colors.red}`,
    color: colors.white,
    position: 'fixed',
    top: '190px',
    right: '-80px',
    padding: '3px',
    zIndex: '9999',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.red}`,
      color: colors.red,
    },
  },
})
export default function MatchCvBtn() {
  const { classes } = useStyles()

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      className={classes.matchingBtn}
      component={RouterLink}
      to='/matche-ton-cv'
    >
      <Typography fontWeight='bold' fontSize='19px'>
        Matchez votre CV
      </Typography>
    </Grid>
  )
}
