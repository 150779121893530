import React from 'react'

import RouterLink from '../common/router-link/RouterLink'

import { Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'
import Paragraph from '../common/custom-paragraphs/Paragraph'

import { useActivitySectors } from '../../hooks/activity-sectors/useActivitySectors'
import { useViews } from '../../hooks/views/useViews'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  button: {
    marginTop: '2rem',
    width: '200px',
    height: '50px',
    borderRadius: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'white',
    border: `1px solid ${colors.white}`,
    marginRight: '50px',
    marginLeft: '50px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'white',
      color: colors.red,
      boxShadow: 'none',
    },
    '&.a': {
      color: `${colors.white} !important`,
    },
  },
  title: {
    textTransform: 'uppercase',
    color: colors.white,
  },

  mobileButton: {
    marginTop: '2rem',
    width: '100%',
    height: '50px',
    borderRadius: '0px',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.red,
      boxShadow: 'none',
    },
    boxShadow: 'none',
    color: 'white',
    border: `1px solid ${colors.white}`,
  },
})

export default function SectoralBusinessSolutions() {
  const { view } = useViews('SECTORAL_BUSINESS_SOLUTION')

  const { favoriteActivitySectors } = useActivitySectors()
  const { description, title } = view ?? {}

  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth='md' sx={{ textAlign: 'center' }}>
      <Grid container justifyContent='center'>
        <Grid mb={isMobileFormat ? 1 : 3} container justifyContent='center'>
          {title && (
            <Typography
              variant='h5'
              align={isMobileFormat ? 'center' : 'left'}
              className={classes.title}
            >
              <strong>/ {title.split(/[\s-]+/)[0]}</strong>
              {title.replace(title.split(/[\s-]+/)[0], '')}
            </Typography>
          )}
        </Grid>
        <Paragraph text={isMobileFormat ? '' : description ?? ''} textColor='white' />
      </Grid>
      {favoriteActivitySectors && (
        <Grid container spacing={1} justifyContent='center' alignItems='center'>
          {favoriteActivitySectors?.map(({ _id: id, slug, category }: any) => (
            <Grid
              item
              sm={5}
              xs={12}
              md={4}
              component={RouterLink}
              key={id}
              to={`/secteurs-dactivite-42c/${slug}`}
            >
              <Button
                variant='contained'
                className={isMobileFormat ? classes.mobileButton : classes.button}
                size='small'
              >
                {category}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  )
}
