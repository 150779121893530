import React from 'react'
import ActivitySectorsPage from '../parts/pages/ActivitySectorsPage'
import SubPage from '../parts/pages/SubPage'
import { useActivitySectorBySlug } from '../hooks/activity-sectors/useActivitySectors'
import { ActivitySector } from '../shared/types/activity-sector/ActivitySector'
import { useParams } from 'react-router-dom'

export default function ActivitySectors() {
  return <ActivitySectorsPage />
}

export function ActivitySectorSingle() {
  const { slug = '' } = useParams()
  const { activitySector } = useActivitySectorBySlug(slug)
  const [activitySectorData, setActivitySectorData] = React.useState<ActivitySector | undefined>(
    activitySector
  )

  React.useEffect(() => {
    setActivitySectorData(activitySector)
  }, [activitySector, slug])

  return (
    <>
      {activitySectorData ? (
        <SubPage content={activitySectorData} category="Secteurs d'activités" />
      ) : null}
    </>
  )
}
