import React from 'react'

interface IBoldEntities {
  poste: string
}

export default function boldEntities({ poste }: IBoldEntities) {
  if (poste.match(/42c si/i)) {
    return (
      <>
        {poste.replace(/42c si/i, '')}
        <strong>42c SI</strong>
      </>
    )
  } else if (poste.match(/42c/i)) {
    return (
      <>
        {poste.replace(/42c/i, '')}
        <strong>42c</strong>
      </>
    )
  } else if (poste.match(/42 dlp/i)) {
    return (
      <>
        {poste.replace(/42 dlp/i, '')}
        <strong>42 DLP</strong>
      </>
    )
  } else if (poste.match(/éstiam/i)) {
    return (
      <>
        {poste.replace(/éstiam/i, '')}
        <strong>éstiam</strong>
      </>
    )
  } else if (poste.match(/42c lab/i)) {
    return (
      <>
        {poste.replace(/42c lab/i, '')}
        <strong>42c Lab</strong>
      </>
    )
  } else {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: poste,
        }}
      />
    )
  }
}
