import axios from 'axios'

const FETCH_UPLOAD_URL = `${process.env.REACT_APP_BASE_URL}/matching-resume`

const FETCH_MATCHING_URL = `${process.env.REACT_APP_BASE_URL_MATCHING_CV}/matching-resume`

export function useMatchingCv() {
  async function fetchMatchingCv(bodyFormData: FormData) {
    return new Promise((resolve, reject) =>
      axios({
        method: 'POST',
        url: `${FETCH_UPLOAD_URL}`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response.status === 201) {
          axios({
            method: 'POST',
            url: `${FETCH_MATCHING_URL}`,
            data: {
              url: response.data.resumeUrl,
            },
            headers: {
              'content-type': 'application/json',
            },
          })
            .then((res) => {
              resolve(JSON.parse(res.data))
            })
            .catch(() => {
              reject(new Error('An error occurred wile fetching job offers.'))
            })
        } else {
          reject(new Error('An error occurred wile saving the CV.'))
        }
      })
    )
  }

  return { fetchMatchingCv }
}
