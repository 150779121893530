import React from 'react'
import { Routes as ReactRoutes, Route } from 'react-router-dom'
import { useRoutesList } from '../hooks/routes/useRoutesList'

export default function Routes() {
  const { routes } = useRoutesList()

  return (
    <ReactRoutes>
      <Route path='/'>
        {routes.map(({ path, index, element }) => {
          return (
            <Route
              key={path + Math.random().toString(36).substring(2, 15)}
              {...{
                path,
                index,
                element,
              }}
            />
          )
        })}
      </Route>
    </ReactRoutes>
  )
}
