import { Divider, Grid, Typography } from '@mui/material'
import colors from '../../../assets/styles/styles.module.scss'

export default function Copyright() {
  return (
    <>
      <Grid item xs={12} mt={{ xs: 3, sm: 1, md: 3 }}>
        <Divider
          sx={{
            background: colors.white,
            width: '100%',
            marginBottom: '15px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          variant='middle'
        />
        <Typography align='center' fontSize='13px' fontWeight='bold'>
          &copy; 42c - 2022 - Tous droits réservés
        </Typography>
      </Grid>
    </>
  )
}
