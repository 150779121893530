import React, { useEffect, useState } from 'react'

import { chunk, uniq } from 'lodash'

import { Grid, ToggleButton, ToggleButtonGroup, styled, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'

import {
  useSuccessStories,
  useSuccessStoriesFilter,
} from '../../hooks/success-stories/useSuccessStories'
import colors from '../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
  buttonText: {
    background: colors.white,
    border: `1px solid ${colors.orangeGradient}`,
    padding: '8px 15px 8px 15px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: colors.orange,
    boxShadow: 'none',
    borderRadius: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
      background: colors.orangeGradient,
      border: `1px solid ${colors.orangeGradient}`,
    },
  },
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.75),
    '&:not(:first-of-type)': {
      borderRadius: 0,
      outlineWidth: '1px',
      outlineStyle: 'solid',
    },
    '&:first-of-type': {
      borderRadius: 0,
      outlineWidth: '1px',
      outlineStyle: 'solid',
    },
  },
  '& .MuiToggleButton-root.Mui-selected': {
    color: 'white',
    background: colors.orangeGradient,
    border: `1px solid ${colors.orangeGradient}`,
  },
  '& .MuiToggleButton-root': {
    borderWidth: 1,
    borderRadius: 0,
  },
}))

type filterSuccessStoriesFormType = {
  categories: string[]
  keywords: string[]
}

const initFilterSuccessStoriesForm = {
  categories: [],
  keywords: [],
}

export default function SuccessStoriesFilter() {
  const { classes } = useStyles()
  const isMediumFormat = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const isXSmallFormat = useMediaQuery('(max-width:320px)')
  const isMobileFormat = isMediumFormat || isSmallFormat || isXSmallFormat

  const { successStories } = useSuccessStories()

  const [chunkedSuccessStoryCategories, setChunkedSuccessStoryCategories] = useState<string[][]>()
  const [chunkedSuccessStoryKeywords, setChunkedSuccessStoryKeywords] = useState<string[][]>()

  const [filterSuccessStoriesValues, setFilterSuccessStoriesValues] =
    useState<filterSuccessStoriesFormType>(initFilterSuccessStoriesForm)

  const { mutateFilterSuccessStories } = useSuccessStoriesFilter(filterSuccessStoriesValues)

  useEffect(() => {
    if (filterSuccessStoriesValues) {
      mutateFilterSuccessStories()
    }
  }, [filterSuccessStoriesValues, mutateFilterSuccessStories])

  useEffect(() => {
    const successStoryCategories = uniq(successStories?.map(({ category }) => category))

    const successStoryKeywords = uniq(successStories?.map(({ keywords }) => keywords).flat())

    setChunkedSuccessStoryCategories(chunk(successStoryCategories, 20))
    setChunkedSuccessStoryKeywords(chunk(successStoryKeywords, 20))

    if (isMediumFormat) {
      setChunkedSuccessStoryCategories(chunk(successStoryCategories, 5))
      setChunkedSuccessStoryKeywords(chunk(successStoryKeywords, 5))
    }

    if (isSmallFormat) {
      setChunkedSuccessStoryCategories(chunk(successStoryCategories, 3))
      setChunkedSuccessStoryKeywords(chunk(successStoryKeywords, 3))
    }

    if (isXSmallFormat) {
      setChunkedSuccessStoryCategories(chunk(successStoryCategories, 2))
      setChunkedSuccessStoryKeywords(chunk(successStoryKeywords, 2))
    }
  }, [isMediumFormat, isSmallFormat, isXSmallFormat, successStories])

  function handleKeywords(event: React.MouseEvent<HTMLElement>, selectedKeyword: string) {
    setFilterSuccessStoriesValues({
      ...filterSuccessStoriesValues,
      keywords: filterSuccessStoriesValues.keywords.includes(selectedKeyword)
        ? filterSuccessStoriesValues.keywords.filter((keyword) => keyword !== selectedKeyword)
        : [...filterSuccessStoriesValues.keywords, selectedKeyword],
    })
  }

  function handCategory(event: React.MouseEvent<HTMLElement>, selectedCategory: string) {
    setFilterSuccessStoriesValues({
      ...filterSuccessStoriesValues,
      categories: filterSuccessStoriesValues.categories.includes(selectedCategory)
        ? filterSuccessStoriesValues.categories.filter((category) => category !== selectedCategory)
        : [...filterSuccessStoriesValues.categories, selectedCategory],
    })
  }

  return (
    <Grid container mb={5}>
      <Grid item container justifyContent={isMobileFormat ? 'center' : ''} alignItems='center'>
        <Grid>
          <StyledToggleButtonGroup
            exclusive
            value=''
            aria-label='displayAll'
            sx={{ marginRight: 0.75 }}
          >
            <ToggleButton
              value='Afficher tout'
              aria-label='displayAll'
              className={classes.buttonText}
              onClick={() => setFilterSuccessStoriesValues(initFilterSuccessStoriesForm)}
            >
              Afficher tout
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        <Grid>
          {chunkedSuccessStoryCategories?.map((partCategories, index) => (
            <StyledToggleButtonGroup
              exclusive
              value={filterSuccessStoriesValues.categories}
              onChange={handCategory}
              aria-label='categories'
              key={index}
            >
              {partCategories.map((category) => (
                <ToggleButton
                  value={encodeURIComponent(category)}
                  aria-label={category}
                  className={classes.buttonText}
                  key={category}
                >
                  {category}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          ))}
        </Grid>

        <Grid>
          {chunkedSuccessStoryKeywords?.map((partKeywords, index) => (
            <StyledToggleButtonGroup
              exclusive
              value={filterSuccessStoriesValues.keywords}
              onChange={handleKeywords}
              aria-label='keywords'
              key={index}
            >
              {partKeywords.map((keyword) => (
                <ToggleButton
                  value={encodeURIComponent(keyword)}
                  aria-label={keyword}
                  className={classes.buttonText}
                  key={keyword}
                >
                  {keyword}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
