import RouterLink from '../router-link/RouterLink'
import { Typography, useMediaQuery, AppBar, Box, Stack } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { theme } from '../../../assets/themes/theme'
import { useNavbarElements } from '../../../hooks/navbar-elements/useNavbarElements'
import colors from '../../../assets/styles/styles.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const useStyles = makeStyles()({
  link: {
    color: colors.orange,
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  mobileAppBar: {
    background: colors.white,
    height: '65px',
    justifyContent: 'center',
    display: 'none',
  },
  appBar: {
    background: colors.white,
    height: '65px',
    justifyContent: 'center',
    display: 'flex',
  },
})

export default function MenuNavbar() {
  const { navbarElements } = useNavbarElements()
  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <AppBar
      position='sticky'
      elevation={1}
      className={isMobileFormat ? classes.mobileAppBar : classes.appBar}
    >
      <Stack direction='row' justifyContent='space-evenly' alignItems='center' spacing={1}>
        <Box ml={12} mr={12}>
          <RouterLink to='/'>
            <LazyLoadImage
              effect='opacity'
              alt='Logo 42c'
              src='/logos-42c/logo42nav.png'
              width='50'
              height='50'
              aria-label='Accueil'
              role='img'
            />
          </RouterLink>
        </Box>
        {navbarElements?.map(({ _id: id, title, link }) => (
          <Typography
            component={RouterLink}
            key={id}
            to={link}
            className={classes.link}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        ))}
      </Stack>
    </AppBar>
  )
}
