import React from 'react'
import SEO from '../../components/common/seo/SEO'
import { Container, Grid, Typography } from '@mui/material'
import RouterLink from '../../components/common/router-link/RouterLink'
import colors from '../../assets/styles/styles.module.scss'

export default function PageNotFoundPage() {
  return (
    <>
      <SEO />
      <Container maxWidth='xl'>
        <Grid container justifyContent='center' alignItems='center' p={6}>
          <Grid item md={6}>
            <Typography fontWeight='bold' fontSize='5em' variant='h1' mb={2}>
              404
            </Typography>
            <Typography color='black' fontSize='3em'>
              Oups !
            </Typography>
            <Typography color='black' fontSize='3em' mb={4}>
              Le lien n'était plus bon ...
            </Typography>
            <Typography
              component={RouterLink}
              to='/'
              color='textPrimary'
              sx={{
                '&:hover': { color: colors.darkPurple },
                fontWeight: 'bold',
                fontSize: '1.5em',
              }}
            >
              ← Retour à l'accueil
            </Typography>
          </Grid>
          <Grid item md={6}>
            <div style={{ width: '100%', height: 0, paddingBottom: '100%', position: 'relative' }}>
              <iframe
                src='https://giphy.com/embed/H7wajFPnZGdRWaQeu0'
                width='100%'
                height='100%'
                style={{ position: 'absolute' }}
                frameBorder='0'
                className='giphy-embed'
                allowFullScreen
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
