import React from 'react'

import { Grid, Typography, useMediaQuery, Box, Link } from '@mui/material'

import ActualityBtn from './ActualityBtn'

import { theme } from '../../../../assets/themes/theme'
import { makeStyles } from 'tss-react/mui'

import { LazyLoadImage } from 'react-lazy-load-image-component'
interface IActuality {
  id?: string | number
  title: string
  description: string
  imageUrl: string
  link: string
}

const useStyles = makeStyles()({
  gridItem: {
    padding: '0 0.7rem 0 0.7rem',
    transition: 'transform 0.1s ease-in-out 0.1s',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
  },
  mobileGridItem: {
    margin: 'auto',
    marginBottom: '20px',
    padding: '0 0.7rem 0 0.7rem',
    transition: 'transform 0.1s ease-in-out 0.1s',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
  },
})

export default function Actuality({ title, description, imageUrl, link }: IActuality) {
  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      item
      xs={10}
      md={4}
      className={isMobileFormat ? classes.mobileGridItem : classes.gridItem}
    >
      <Link href={link} target='_blank' underline='none'>
        <Grid container>
          {imageUrl && (
            <LazyLoadImage
              effect='opacity'
              src={imageUrl}
              alt={"Image de l'article : " + title}
              width='100%'
              height='100%'
              style={{ minHeight: '250px' }}
            />
          )}
          <Box
            component='div'
            sx={{ color: 'white', textAlign: 'left' }}
            pr={{ xl: 10, lg: 10, md: 8, sm: 10, xs: 0 }}
          >
            <Typography
              sx={{
                color: 'white',
                textAlign: 'start',
                fontWeight: 'bold',
                letterSpacing: '1px',
              }}
              pt={1}
              pb={1}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />

            <Typography
              dangerouslySetInnerHTML={{
                __html: description ?? '',
              }}
            />

            <ActualityBtn text='Lire la suite' link={link} />
          </Box>
        </Grid>
      </Link>
    </Grid>
  )
}
