import React from 'react'

import { useMediaQuery, Grid, Link } from '@mui/material'
import { theme } from '../../../assets/themes/theme'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function FilialesLogos() {
  const filialesLinks = [
    {
      logo: '/logos-estiam/estiamBlanc.png',
      link: 'https://www.estiam.education/',
    },
    {
      logo: '/logos-42c/42c_DLP_Logo_Blanc.png',
      link: 'http://42dlp.com/',
    },
    {
      logo: '/logos-42c/42c_si.png',
      link: 'https://www.42csi.fr/',
    },
    {
      logo: '/logos-42c/42c_Lab_blanc.png',
      link: '/42c-lab',
    },
  ]
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      item
      container
      alignItems='center'
      justifyContent={isMobileFormat ? 'start' : 'end'}
      xs={12}
      md={3}
      lg={3}
      xl={2}
      my={{ xs: '1rem', md: '1.3rem' }}
    >
      <Grid
        item
        container
        spacing={isMobileFormat ? 5.5 : 0}
        alignItems='center'
        justifyContent={isMobileFormat ? 'start' : 'end'}
        xs={12}
        md={5}
        lg={4}
        xl={5}
      >
        {filialesLinks.map((social, key) => (
          <Grid
            item
            key={key}
            p={{ xs: 1, sm: 3, md: 1 }}
            xs={6}
            sm={3}
            md={10}
            lg={10}
            xl={12}
            sx={{ cursor: 'pointer' }}
          >
            <Link
              href={social.link}
              target='_blank'
              underline='none'
              aria-label={'Lien vers le site de la filiale'}
              role='link'
            >
              {social && (
                <LazyLoadImage
                  effect='opacity'
                  src={social.logo}
                  alt='Logo filiale 42c'
                  width='100%'
                  height='auto'
                />
              )}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
