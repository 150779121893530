/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useJobOffersFilter } from '../../hooks/job-offers/useJobOffers'
import { useBanderole } from '../../hooks/banderole/useBanderoles'
import { JobOffer } from '../../shared/types/job-offer/JobOffer'

import InfiniteScroll from 'react-infinite-scroller'

import Banderole from '../../components/common/banderoles/Banderole'
import JobOfferCard from '../../components/job-offers/JobOfferCard'
import JobOffersBottomBanderole from '../../components/common/job-offers/JobOffersBottomBanderole'
import Map from '../../components/common/map/Map'
import JobOffersFilter from '../../components/job-offers/common/JobOffersFilter'
import MatchCvBtn from '../../components/common/matchCV/MatchCVBtn'

export default function JobOffersPage() {
  const { filteredJobOffers } = useJobOffersFilter()

  const { pageBanderole } = useBanderole('JOB_OFFERS')
  const { imageUrl, title } = pageBanderole ?? {}

  const [hasMore, setHasMore] = useState(true)
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])

  function displayMore() {
    if (filteredJobOffers) {
      const toAddElementsLength =
        filteredJobOffers.length - displayedJobOffers.length > 10
          ? 10
          : filteredJobOffers.length - displayedJobOffers.length

      if (displayedJobOffers.length < filteredJobOffers.length) {
        setTimeout(() => {
          setDisplayedJobOffers([
            ...displayedJobOffers,
            ...filteredJobOffers?.slice(
              displayedJobOffers.length,
              displayedJobOffers.length + toAddElementsLength
            ),
          ])
        }, 1500)
      } else {
        setHasMore(false)
      }
    }
  }

  useEffect(() => {
    if (filteredJobOffers) {
      if (filteredJobOffers.length <= 10) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setDisplayedJobOffers([...filteredJobOffers?.slice(0, 10)])
    }
  }, [filteredJobOffers])

  return (
    <>
      <Grid container>
        <Banderole
          customImageUrl={imageUrl}
          customTitle={title}
          displayContactUsButton={false}
          category={"OFFRES D'EMPLOI"}
        />

        <JobOffersFilter />
        <MatchCvBtn />

        <Grid item xs={12}>
          <Typography variant='subtitle1' color='black' mt={4} textAlign='center' fontWeight='bold'>
            {filteredJobOffers ? filteredJobOffers.length : '0'} résultats pour votre recherche
          </Typography>
        </Grid>

        <Grid
          container
          item
          xl={6}
          lg={8}
          xs={10}
          justifyContent='center'
          alignItems='center'
          m={'3rem auto 5rem auto'}
        >
          <InfiniteScroll
            loadMore={displayMore}
            hasMore={hasMore}
            loader={
              filteredJobOffers ? (
                <Box display='flex' alignItems='center' justifyContent='center' key={'loader'}>
                  <Typography variant='body1' color='textPrimary' fontWeight='bold' mt={3}>
                    Chargement en cours ...
                  </Typography>
                </Box>
              ) : (
                <Box display='flex' alignItems='center' justifyContent='center' key={'loader'} />
              )
            }
          >
            <Grid container>
              {displayedJobOffers ? (
                <Grid container justifyContent='center' spacing={2}>
                  {displayedJobOffers?.map(({ slug, jobLocation, title, category }) => (
                    <Grid
                      item
                      container
                      sm={6}
                      md={4}
                      key={slug}
                      minWidth={{ xs: '250px', sm: '280px' }}
                    >
                      <JobOfferCard
                        slug={slug}
                        title={title}
                        jobLocation={jobLocation}
                        category={category}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </InfiniteScroll>
        </Grid>
        <JobOffersBottomBanderole />
      </Grid>
      <Map businessType='42C' zoom={3} focus={[39.369673648067455, 2.8468790869562737]} />
    </>
  )
}
