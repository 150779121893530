import { makeStyles } from 'tss-react/mui'
import colors from '../../../../assets/styles/styles.module.scss'

export const useStyles = makeStyles()({
  buttonText: {
    position: 'relative',
    background: colors.orangeGradient,
    color: colors.white,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    padding: '5px',
    marginTop: '10px',
    width: '100%',
    height: '40px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: 0,
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
})
