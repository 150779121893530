import useSWR from 'swr'
import { Client } from '../../shared/types/client/Client'

const FETCH_CLIENTS_URL = `${process.env.REACT_APP_BASE_URL}/client`

export async function fetchAllClients(): Promise<Client[]> {
  const response = await fetch(`${FETCH_CLIENTS_URL}/all`)
  return await response.json()
}

export function useClients() {
  const { data: clients } = useSWR<Client[]>('api/client/all', fetchAllClients)

  return { clients }
}
