import React from 'react'

import { Grid, Typography, Link } from '@mui/material'
import BoldEntities from '../../shared/utils/boldEntities'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IExpertCard {
  linkedInUrl?: string
  imageUrl: string
  firstName: string
  lastName: string
  poste: string
}

export default function ExpertCard({
  linkedInUrl,
  imageUrl,
  firstName,
  lastName,
  poste,
}: IExpertCard) {
  return (
    <Link
      target='_blank'
      underline='none'
      href={linkedInUrl ?? 'https://www.linkedin.com/company/42consultinggroupe/mycompany/'}
    >
      <Grid>
        {imageUrl && (
          <LazyLoadImage
            effect='opacity'
            style={{ objectFit: 'cover' }}
            src={imageUrl}
            alt={"Photo de l'expert : " + firstName + ' ' + lastName}
            width={'130px'}
            height={'190px'}
          />
        )}
      </Grid>

      <Grid mt={0.5}>
        <Typography
          gutterBottom
          color='#000'
          fontWeight='bold'
          fontSize='12px'
          textAlign='start'
          dangerouslySetInnerHTML={{
            __html: firstName + ' ' + lastName,
          }}
        />
      </Grid>

      <Grid item xs={10}>
        <Typography variant='body2' color='#000' textAlign='start' fontSize='11px'>
          <BoldEntities poste={poste} />
        </Typography>
      </Grid>
    </Link>
  )
}
