import React from 'react'

import { Button, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../../assets/styles/styles.module.scss'

interface IActualityBtn {
  link: string
  text: string
}

const useStyles = makeStyles()({
  button: {
    border: '1px solid white',
    borderRadius: '0px',
    padding: '5px',
    width: '120px',
    marginTop: '20px',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'IBMPlexSans',
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      color: colors.red,
      boxShadow: 'none',
      backgroundColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
  },
})

export default function ActualityBtn({ link, text }: IActualityBtn) {
  const { classes } = useStyles()

  return (
    <>
      <Link href={link} target='_blank' underline='none' component='p'>
        <Button variant='outlined' className={classes.button}>
          {text}
        </Button>
      </Link>
    </>
  )
}
