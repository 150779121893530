import React from 'react'

import { Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../../assets/themes/theme'

interface IJobOfferBanderole {
  title: string
  category: string
  startDate: string
  banderoleImageUrl?: string
  jobLocation: string
  level: string
}

export default function JobOfferBanderole({
  title,
  category,
  startDate,
  banderoleImageUrl,
  jobLocation,
  level,
}: IJobOfferBanderole) {
  const isMediumFormat = useMediaQuery(theme.breakpoints.down('xl'))

  return (
    <Container
      sx={{
        background: `${theme.palette.secondary.main} url(${banderoleImageUrl}) center center / cover no-repeat `,
        width: '100%',
        position: 'relative',
        height: '70vh',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        textAlign: 'center',
      }}
      maxWidth={false}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant={isMediumFormat ? 'h4' : 'h3'}
            color='#FFF'
            mt={2}
            sx={{
              fontWeight: 'bold',
              padding: '0.5rem',
            }}
            dangerouslySetInnerHTML={{
              __html: title ?? '',
            }}
          />
          <Typography color='white' variant='body2' textTransform='uppercase' mt={2}>
            Contrat : <strong>{category}</strong> / Début :<strong> {startDate} </strong> / Lieu :
            <strong> {jobLocation} </strong> / Niveau : <strong>{level}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
