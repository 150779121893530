import useSWR from 'swr'
import { SuccessStory } from '../../shared/types/success-story/SuccessStory'

const FETCH_SUCCESS_STORIES_URL = `${process.env.REACT_APP_BASE_URL}/success-story`
function paramsToUrl(categories?: string[], keywords?: string[], page?: string) {
  let url = '?'

  if (categories?.length) {
    categories.forEach((keyword) => {
      url += `categories=${keyword}&`
    })
  }

  if (keywords?.length) {
    keywords.forEach((keyword) => {
      url += `keywords=${keyword}&`
    })
  }

  if (page) {
    url += `page=${page}&`
  }

  return url
}

export async function fetchAllSuccessStories(): Promise<SuccessStory[]> {
  const response = await fetch(`${FETCH_SUCCESS_STORIES_URL}/all`)
  return await response.json()
}

export async function fetchFavoriteSuccessStories(): Promise<SuccessStory[]> {
  const response = await fetch(`${FETCH_SUCCESS_STORIES_URL}/all/favorite`)
  return await response.json()
}

export function useSuccessStories() {
  const { data: successStories } = useSWR<SuccessStory[]>(
    '/api/success-story/all',
    fetchAllSuccessStories
  )
  const { data: favoriteSuccessStories } = useSWR<SuccessStory[]>(
    '/api/favorite-success-story/all',
    fetchFavoriteSuccessStories
  )

  return { successStories, favoriteSuccessStories }
}

export function useSuccessStoriesFilter(filterSuccessStoriesValues?: {
  categories?: string[]
  keywords?: string[]
  page?: string
}) {
  const { categories, keywords, page } = filterSuccessStoriesValues ?? {}

  const { data: filteredSuccessStories, mutate: mutateFilterSuccessStories } = useSWR<
    SuccessStory[]
  >(`/api/success-story/filter?page={${page}}`, () =>
    fetchFilteredSuccessStories(categories, keywords, page)
  )

  return { filteredSuccessStories, mutateFilterSuccessStories }
}

export async function fetchFilteredSuccessStories(
  categories?: string[],
  keywords?: string[],
  page?: string
): Promise<SuccessStory[]> {
  const params = paramsToUrl(categories, keywords, page)
  const response = await fetch(`${FETCH_SUCCESS_STORIES_URL}/filter${params}`)
  return await response.json()
}
