import React, { useEffect, useState } from 'react'

import { Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'

import SectionTitle from '../common/custom-titles/SectionTitle'

import { useAdvantages } from '../../hooks/advantages/useAdvantages'
import { useViews } from '../../hooks/views/useViews'
import { Advantage } from '../../shared/types/advantage/Advantage'

export default function Advantages() {
  const { advantages } = useAdvantages()
  const { view } = useViews('JOIN_US')

  const { title } = view ?? {}

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  const [displayedAdvantages, setDisplayedAdvantages] = useState<Advantage[] | undefined>(undefined)

  useEffect(() => {
    if (advantages) {
      setDisplayedAdvantages(advantages)
    }
  }, [advantages])

  return (
    <>
      {advantages && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={10} sm={9} xl={6} mb={5} mt={25} pl={isMobileFormat ? 0 : 15} container>
            {title && (
              <Grid item xs={12} mb={1}>
                <SectionTitle text={title} titleColor='orange' />
              </Grid>
            )}

            <Grid item xs={12} container spacing={5}>
              {displayedAdvantages?.map(({ id, title, description }) => (
                <Grid item md={6} key={id}>
                  <Typography
                    variant='body2'
                    mt={1}
                    fontWeight='bold'
                    color='black'
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <Typography
                    variant='body2'
                    color='black'
                    mt={1}
                    mb={1}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
