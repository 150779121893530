import RouterLink from '../router-link/RouterLink'
import { Typography, Grid, Box } from '@mui/material'
import colors from '../../../assets/styles/styles.module.scss'

const FooterMenu = () => {
  const footerMenu = [
    {
      items: [
        { link: '/qui-sommes-nous', label: 'Qui sommes-nous ?' },
        { link: '/nos-experts-42c', label: 'Équipe' },
        { link: '/references-42c', label: 'Références' },
        { link: '/multi-solutions', label: 'Expertises' },
      ],
    },
    {
      items: [
        { link: '/on-parle-de-nous', label: 'On parle de nous !' },
        { link: '/contact', label: 'Contact' },
        { link: '/cgu', label: 'CGU' },
        { link: '/mentions-legales', label: 'Mentions Légales' },
      ],
    },
  ]

  return (
    <Grid
      item
      container
      alignItems='center'
      xs={12}
      sm={6}
      md={5}
      xl={6}
      m={{ xs: '1rem auto', md: '1.3rem auto' }}
    >
      <Grid item container xs={12} lg={11} xl={10} spacing={2}>
        {footerMenu.map((list, key) => (
          <Grid item container xs={6} key={key}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {list.items.map((menu, key) => (
                <Typography
                  variant='body1'
                  fontSize='15px'
                  color={colors.white}
                  mb={2}
                  sx={{ cursor: 'pointer' }}
                  dangerouslySetInnerHTML={{
                    __html: menu.label,
                  }}
                  component={RouterLink}
                  key={key}
                  to={menu.link}
                />
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
export default FooterMenu
