import React from 'react'

import { Grid } from '@mui/material'

import MissionCard from './MissionCard'
import SectionTitle from '../common/custom-titles/SectionTitle'

import { useLab } from '../../hooks/lab/useLab'

export default function LabMissions() {
  const { labData = [] } = useLab()
  const { missions } = labData[0] ?? {}

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item container xs={10} sm={11} md={10} lg={9} xl={10}>
        <Grid item xs={12} mt={10} mb={2}>
          <SectionTitle text={'Missions'} titleColor='orange' />
        </Grid>
        <Grid container spacing={8}>
          {missions?.map(({ _id: id, imageUrl, title, description }) => (
            <MissionCard key={id} title={title} description={description} imageUrl={imageUrl} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
