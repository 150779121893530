import React from 'react'

import { Grid, Typography } from '@mui/material'

import JoinUsJobCard from './JoinUsJobCard'
import { useJoinUs } from '../../hooks/joins-us/useJoinUs'
import colors from '../../assets/styles/styles.module.scss'

export default function OurJobs() {
  const { joinUsData = [] } = useJoinUs()
  const { ourJobs } = joinUsData[0] ?? {}

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{ background: colors.orangeGradient }}
      pt={6}
      pb={5}
    >
      <Grid item xs={12}>
        <Typography variant='h4' color='white' textAlign='center' fontWeight='bold'>
          {ourJobs?.title}
        </Typography>
      </Grid>
      <Grid item lg={6} md={8} xs={9} mt={2}>
        <Typography
          variant='body2'
          color='white'
          textAlign='center'
          fontWeight='bold'
          dangerouslySetInnerHTML={{
            __html: ourJobs?.description,
          }}
        />
      </Grid>
      <Grid container justifyContent='center' mt={5} mb={6}>
        <Grid
          item
          xl={7}
          md={10}
          xs={11}
          container
          justifyContent='center'
          alignItems='center'
          spacing={4}
        >
          {ourJobs?.jobs.map(({ _id: id, imageUrl, title }) => (
            <JoinUsJobCard key={id} title={title} imageUrl={imageUrl} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
