import useSWR from 'swr'
import { View, ViewCategory } from '../../shared/types/view/View'

const FETCH_VIEWS_URL = `${process.env.REACT_APP_BASE_URL}/view`

export async function fetchViewByCategory(viewCategory: string) {
  const response = await fetch(`${FETCH_VIEWS_URL}?category=${viewCategory}`)
  return await response.json()
}

export function useViews(viewCategory: ViewCategory) {
  const { data: view } = useSWR<View>(`/api/view/${viewCategory}`, () =>
    fetchViewByCategory(viewCategory)
  )

  return { view }
}
