import { Button, Grid, Typography } from '@mui/material'
import RouterLink from '../router-link/RouterLink'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const useStyles = makeStyles()({
  bottomBanderole: {
    textAlign: 'center',
    minHeight: '200px',
    maxHeight: '300px',
    padding: '30px 0px',
    background: colors.red,
  },

  button: {
    height: '40px',
    padding: '20px',
    borderRadius: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'white',
    border: `1px solid ${colors.white}`,
    fontWeight: 'bold',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'white',
      color: colors.red,
      boxShadow: 'none',
    },
  },
})
export default function JobOffersBottomBanderole() {
  const { classes } = useStyles()

  return (
    <Grid className={classes.bottomBanderole} container justifyContent='center' alignItems='center'>
      <Grid item xs={12} m={0}>
        <LazyLoadImage
          effect='opacity'
          height='50px'
          width='50px'
          style={{ objectFit: 'cover' }}
          src='/img/searchWarning.png'
          alt='Icône de recherche'
        />
      </Grid>
      <Grid item xs={10} sm={4} md={4} lg={3} xl={3} mt={1} mb={1}>
        <Typography variant='h5' color='white' fontWeight='bold'>
          Aucune offre ne vous correspond ?
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1} mb={1}>
        <Button
          variant='contained'
          component={RouterLink}
          to='/postuler'
          className={classes.button}
          size='small'
          aria-label={'Candidature spontanée'}
          role='button'
        >
          Candidature spontanée
        </Button>
      </Grid>
    </Grid>
  )
}
