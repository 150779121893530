import React from 'react'

import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import { LatLngExpression } from 'leaflet'
import * as L from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'

import CampusMarker from './CampusMarker'
import Marker from './Marker'

import { useMapMarkers } from '../../../hooks/map-markers/useMapMarkers'
import { MapMarkerType } from '../../../shared/types/map-marker/MapMarker'
import { constantMarkers } from '../../../shared/constants/map-markers'
import { makeStyles } from 'tss-react/mui'

interface IMap {
  businessType?: MapMarkerType
  zoom: number
  focus: LatLngExpression
}

const useStyles = makeStyles()({
  height: { width: '100%', height: '70vh' },
})

export default function Map({ businessType, zoom, focus }: IMap) {
  const { mapMarkers = constantMarkers } = useMapMarkers()
  const { classes } = useStyles()

  const mapOptions = {
    gestureHandling: true,
  }

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

  return (
    <MapContainer
      center={focus}
      zoom={zoom}
      minZoom={2}
      scrollWheelZoom={false}
      zoomControl={false}
      className={classes.height}
      tap={false}
      {...mapOptions}
    >
      <ZoomControl position='bottomright' />
      <TileLayer
        attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
        url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
      />
      {mapMarkers
        .filter(({ business }) => business === 'ESTIAM')
        .map(({ _id: id, latitude, longitude, firstAddress, city, country, email, logoUrl }) => (
          <CampusMarker
            key={id}
            latitude={latitude}
            longitude={longitude}
            firstAddress={firstAddress}
            city={city}
            country={country}
            email={email}
            logoUrl={logoUrl}
          />
        ))}
      {mapMarkers
        .filter(({ business }) => business === businessType)
        .map(
          ({
            _id: id,
            latitude,
            longitude,
            firstAddress,
            secondAddress,
            city,
            country,
            phone,
            logoUrl,
          }) => (
            <Marker
              key={id}
              latitude={latitude}
              longitude={longitude}
              firstAddress={firstAddress}
              secondAddress={secondAddress}
              city={city}
              country={country}
              phone={phone}
              logoUrl={logoUrl}
            />
          )
        )}
    </MapContainer>
  )
}
