import React from 'react'

import { Grid, useMediaQuery } from '@mui/material'

import CustomFooter from '../CustomFooter'
import Actualities from '../../components/common/actualities/all/Actualities'
import ContactForm from '../../components/contact/ContactForm'

import { useViews } from '../../hooks/views/useViews'
import { contactPage } from '../../shared/constants/pages-titles'
import colors from '../../assets/styles/styles.module.scss'
import { theme } from '../../assets/themes/theme'
import SEO from '../../components/common/seo/SEO'
import HeaderPage from '../../components/common/header-page/HeaderPage'

export default function ContactPage() {
  const { view } = useViews('CONTACT')

  const { metaTitle, metaDescription } = view ?? {}
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />

      <HeaderPage
        viewCategory='CONTACT'
        pageTitle={'Nous <strong>Contacter</strong>'}
        withDescription
      />

      <ContactForm />
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={contactPage} />
    </>
  )
}
