import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useEstiam } from '../../hooks/estiam/useEstiam'

export default function Comity() {
  const { estiamData = [] } = useEstiam()
  const { comity } = estiamData[0] ?? {}

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={10} mt={10}>
        <Grid item xs={12}>
          <Box mb={5} component='header'>
            <Typography color='textSecondary' variant='h4' align='left' fontWeight='bold'>
              Un comité d'orientation stratégique
            </Typography>
          </Box>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Typography variant='body2' fontWeight='bold' color='black'>
              {comity?.title}
            </Typography>
            <Typography
              variant='body2'
              color='black'
              mt={2}
              dangerouslySetInnerHTML={{
                __html: comity?.description,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
