import React from 'react'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'

import MultiSolutionCard from '../../components/multi-solutions/single-multi-solution/MultiSolutionCard'
import { theme } from '../../assets/themes/theme'
import { useMultiSolutions } from '../../hooks/multi-solutions/useMultiSolutions'

import Actualities from '../../components/common/actualities/all/Actualities'
import Paragraph from '../../components/common/custom-paragraphs/Paragraph'
import SectionTitle from '../../components/common/custom-titles/SectionTitle'
import CustomFooter from '../CustomFooter'
import { multiSolutionsPage } from '../../shared/constants/pages-titles'
import colors from '../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  box: {
    padding: '0.5rem 0',
  },
  title: {
    textTransform: 'uppercase',
    color: colors.white,
    fontSize: '12px !important',
  },
})

export default function MultiSolutionsPage() {
  const { view } = useViews('TECHNICAL_SOLUTION')
  const { multiSolutions } = useMultiSolutions()
  const { classes } = useStyles()
  const { metaTitle, metaDescription, description, title } = view ?? {}

  const isSmFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <Container maxWidth='xl'>
        <Box>
          <Grid container direction='row-reverse' style={{ margin: 'auto', display: 'flex' }}>
            <Grid
              item
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={12}
              sx={{
                background: colors.orangeGradient,
                height: '500px',
                position: 'relative',
                top: { xs: '70px', md: '100px' },
                padding: '20px',
              }}
            >
              <Grid item xl={1} lg={1} md={1} sm={2} xs={3}>
                <Box mb={3} className={classes.box} component='header'>
                  {title && (
                    <Typography align='left' className={classes.title}>
                      <strong>/ {title.split(/[\s-]+/)[0]}</strong>

                      {title.replace(title.split(/[\s-]+/)[0], '')}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                container
                item
                justifyContent='end'
                alignItems='center'
                style={{ margin: 'auto', display: 'flex' }}
              >
                <Box
                  sx={{
                    padding: 5,
                  }}
                >
                  <SectionTitle text={title ?? ''} titleColor='white' />
                  <Paragraph text={description ?? ''} textColor='white' />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container disableGutters maxWidth='lg'>
        <Grid
          container
          spacing={isSmFormat ? 0 : 4}
          item
          xl={9}
          lg={8}
          md={8}
          sm={10}
          xs={10}
          m='auto auto -50px auto'
        >
          {multiSolutions?.map(
            ({
              slug,
              imageUrl,
              cardImageUrl,
              subTitle,
              category,
              subDescription,
              gifUrl,
              cardGifUrl,
            }) => (
              <Grid item key={slug} xl={4} lg={4} md={4} sm={6} xs={12} mt={isSmFormat ? 3 : 0}>
                <MultiSolutionCard
                  slug={slug}
                  subTitle={subTitle}
                  subDescription={subDescription}
                  imageUrl={cardImageUrl ?? imageUrl}
                  category={category}
                  gifUrl={cardGifUrl ?? gifUrl ?? imageUrl}
                />
              </Grid>
            )
          )}
        </Grid>
      </Container>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '8rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={multiSolutionsPage} />
    </>
  )
}
