import React from 'react'
import RouterLink from '../router-link/RouterLink'
import { Button, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import SocialLogo from './FooterSocialLogo'
import MainTitle from '../custom-titles/MainTitle'

import { FaFacebookF, FaYoutube, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'

import {
  FACEBOOK_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  INSTAGRAM_URL,
} from '../../../shared/constants/social-media-links'
import colors from '../../../assets/styles/styles.module.scss'

const useStyles = makeStyles()(() => {
  return {
    social: {
      color: '#fff',
      padding: '3px',
      height: '30px',
      width: '30px',
    },
    button: {
      border: `1px solid ${colors.white}`,
      borderRadius: '1px',
      background: colors.orangeGradient,
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '15px',
      padding: '7px',
      width: '125px',
      textAlign: 'center',
      cursor: 'pointer',
      textTransform: 'uppercase',
      marginBottom: '10px',
      '&:hover': {
        color: colors.red,
        boxShadow: 'none',
        background: colors.white,
        border: `1px solid ${colors.white}`,
      },
    },
  }
})

export default function FooterSocial() {
  const { classes } = useStyles()
  const socialLinks = [
    {
      logo: <FaFacebookF className={classes.social} />,
      link: FACEBOOK_URL,
    },
    {
      logo: <FaLinkedin className={classes.social} />,
      link: LINKEDIN_URL,
    },
    {
      logo: <FaTwitter className={classes.social} />,
      link: TWITTER_URL,
    },
    {
      logo: <FaYoutube className={classes.social} />,
      link: YOUTUBE_URL,
    },
    {
      logo: <FaInstagram className={classes.social} />,
      link: INSTAGRAM_URL,
    },
  ]

  return (
    <Grid
      item
      container
      alignItems='start'
      xs={12}
      sm={6}
      md={4}
      m={{ xs: '1rem auto 1rem auto', md: '1.3rem auto' }}
    >
      <Grid item xs={12} container justifyContent='flex-start'>
        <Grid>
          <MainTitle text='Rejoignez la team' titleColor='white' />
          <Grid mt={2}>
            <Button component={RouterLink} to='/offre-demploi-42c' className={classes.button}>
              CANDIDATS
            </Button>
          </Grid>
          <Grid item container xs={12} md={10} xl={12} mt={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {socialLinks.map((social, key) => {
                return (
                  <SocialLogo link={social.link} key={key}>
                    {social.logo}
                  </SocialLogo>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
