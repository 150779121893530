export const homePage = 'page-accueil'
export const expertsPage = 'page-experts'
export const labPage = 'page-lab'
export const successStoriesPage = 'page-success-stories'
export const contactPage = 'page-contact'
export const legalNoticesPage = 'page-mentions-legales'
export const joinUsPage = 'page-qui-sommes-nous'
export const applicationsPage = 'page-postuler'
export const multiSolutionsPage = 'page-multi-solutions'
export const activitySectorsPage = 'page-secteurs-d-activites'
export const talkingAboutUsPage = 'page-on-parle-de-nous'
export const cguPage = 'page-cgu'
