import React from 'react'

import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../assets/styles/styles.module.scss'

interface ISectionTitle {
  text: string
  titleColor: 'orange' | 'white'
}

export default function SectionTitle({ text, titleColor }: ISectionTitle) {
  const useStyles = makeStyles()({
    title: {
      fontWeight: 'bold',
      color: titleColor === 'orange' ? colors.orange : colors.white,
    },
  })
  const { classes } = useStyles()

  return (
    <Box mb={3} component='header'>
      <Typography
        variant='h4'
        align='left'
        className={classes.title}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Box>
  )
}
