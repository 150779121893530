import React from 'react'
import ApplyPage from '../parts/pages/ApplyPage'
import { useParams } from 'react-router-dom'

export default function Apply() {
  return <ApplyPage />
}

export function ApplyByJobOffer() {
  const { jobOfferId = '' } = useParams()

  return <ApplyPage {...{ jobOfferId }} />
}
