import RouterLink from '../../common/router-link/RouterLink'
import { Button, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material'

import { theme } from '../../../assets/themes/theme'

import { FaFacebookF, FaLinkedin, FaTwitter } from 'react-icons/fa'
import SocialLogo from '../../../components/common/navbar/SocialLogo'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'
import { useLocation } from 'react-router-dom'

export const useStyles = makeStyles()({
  social: {
    color: colors.orange,
    height: '15px',
    width: '15px',
  },
  applyBtn: {
    width: '90px',
    height: '40px',
    borderRadius: '0px',
    background: colors.orangeGradient,
    textTransform: 'uppercase',
    boxShadow: 'none',
    color: colors.white,
    border: `1px solid ${colors.orangeGradient}`,
    fontSize: '11px',
    fontWeight: 'bold',
    '&:hover': {
      color: `${colors.orange} !important`,
      boxShadow: 'none !important',
      background: `${colors.white} !important`,
      border: `1px solid ${colors.orange} !important`,
    },
  },
})

interface IApplySection {
  jobOfferId: string
  host?: string
  logoUrl?: string
  entityTitle: string
  entityDescription: string
  title: string
  description: string
}

export default function ApplySection({
  jobOfferId,
  host,
  logoUrl,
  entityTitle,
  entityDescription,
  title,
  description,
}: IApplySection) {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { classes } = useStyles()
  const location = useLocation()

  const socialLinks = [
    {
      logo: <FaFacebookF className={classes.social} />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        host + location.pathname
      )}`,
    },
    {
      logo: <FaLinkedin className={classes.social} />,
      link: `https://www.linkedin.com/shareArticle/?url=${encodeURIComponent(
        host + location.pathname
      )}`,
    },
    {
      logo: <FaTwitter className={classes.social} />,
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(host + location.pathname)}`,
    },
  ]

  return (
    <Grid item container xs={10} sm={10} md={3} lg={3} xl={3} mt={isMobileFormat ? 2 : 10}>
      <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
        <Grid item xs={12}>
          <Grid item md={5} sm={2} xs={4}>
            <CardMedia src={logoUrl ?? '/logos-42c/logo42nav.png'} component='img' alt='Logo' />
          </Grid>
          <Typography
            variant='h6'
            fontWeight='bold'
            dangerouslySetInnerHTML={{
              __html: entityTitle,
            }}
          />
          <Typography
            variant='body2'
            color='black'
            mt={1}
            dangerouslySetInnerHTML={{
              __html: entityDescription,
            }}
          />
        </Grid>
        <Grid item md={12} mt={3}>
          <Typography
            variant='h6'
            fontWeight='bold'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Typography
            variant='body2'
            color='black'
            mt={1}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Grid>
        <Grid container direction={{ xl: 'column', sm: 'row', xs: 'column' }}>
          <Grid
            component={RouterLink}
            to={`/postuler/${jobOfferId}`}
            item
            xs={2}
            md={12}
            mt={isMobileFormat ? 3 : 3}
          >
            <Button variant='outlined' className={classes.applyBtn}>
              Postuler
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            md={12}
            container
            alignItems='center'
            color='#fff'
            mt={3}
            ml={{ xl: 0, lg: 0, md: 0, sm: '10px', xs: 0 }}
          >
            <Typography
              fontWeight='bold'
              color='textPrimary'
              fontSize='0.8rem'
              textTransform='uppercase'
            >
              Partager :
            </Typography>

            {socialLinks.map((social, key) => {
              return (
                <SocialLogo link={social.link} key={key}>
                  {social.logo}
                </SocialLogo>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
