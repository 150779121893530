import React from 'react'
import { Box, Typography } from '@mui/material'
import LatestTweet from './LatestTweet'
import { SocialMediaTwitter as SocialMediaTwitterType } from '../../../../shared/types/social-media/SocialMediaTwitter'

interface ISocialMediaTwitter {
  tweets: SocialMediaTwitterType[]
}

export default function SocialMediaTwitter({ tweets }: ISocialMediaTwitter) {
  return tweets && tweets.length ? (
    <>
      {tweets.map((tweet, k: number) => (
        <LatestTweet tweet={tweet} key={k} />
      ))}
    </>
  ) : (
    <Box>
      <Typography align='center'>Aucun contenu Twitter disponible.</Typography>
    </Box>
  )
}
