import useSWR from 'swr'
import { Banderole } from '../../shared/types/banderole/Banderole'

const FETCH_BANDEROLES_URL = `${process.env.REACT_APP_BASE_URL}/banderole`

export async function fetchBanderoleByPage(page: string) {
  const response = await fetch(`${FETCH_BANDEROLES_URL}/page/${page}`)
  return response.json()
}

export function useBanderole(page: string) {
  const { data: pageBanderole } = useSWR<Banderole>(`api/banderole/page/${page}`, () =>
    fetchBanderoleByPage(page)
  )

  return { pageBanderole }
}
