import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

const RouterLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
export default RouterLink
