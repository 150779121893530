import { Grid, useMediaQuery } from '@mui/material'
import colors from '../../assets/styles/styles.module.scss'
import { theme } from '../../assets/themes/theme'
import Actualities from '../../components/common/actualities/all/Actualities'
import ApplyForm from '../../components/apply/ApplyForm'
import CustomFooter from '../CustomFooter'

import { applicationsPage } from '../../shared/constants/pages-titles'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'
import HeaderPage from '../../components/common/header-page/HeaderPage'

export interface IApplyPage {
  jobOfferId?: string
}

export default function ApplyPage({ jobOfferId }: IApplyPage) {
  const { view } = useViews('APPLICATION')
  const { metaTitle, metaDescription } = view ?? {}
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />

      <HeaderPage
        viewCategory='APPLICATION'
        pageTitle={'<strong>Postuler</strong>'}
        withDescription
      />

      <ApplyForm {...{ jobOfferId }} />
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={applicationsPage} />
    </>
  )
}
