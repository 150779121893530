import React from 'react'

import { theme } from '../../assets/themes/theme'
import { Typography, Grid, useMediaQuery } from '@mui/material'
import ExpertCard from './ExpertCard'

import { useExpertsBySector } from '../../hooks/experts/useExperts'
import { Expert } from '../../shared/types/expert/Expert'

interface IInlineExpertsBySector {
  sector: string
}

export default function InlineExpertsBySector({ sector }: IInlineExpertsBySector) {
  const { experts } = useExpertsBySector(sector)
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid item mt={1}>
      <Typography
        gutterBottom
        pb={1}
        color='#000000'
        fontWeight='bold'
        fontSize={isMobileFormat ? '13px' : '14px'}
        dangerouslySetInnerHTML={{
          __html: sector,
        }}
      />
      <Grid container spacing={1}>
        {experts?.map((expert: Expert) => (
          <Grid item mb={1} xs={12} sm={12} key={expert._id}>
            <ExpertCard
              linkedInUrl={expert.linkedInUrl}
              imageUrl={expert.imageUrl}
              firstName={expert.firstName}
              lastName={expert.lastName}
              poste={expert.poste}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
