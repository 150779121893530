import React from 'react'

import { Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../../assets/themes/theme'
import colors from '../../../assets/styles/styles.module.scss'
import CountUp from 'react-countup'
import { InView } from 'react-intersection-observer'
import { useSecondBanderole } from '../../../hooks/banderole/useSecondBanderole'
import { makeStyles } from 'tss-react/mui'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function SecondBanderole() {
  const isMediumFormat = useMediaQuery(theme.breakpoints.up('md'))
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const useStyles = makeStyles()({
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: isMobileFormat ? '10px' : '40px',
    },
    img: {
      width: isMediumFormat ? '70px' : isMobileFormat ? '40px' : '90px',
      height: isMediumFormat ? '70px' : isMobileFormat ? '40px' : '90px',
    },
    text: { color: 'white', textAlign: 'center' },
    stats: {
      fontSize: isMobileFormat ? '16px' : '',
      margin: isMobileFormat ? 0.5 : 0,
    },
    description: {
      fontSize: isMobileFormat ? '11px' : '',
      margin: isMobileFormat ? 0 : 1,
    },
  })
  const { secondBanderole } = useSecondBanderole()
  const { secondBanderoleStatistics, firstImageUrl, secondImageUrl, thirdImageUrl } =
    secondBanderole ?? {}

  const [revenues, yearsOfGrowth, otherStatistics] = secondBanderoleStatistics ?? ''

  const { classes } = useStyles()

  return (
    <Grid
      style={{
        background: colors.orangeGradient,
        display: 'flex',
      }}
      container
      justifyContent='center'
    >
      {secondBanderole && (
        <>
          <Grid
            item
            alignItems='center'
            justifyContent={isMobileFormat ? 'center' : 'flex-end'}
            container
            xs={4}
            p={1}
          >
            <Grid
              container
              item
              className={classes.container}
              justifyContent='flex-end'
              direction={isMobileFormat ? 'column' : 'row'}
            >
              {firstImageUrl && (
                <LazyLoadImage
                  effect='opacity'
                  src={firstImageUrl}
                  alt='Icône de statistiques'
                  className={classes.img}
                />
              )}
              <Grid item xs={12} sm={12} md={7} xl={4} className={classes.text}>
                <InView>
                  {({ inView, ref }) => (
                    <Typography
                      variant={isMobileFormat ? 'h5' : 'h4'}
                      className={classes.stats}
                      ref={ref}
                    >
                      {inView ? (
                        <CountUp useEasing={true} end={revenues?.number} duration={2} />
                      ) : (
                        '0'
                      )}
                      {revenues?.unit}
                    </Typography>
                  )}
                </InView>
                <Typography
                  variant='body2'
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: revenues?.description,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={isMobileFormat ? 'center' : 'center'}
            alignItems='center'
            container
            xs={4}
            p={1}
          >
            <Grid
              item
              container
              className={classes.container}
              justifyContent='center'
              direction={isMobileFormat ? 'column' : 'row'}
            >
              {thirdImageUrl && (
                <LazyLoadImage
                  effect='opacity'
                  src={thirdImageUrl}
                  alt='Icône de statistiques'
                  className={classes.img}
                />
              )}
              <Grid item xs={12} sm={12} md={7} xl={4} className={classes.text}>
                <InView>
                  {({ inView, ref }) => (
                    <Typography
                      variant={isMobileFormat ? 'h5' : 'h4'}
                      className={classes.stats}
                      ref={ref}
                    >
                      {inView ? (
                        <CountUp useEasing={true} end={otherStatistics?.number} duration={2} />
                      ) : (
                        '0'
                      )}
                      {otherStatistics?.unit}
                    </Typography>
                  )}
                </InView>
                <Typography
                  variant='body2'
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: otherStatistics?.description,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={isMobileFormat ? 'center' : 'flex-start'}
            alignItems='center'
            container
            xs={4}
            p={1}
          >
            <Grid
              item
              container
              className={classes.container}
              justifyContent='flex-start'
              direction={isMobileFormat ? 'column' : 'row'}
            >
              {secondImageUrl && (
                <LazyLoadImage
                  effect='opacity'
                  src={secondImageUrl}
                  alt='Icône de statistiques'
                  className={classes.img}
                />
              )}
              <Grid item xs={12} sm={12} md={7} xl={4} className={classes.text}>
                <InView>
                  {({ inView, ref }) => (
                    <Typography
                      variant={isMobileFormat ? 'h5' : 'h4'}
                      className={classes.stats}
                      ref={ref}
                    >
                      {inView ? (
                        <CountUp useEasing={true} end={yearsOfGrowth?.number} duration={2} />
                      ) : (
                        '0'
                      )}
                      {yearsOfGrowth?.unit}
                    </Typography>
                  )}
                </InView>
                <Typography
                  variant='body2'
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: yearsOfGrowth?.description,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
