import { CardMedia, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import SEO from '../../components/common/seo/SEO'
import { useJobOffer } from '../../hooks/job-offers/useJobOffers'
import CustomFooter from '../CustomFooter'
import Paragraph from '../../components/common/custom-paragraphs/Paragraph'
import ApplySection from '../../components/job-offers/job-offer-details-page/ApplySection'
import FAQ from '../../components/job-offers/job-offer-details-page/FAQ'
import JobOfferBanderole from '../../components/job-offers/job-offer-details-page/JobOfferBanderole'

interface IJobOfferPage {
  jobOfferSlug: string
  host?: string
}

export default function JobOfferPage({ jobOfferSlug, host }: IJobOfferPage) {
  const { jobOffer } = useJobOffer(jobOfferSlug)

  const {
    _id,
    title,
    description,
    category,
    jobLocation,
    level,
    banderoleImageUrl,
    logoUrl,
    entity,
    about,
    imageUrl,
    littleDescription,
    profile,
    startDate,
    quotes,
    questionsResponses,
    videoUrl,
    metaTitle,
    metaDescription,
  } = jobOffer ?? {}

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <SEO
        metaTitle={metaTitle ?? title}
        metaDescription={metaDescription ?? littleDescription}
        ogCard
        ogTitle={title}
        imageUrl={imageUrl}
        description={description}
      />

      <JobOfferBanderole
        title={title ?? ''}
        category={category ?? ''}
        startDate={startDate ?? ''}
        jobLocation={jobLocation ?? ''}
        level={level ?? ''}
        banderoleImageUrl={banderoleImageUrl ?? '/img/1.png'}
      />
      <Container maxWidth='lg'>
        <Grid container justifyContent='center' mt={4}>
          <ApplySection
            jobOfferId={`${_id}`}
            host={host}
            logoUrl={logoUrl}
            title={title ?? ''}
            description={littleDescription ?? ''}
            entityTitle={entity ? entity.title : ''}
            entityDescription={entity ? entity.description : ''}
          />
          <Grid
            container
            justifyContent='flex-end'
            item
            xs={10}
            sm={10}
            md={7}
            lg={7}
            xl={8}
            mt={8}
          >
            {about && (
              <Grid item xs={12} md={10}>
                <Paragraph textColor='black' title='À propos' text={about} />
              </Grid>
            )}
            {imageUrl && (
              <Grid item xs={12} mb={3}>
                <CardMedia component='img' src={imageUrl} alt="Image de présentation de l'offre" />
              </Grid>
            )}

            {description && (
              <Grid item xs={12} md={10} mt={4}>
                <Paragraph textColor='black' title='Descriptif du poste' text={description} />
              </Grid>
            )}
            {profile && (
              <Grid item xs={12} md={10} mt={4}>
                <Paragraph textColor='black' title='Profil recherché' text={profile} />
              </Grid>
            )}
            {videoUrl && (
              <Grid item xs={12} md={10} mt={4}>
                <Typography variant='h4' color='textPrimary' fontWeight='bold'>
                  Découvrez l'équipe
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <Grid container justifyContent='center' alignItems='center' mt={3} mb={6}>
        <Grid item xs={0} md={2} />
        <Grid item xs={10} sm={10} md={7} xl={5} container>
          <Grid item xs={12}>
            <Grid item xs={12} mt={4} container justifyContent='center' alignItems='center'>
              {videoUrl && (
                <CardMedia
                  component='iframe'
                  src={'https://www.youtube.com/embed/' + videoUrl}
                  sx={{
                    width: '100%',
                    height: {
                      xl: '30rem',
                      lg: '30rem',
                      md: '25rem',
                      sm: '25rem',
                      xs: '15rem',
                    },
                    border: '0',
                  }}
                />
              )}
              <Grid item container spacing={7} mt={2}>
                {quotes?.map(({ _id: id, firstName, lastName, role, quote }) => (
                  <Grid item xs={12} sm={6} key={id}>
                    <Typography
                      variant={isMobileFormat ? 'h6' : 'h5'}
                      align='left'
                      sx={{
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      “{quote}”
                    </Typography>
                    <Typography
                      variant='body2'
                      align='left'
                      mt={1}
                      sx={{
                        color: 'black',
                      }}
                    >
                      {firstName + ' ' + lastName + ' - ' + role}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {questionsResponses && questionsResponses.length > 0 && (
              <FAQ FAQs={questionsResponses} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <CustomFooter />
    </>
  )
}
