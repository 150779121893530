import useSWR from 'swr'
import { JobOffer } from '../../shared/types/job-offer/JobOffer'

const FETCH_JOB_OFFERS_URL = `${process.env.REACT_APP_BASE_URL}/job-offers`

function paramsToUrl(search?: string, jobLocation?: string, category?: string, page?: string) {
  let url = ''

  if (search?.length) {
    url += `search=${search}&`
  }

  if (jobLocation?.length) {
    url += `jobLocation=${jobLocation}&`
  }

  if (category?.length) {
    url += `category=${category}&`
  }

  if (page?.length) {
    url += `page=${page}&`
  }

  return url
}

export async function fetchAllJobOffers(): Promise<JobOffer[]> {
  const response = await fetch(`${FETCH_JOB_OFFERS_URL}/all`)
  return await response.json()
}

export async function fetchFavoriteJobOffers(): Promise<JobOffer[]> {
  const response = await fetch(`${FETCH_JOB_OFFERS_URL}/all/favorite`)
  return await response.json()
}

export async function fetchJobOfferBySlug(JobOfferSlug: string): Promise<JobOffer> {
  const response = await fetch(`${FETCH_JOB_OFFERS_URL}/slug/${JobOfferSlug}`)
  return await response.json()
}

export async function fetchFilteredJobOffers(
  search?: string,
  jobLocation?: string,
  category?: string,
  page?: string
): Promise<JobOffer[]> {
  const params = paramsToUrl(search, jobLocation, category, page)
  const response = await fetch(`${FETCH_JOB_OFFERS_URL}/filter?${params}`)
  return await response.json()
}

export function useJobOffers() {
  const { data: jobOffers } = useSWR<JobOffer[]>('api/job-offer/all', fetchAllJobOffers)
  const { data: favoriteJobOffers } = useSWR<JobOffer[]>(
    '/api/favorite-job-offer/all',
    fetchFavoriteJobOffers
  )

  return { jobOffers, favoriteJobOffers }
}

export function useJobOffersFilter(filterJobOffersType?: {
  search?: string
  jobLocation?: string
  category?: string
  page?: string
}) {
  const { search, jobLocation, category, page } = filterJobOffersType ?? {}
  const { data: filteredJobOffers, mutate: mutateFilterJobOffer } = useSWR<JobOffer[]>(
    `/api/job-offers/filter?page={${page}}`,
    () => fetchFilteredJobOffers(search, jobLocation, category, page)
  )

  return { filteredJobOffers, mutateFilterJobOffer }
}

export function useJobOffer(JobOfferSlug: string) {
  const { data: jobOffer } = useSWR<JobOffer>(`/api/job-offers/slug/${JobOfferSlug}`, () =>
    fetchJobOfferBySlug(JobOfferSlug)
  )
  return { jobOffer }
}
