import useSWR from 'swr'
import { Estiam } from '../../shared/types/estiam/Estiam'

const FETCH_ESTIAM_URL = `${process.env.REACT_APP_BASE_URL}/estiam`

export async function fetchAllEstiam(): Promise<Estiam[]> {
  const response = await fetch(`${FETCH_ESTIAM_URL}/all`)
  return await response.json()
}

export function useEstiam() {
  const { data: estiamData } = useSWR<Estiam[]>('api/estiam/all', fetchAllEstiam)

  return { estiamData }
}
