import React from 'react'

import { Container, Grid } from '@mui/material'

import Actuality from '../single/Actuality'
import ActualitiesBtn from '../all/ActualitiesBtn'
import MainTitle from '../../custom-titles/MainTitle'

import { useViews } from '../../../../hooks/views/useViews'
import { useActualities } from '../../../../hooks/actualities/useActualities'

import { BLOG_URL } from '../../../../shared/constants/social-media-links'

export default function Actualities() {
  const { view } = useViews('ACTUALITY')
  const { title } = view ?? {}

  const { actualities } = useActualities()

  return (
    <Container
      maxWidth='xl'
      disableGutters
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '20px',
      }}
    >
      <Grid item container xs={10} justifyContent='center' m='auto'>
        <Grid container mb={4}>
          <Grid item xs={12} sm={6} md={7}>
            <MainTitle text={title ?? 'ACTUALITÉS'} titleColor='white' />
          </Grid>
          <Grid
            item
            container
            justifyContent={{ xs: 'center', sm: 'end' }}
            xs={12}
            sm={6}
            md={5}
            mt={{ xs: 1, sm: 0 }}
            mb={{ xs: 1, sm: 0 }}
          >
            <ActualitiesBtn text="Toute l'actualité" link={BLOG_URL} />
          </Grid>
        </Grid>

        <Grid container m='auto' spacing={{ xs: 0.5, sm: 1 }}>
          {actualities?.map(({ id, title, imageUrl, description, link }) => (
            <Actuality
              link={link}
              key={id}
              title={title}
              description={description}
              imageUrl={imageUrl}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}
