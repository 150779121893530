import React from 'react'
import { Route } from '../../shared/types/route/Route'
import ActivitySectors, { ActivitySectorSingle } from '../../pages/ActivitySectors'
import Apply, { ApplyByJobOffer } from '../../pages/Apply'
import Contact from '../../pages/Contact'
import LegalNotices from '../../pages/LegalNotices'
import CGU from '../../pages/CGU'
import Estiam from '../../pages/Estiam'
import Experts from '../../pages/Experts'
import Home from '../../pages/Home'
import JobOffers, { JobOfferDetails } from '../../pages/JobOffers'
import JoinUs from '../../pages/JoinUs'
import Lab from '../../pages/Lab'
import MatchCV from '../../pages/MatchCV'
import MatchCVResult from '../../pages/MatchCVResult'
import MultiSolutions, { MultiSolutionSingle } from '../../pages/MultiSolutions'
import PageNotFound from '../../pages/PageNotFound'
import SuccessStories from '../../pages/SuccessStories'
import TalkingAboutUs from '../../pages/TalkingAboutUs'
import { useActivitySectors } from '../activity-sectors/useActivitySectors'
import { useMultiSolutions } from '../multi-solutions/useMultiSolutions'

export function useRoutesList() {
  const { multiSolutions } = useMultiSolutions()
  const { activitySectors } = useActivitySectors()

  const multiSolutionsChildren =
    multiSolutions?.map((multiSolution) => ({
      index: false,
      path: `/multi-solutions/${multiSolution.slug}`,
    })) ?? []

  const activitySectorsChildren =
    activitySectors?.map((activitySector) => ({
      index: false,
      path: `/secteurs-dactivite-42c/${activitySector.slug}`,
    })) ?? []

  const routes: Route[] = [
    {
      element: <Home />,
      index: true,
    },
    {
      element: <Lab />,
      index: false,
      path: '/42c-lab',
    },
    {
      element: <Contact />,
      index: false,
      path: '/contact',
    },
    {
      element: <LegalNotices />,
      index: false,
      path: '/mentions-legales',
    },
    {
      element: <CGU />,
      index: false,
      path: '/cgu',
    },
    {
      element: <Estiam />,
      index: false,
      path: '/estiam',
    },
    {
      element: <MatchCV />,
      index: false,
      path: '/matche-ton-cv',
    },
    {
      element: <MatchCVResult />,
      index: false,
      path: '/matche-ton-cv/resultats',
    },
    {
      element: <Experts />,
      index: false,
      path: '/nos-experts-42c',
    },
    {
      element: <JoinUs />,
      index: false,
      path: '/qui-sommes-nous',
    },
    {
      element: <TalkingAboutUs />,
      index: false,
      path: '/on-parle-de-nous',
    },
    {
      element: <SuccessStories />,
      index: false,
      path: '/references-42c',
    },
    {
      element: <ActivitySectors />,
      index: false,
      path: '/secteurs-dactivite-42c',
    },
    {
      element: <JobOffers />,
      index: false,
      path: '/offre-demploi-42c',
    },
    {
      element: <Apply />,
      index: false,
      path: '/postuler',
    },
    {
      element: <MultiSolutions />,
      index: false,
      path: '/multi-solutions',
    },
    {
      element: <JobOfferDetails />,
      index: false,
      path: '/offre-demploi-42c/:slug',
    },
    {
      element: <ApplyByJobOffer />,
      index: false,
      path: '/postuler/:jobOfferId',
    },
    {
      element: <ActivitySectorSingle />,
      index: false,
      path: '/secteurs-dactivite-42c/:slug',
    },
    {
      element: <MultiSolutionSingle />,
      index: false,
      path: '/multi-solutions/:slug',
    },
    {
      element: <PageNotFound />,
      index: false,
      path: '*',
    },
  ]

  return { routes, multiSolutionsChildren, activitySectorsChildren }
}
