import React from 'react'

import { Typography, Button, useMediaQuery, Box } from '@mui/material'
import { theme } from '../../../assets/themes/theme'
import ReferenceCard from './ReferenceCard'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'

interface IReference {
  _id: string
  title: string
  imageUrl: string
  category: string
  summary: string
  description: string
  articleLink?: string
  fileLink?: string
}

const useStyles = makeStyles()({
  box: {
    display: 'block',
    transition: '.5s ease',
    '&:hover $resume': {
      display: 'flex',
    },
    position: 'relative',
  },
  boxResume: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    opacity: '0',
    transition: '.5s ease',
    backgroundColor: colors.orange,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 0.9,
    },
  },
  resume: {
    display: 'block',
    color: 'white',
    padding: '10px 50px 0 50px',
    textAlign: 'center',
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    opacity: 1,
  },
})

export default function Reference({
  _id: id,
  title,
  imageUrl,
  category,
  summary,
  articleLink,
  fileLink,
}: IReference) {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const { classes } = useStyles()

  return (
    <>
      <Button
        href={fileLink ? fileLink : articleLink ? articleLink : ''}
        download
        target='_blank'
        sx={{ width: '100%', margin: 0, padding: 0 }}
      >
        <Box className={classes.box}>
          <ReferenceCard _id={id} imageUrl={imageUrl} title={title} category={category} />
          <Box className={classes.boxResume}>
            <Typography
              className={classes.resume}
              align='center'
              variant={isMobileFormat ? 'body2' : 'body1'}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          </Box>
        </Box>
      </Button>
    </>
  )
}
