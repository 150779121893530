import { ContactForm } from '../../shared/types/contact/ContactForm'

const CONTACT_URL = `${process.env.REACT_APP_BASE_URL}/question-contact`

export function useContact() {
  async function addContact(contactFormValues: ContactForm): Promise<ContactForm | any> {
    const response = await fetch(`${CONTACT_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactFormValues),
    })

    return await response.json()
  }

  return { addContact }
}
