import React from 'react'

import { uniq } from 'lodash'

import { Container, Grid } from '@mui/material'

import { useExperts } from '../../hooks/experts/useExperts'

import ExpertsBySector from './ExpertsBySector'
import InlineExpertsBySector from './InlineExpertsBySector'

export default function ExpertsList() {
  const { experts } = useExperts()

  const sectors = uniq(experts?.map(({ sector }) => sector))

  const rest = sectors.filter(
    (sector) => sector !== 'Ressources Humaines' && sector !== 'Marketing'
  )

  const communicationAndRHExperts = sectors.filter(
    (sector) => sector === 'Ressources Humaines' || sector === 'Marketing'
  )

  return (
    <Container maxWidth={'md'}>
      <Grid
        sx={{ marginLeft: { md: 10 }, mb: 5 }}
        item
        container
        direction='row'
        spacing={2}
        xl={11}
      >
        {rest.map((sector) => (
          <Grid xs={10} item mt={1} key={sector}>
            <ExpertsBySector sector={sector} />
          </Grid>
        ))}
        {communicationAndRHExperts.map((sector) => (
          <Grid item xs={5} sm={2.5} key={sector}>
            <InlineExpertsBySector sector={sector} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
