import React from 'react'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { useActivitySectors } from '../../hooks/activity-sectors/useActivitySectors'
import Actualities from '../../components/common/actualities/all/Actualities'
import ActivitySectorCard from '../../components/activity-sectors/ActivitySectorCard'
import Paragraph from '../../components/common/custom-paragraphs/Paragraph'
import SectionTitle from '../../components/common/custom-titles/SectionTitle'
import CustomFooter from '../CustomFooter'
import { activitySectorsPage } from '../../shared/constants/pages-titles'
import colors from '../../assets/styles/styles.module.scss'
import { theme } from '../../assets/themes/theme'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  box: {
    padding: '0.5rem 0',
  },
  title: {
    textTransform: 'uppercase',
    color: colors.white,
    fontSize: '12px !important',
  },
})

export default function ActivitySectorsPage() {
  const { view } = useViews('ACTIVITY_SECTOR')
  const { activitySectors } = useActivitySectors()
  const { classes } = useStyles()
  const { metaTitle, metaDescription, description, title } = view ?? {}
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <Container maxWidth='lg'>
        <Box>
          <Grid container direction='row-reverse' style={{ margin: 'auto', display: 'flex' }}>
            <Grid
              item
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={12}
              sx={{
                background: colors.orangeGradient,
                height: '550px',
                position: 'relative',
                top: { xs: '70px', md: '100px' },
                padding: '20px',
              }}
            >
              <Grid item xl={1} lg={1} md={2} sm={2} xs={4}>
                <Box sx={{ width: 100 }} mb={3} className={classes.box} component='header'>
                  {title && (
                    <Typography align='left' className={classes.title}>
                      <strong>/ {title.split(/[\s-]+/)[0]}</strong>

                      {title.replace(title.split(/[\s-]+/)[0], '')}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                container
                item
                justifyContent='end'
                alignItems='center'
                style={{ margin: 'auto', display: 'flex' }}
              >
                <Box
                  sx={{
                    padding: 5,
                  }}
                >
                  <SectionTitle text={title ?? ''} titleColor='white' />
                  <Paragraph text={description ?? ''} textColor='white' />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container disableGutters maxWidth='lg'>
        <Grid item container xl={9} lg={8} md={8} sm={10} xs={10} m='auto auto -50px auto'>
          {activitySectors?.map(
            ({ _id: id, imageUrl, slug, subTitle, subDescription, gifUrl, isFavorite }) => {
              if (isFavorite) {
                return (
                  <Grid container direction='column' justifyContent='center' mt={2} key={id}>
                    <ActivitySectorCard
                      slug={slug}
                      subTitle={subTitle}
                      subDescription={subDescription}
                      imageUrl={imageUrl}
                      highlight={true}
                      gifUrl={gifUrl ?? imageUrl}
                    />
                  </Grid>
                )
              }
              return null
            }
          )}
          {activitySectors?.map(
            ({ _id: id, imageUrl, slug, subTitle, subDescription, gifUrl, isFavorite }) => {
              if (!isFavorite) {
                return (
                  <Grid container direction='column' justifyContent='center' mt={2} key={id}>
                    <ActivitySectorCard
                      slug={slug}
                      subTitle={subTitle}
                      subDescription={subDescription}
                      imageUrl={imageUrl}
                      highlight={false}
                      gifUrl={gifUrl ?? imageUrl}
                    />
                  </Grid>
                )
              }
              return null
            }
          )}
        </Grid>
      </Container>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '8rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={activitySectorsPage} />
    </>
  )
}
