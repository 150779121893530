/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'

import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'

import SuccessStory from '../../components/common/reference/Reference'
import SuccessStoriesFilter from '../../components/success-stories/SuccessStoriesFilter'
import CustomFooter from '../CustomFooter'
import Actualities from '../../components/common/actualities/all/Actualities'
import { useSuccessStoriesFilter } from '../../hooks/success-stories/useSuccessStories'
import { SuccessStory as SuccessStoryType } from '../../shared/types/success-story/SuccessStory'

import InfiniteScroll from 'react-infinite-scroller'
import { successStoriesPage } from '../../shared/constants/pages-titles'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'
import { makeStyles } from 'tss-react/mui'
import HeaderPage from '../../components/common/header-page/HeaderPage'

const useStyles = makeStyles()({
  gridItem: {
    textAlign: 'center',
  },
  mobileGridItem: {
    margin: 'auto',
    textAlign: 'center',
    marginBottom: '10px',
  },
})

export default function SuccessStoriesPage() {
  const { view } = useViews('SUCCESS_STORY')

  const { metaTitle, metaDescription } = view ?? {}

  const { filteredSuccessStories } = useSuccessStoriesFilter()

  const [hasMore, setHasMore] = useState(true)
  const [displayedSuccessStories, setDisplayedSuccessStories] = useState<SuccessStoryType[]>([])

  function displayMore() {
    if (filteredSuccessStories) {
      const toAddElementsLength =
        filteredSuccessStories.length - displayedSuccessStories.length > 6
          ? 6
          : filteredSuccessStories.length - displayedSuccessStories.length

      if (displayedSuccessStories.length < filteredSuccessStories.length) {
        setTimeout(() => {
          setDisplayedSuccessStories([
            ...displayedSuccessStories,
            ...filteredSuccessStories?.slice(
              displayedSuccessStories.length,
              displayedSuccessStories.length + toAddElementsLength
            ),
          ])
        }, 1000)
      } else {
        setHasMore(false)
      }
    }
  }

  useEffect(() => {
    if (filteredSuccessStories) {
      if (filteredSuccessStories.length <= 6) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setDisplayedSuccessStories([...filteredSuccessStories?.slice(0, 6)])
    }
  }, [filteredSuccessStories])

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { classes } = useStyles()

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />

      <HeaderPage
        viewCategory='SUCCESS_STORY'
        pageTitle={'Success <strong>Stories</strong>'}
        withDescription
      />

      <Grid mb={10}>
        <Container maxWidth='xl' disableGutters>
          <Grid container justifyContent='center'>
            <Grid item xs={10}>
              <SuccessStoriesFilter />

              <InfiniteScroll
                loadMore={displayMore}
                hasMore={hasMore}
                loader={
                  <Box display='flex' alignItems='center' justifyContent='center' key={'loader'}>
                    <Typography variant='body1' color='textPrimary' fontWeight='bold' mt={3}>
                      Chargement en cours ...
                    </Typography>
                  </Box>
                }
              >
                <Grid container>
                  {displayedSuccessStories ? (
                    <Grid container item xs={12} justifyContent='center'>
                      {displayedSuccessStories.map(
                        ({ _id: id, imageUrl, title, category, summary, description }) => (
                          <Grid
                            item
                            xs={10}
                            md={4}
                            key={id}
                            className={isMobileFormat ? classes.mobileGridItem : classes.gridItem}
                          >
                            <Grid container>
                              <SuccessStory
                                title={title}
                                imageUrl={imageUrl}
                                category={category}
                                _id={id}
                                description={description}
                                summary={summary}
                              />
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: 'linear-gradient(40deg, #ff0033, #ff5500)',
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}

      <CustomFooter page={successStoriesPage} />
    </>
  )
}
