import { createTheme } from '@mui/material/styles'

export const red = '#ff0033'
export const orange = '#ff5500'
export const purple = '#6633cc'
export const darkPurple = '#000033'

export const theme = createTheme({
  palette: {
    primary: {
      main: red,
    },
    secondary: {
      main: orange,
      dark: darkPurple,
      light: purple,
    },
    text: {
      primary: orange,
      secondary: purple,
    },
  },
  typography: {
    fontFamily: [
      'IBMPlexSans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
})
