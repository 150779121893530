import useSWR from 'swr'
import { Lab } from '../../shared/types/lab/Lab'

const FETCH_LAB_URL = `${process.env.REACT_APP_BASE_URL}/lab`

export async function fetchAllLab(): Promise<Lab[]> {
  const response = await fetch(`${FETCH_LAB_URL}/all`)
  return await response.json()
}

export function useLab() {
  const { data: labData } = useSWR<Lab[]>('api/lab/all', fetchAllLab)

  return { labData }
}
