import React from 'react'
import { useViews } from '../../../hooks/views/useViews'
import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { ViewCategory } from '../../../shared/types/view/View'
import { theme } from '../../../assets/themes/theme'
import Paragraph from '../custom-paragraphs/Paragraph'
import SectionTitle from '../custom-titles/SectionTitle'
import RouterLink from '../router-link/RouterLink'
import { makeStyles } from 'tss-react/mui'
import colors from '../../../assets/styles/styles.module.scss'

interface IHeaderPage {
  viewCategory: ViewCategory
  pageTitle: string
  contactButton?: boolean
  withDescription?: boolean
}

const useStyles = makeStyles()({
  button: {
    position: 'relative',
    background: colors.orangeGradient,
    fontFamily: 'IBMPlexSans',
    padding: '5px',
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
})

export default function HeaderPage({
  viewCategory,
  pageTitle,
  contactButton,
  withDescription,
}: IHeaderPage) {
  const { view } = useViews(viewCategory)
  const { description, title } = view ?? {}
  const { classes } = useStyles()

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth='xl' disableGutters sx={{ mb: contactButton ? 0 : 5 }}>
      <Box>
        <Grid container justifyContent='center' style={{ margin: 'auto', display: 'flex' }}>
          <Grid item xl={9} lg={9} md={9} sm={10} xs={11} p={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box pt={3} pb={3} pl={isMobileFormat ? 2 : 5} pr={isMobileFormat ? 2 : 5}>
                <Typography
                  align='left'
                  color='textPrimary'
                  textTransform='uppercase'
                  fontSize='13px !important'
                  dangerouslySetInnerHTML={{
                    __html: '<strong>/ </strong>' + pageTitle,
                  }}
                />
              </Box>
            </Grid>
            <Grid xl={7} lg={7} md={9} sm={11} xs={12} container item justifyContent='start'>
              <Box pl={isMobileFormat ? 2 : 5} pr={isMobileFormat ? 2 : 5}>
                <SectionTitle text={title ?? ''} titleColor='orange' />
                {withDescription && <Paragraph text={description ?? ''} textColor='black' />}
                {contactButton && (
                  <RouterLink to='/contact'>
                    <Button variant='outlined' className={classes.button}>
                      Nous contacter
                    </Button>
                  </RouterLink>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
