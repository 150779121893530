import React from 'react'

import { Button, Grid, Typography, Link, useMediaQuery, CardMedia } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { theme } from '../../assets/themes/theme'

import { FaFacebookF, FaLinkedin, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import SocialLogo from '../common/navbar/SocialLogo'

import { useViews } from '../../hooks/views/useViews'
import { useEstiam } from '../../hooks/estiam/useEstiam'
import colors from '../../assets/styles/styles.module.scss'
import ReactGA from 'react-ga4'

const useStyles = makeStyles()({
  button: {
    width: '80%',
    minHeight: '45px',
    background: 'transparent',
    color: colors.purple,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    padding: '6px',
    marginTop: '15px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: 0,
    border: `1px solid ${colors.purple}`,
    verticalAlign: 'middle',
    '&:hover': {
      color: colors.white,
      boxShadow: 'none',
      background: colors.purpleGradient,
      border: `1px solid ${colors.purple}`,
    },
  },
  mobileBtn: {
    width: '100%',
    height: '45px',
    background: 'transparent',
    color: colors.purple,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    padding: '5px 15px 5px 15px',
    margin: '0 10px 10px 10px',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    borderRadius: 0,
    border: `1px solid ${colors.purple}`,
    verticalAlign: 'middle',
    '&:hover': {
      color: colors.white,
      boxShadow: 'none',
      background: colors.purpleGradient,
      border: `1px solid ${colors.purple}`,
    },
  },
  social: {
    color: colors.purple,
    height: '15px',
    width: '15px',
  },
})

export default function EstiamPresentation() {
  const { estiamData = [] } = useEstiam()
  const { imageUrl, brochureUrl } = estiamData[0] ?? {}

  const { view } = useViews('ESTIAM')
  const { title, description } = view ?? {}

  const { classes } = useStyles()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  function handleDownload() {
    ReactGA.event({
      action: 'file_download',
      category: 'ÉSTIAM',
      label: 'éstiam_brochure',
    })
  }

  const socialLinks = [
    {
      logo: <FaFacebookF className={classes.social} />,
      link: 'https://fr-fr.facebook.com/estiameducation/',
    },
    {
      logo: <FaLinkedin className={classes.social} />,
      link: 'https://fr.linkedin.com/school/estiam/',
    },
    {
      logo: <FaTwitter className={classes.social} />,
      link: 'https://twitter.com/estiamofficiel',
    },
    {
      logo: <FaYoutube className={classes.social} />,
      link: 'https://www.youtube.com/channel/UC6TdoKYG0VjhV2GRP6wTBrQ',
    },
    {
      logo: <FaInstagram className={classes.social} />,
      link: 'https://www.instagram.com/estiamofficiel/',
    },
  ]

  return (
    <Grid container justifyContent='center'>
      <Grid
        item
        container
        justifyContent={isMobileFormat ? 'center' : ''}
        direction={isMobileFormat ? 'row' : 'column'}
        xs={10}
        sm={10}
        md={3}
        lg={3}
        xl={3}
        mt={isMobileFormat ? 8 : 20}
      >
        <Grid item xs={12}>
          <Link href='https://www.estiam.education/programmes/' target='_blank' underline='none'>
            <Button className={isMobileFormat ? classes.mobileBtn : classes.button}>
              Voir les formations
            </Button>
          </Link>
          <Link
            href={brochureUrl}
            download
            underline='none'
            onClick={handleDownload}
            target='_blank'
          >
            <Button className={isMobileFormat ? classes.mobileBtn : classes.button}>
              Télécharger la plaquette
            </Button>
          </Link>
          <Link href='mailto:contact@estiam.com' underline='none'>
            <Button className={isMobileFormat ? classes.mobileBtn : classes.button}>
              Contacter l'école
            </Button>
          </Link>
          <Grid
            item
            xs={12}
            container
            alignItems='center'
            color='#fff'
            mt={3}
            marginLeft={isMobileFormat ? '10px' : 0}
          >
            <Typography
              fontWeight='bold'
              color='textSecondary'
              fontSize='0.8rem'
              textTransform='uppercase'
            >
              Partager :
            </Typography>

            {socialLinks.map((social, key) => {
              return (
                <SocialLogo link={social.link} key={key}>
                  {social.logo}
                </SocialLogo>
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10} sm={10} md={7} lg={7} xl={8} mt={8}>
        <Grid item xs={7} sm={4} md={4} lg={3} xl={3}>
          <Link
            href='https://www.estiam.education'
            target='_blank'
            underline='none'
            aria-label={'Site éstiam'}
            role='link'
          >
            <LazyLoadImage
              effect='opacity'
              src='/logos-estiam/estiam.png'
              alt='Logo Éstiam '
              width='100%'
              height='100%'
            />
          </Link>
        </Grid>
        <Typography
          variant='body2'
          mt={5}
          fontWeight='bold'
          color='black'
          dangerouslySetInnerHTML={{
            __html: title ?? '',
          }}
        />
        <Typography
          variant='body2'
          color='black'
          mt={2}
          mb={4}
          dangerouslySetInnerHTML={{
            __html: description ?? '',
          }}
        />

        {imageUrl && (
          <CardMedia src={imageUrl} alt="Image de présentation de l'Éstiam" component='img' />
        )}
      </Grid>
    </Grid>
  )
}
