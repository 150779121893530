import React from 'react'
import { Typography, Grid } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IMissionCard {
  title: string
  description: string
  imageUrl: string
}

export default function MissionCard({ title, description, imageUrl }: IMissionCard) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Grid container>
        <Grid item container alignItems='center' xs={2} mr={1}>
          {imageUrl && (
            <LazyLoadImage
              effect='opacity'
              src={imageUrl}
              width={'40px'}
              height={'40px'}
              alt='Icône cible'
            />
          )}
        </Grid>
        <Grid item container xs={9} alignItems='center'>
          <Typography
            ml={1}
            variant='body1'
            fontWeight='bold'
            color='black'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Grid>
      </Grid>
      <Grid item mt={2}>
        <Typography
          variant='body2'
          color='black'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Grid>
    </Grid>
  )
}
