import { Button, Grid } from '@mui/material'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'
import RouterLink from '../router-link/RouterLink'

interface ISuccessStoriesBtn {
  text: string
  mobile?: boolean
}

export default function SuccessStoriesBtn({ text, mobile }: ISuccessStoriesBtn) {
  const useStyles = makeStyles()(() => ({
    button: {
      padding: '7px',
      minHeight: '30px',
      width: '100%',
      borderRadius: 0,
      color: mobile ? colors.white : colors.orange,
      border: `1.5px solid ${mobile ? colors.white : colors.orange}`,
      background: mobile ? colors.orangeGradient : 'transparent',
      position: 'relative',
      fontWeight: 'bold',
      fontFamily: 'IBMPlexSans',
      textTransform: 'uppercase',
      textAlign: 'center',
      '&:hover': {
        color: mobile ? colors.orange : colors.white,
        boxShadow: 'none',
        backgroundColor: mobile ? colors.white : colors.orange,
        border: `1.5px solid ${mobile ? colors.white : colors.orange}`,
      },
    },
  }))

  const { classes } = useStyles()

  return (
    <Grid item xs={10} sm={8}>
      <Button
        component={RouterLink}
        to='/references-42c'
        variant='outlined'
        className={classes.button}
      >
        {text}
      </Button>
    </Grid>
  )
}
