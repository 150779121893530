import useSWR from 'swr'
import { SecondBanderole } from '../../shared/types/banderole/Banderole'

const FETCH_SECOND_BANDEROLE_URL = `${process.env.REACT_APP_BASE_URL}/second-banderole`

export async function fetchAllSecondBanderole() {
  const response = await fetch(`${FETCH_SECOND_BANDEROLE_URL}/all`)
  return response.json()
}

export function useSecondBanderole() {
  const { data: [secondBanderole] = [] } = useSWR<SecondBanderole[]>(
    'api/second-banderole/all',
    fetchAllSecondBanderole
  )

  return { secondBanderole }
}
