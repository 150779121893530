import useSWR from 'swr'
import { TalkingAboutUs } from '../../shared/types/talking-about-us/TalkingAboutUs'

const FETCH_TALKING_ABOUT_US_URL = `${process.env.REACT_APP_BASE_URL}/talking-about-us`

export async function fetchAllTalkingAboutUs(): Promise<TalkingAboutUs[]> {
  const response = await fetch(`${FETCH_TALKING_ABOUT_US_URL}/all`)
  return await response.json()
}

export function useTalkingAboutUs() {
  const { data: talkingAboutUs } = useSWR<TalkingAboutUs[]>(
    '/api/talking-about-us/all',
    fetchAllTalkingAboutUs
  )

  return { talkingAboutUs }
}
