import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { Container, Grid, useMediaQuery } from '@mui/material'

import { useClients } from '../../../hooks/clients/useClients'
import { ClientType } from '../../../shared/types/client/Client'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { theme } from '../../../assets/themes/theme'
import MainTitle from '../custom-titles/MainTitle'

const useStyles = makeStyles()({
  container: {
    borderTopStyle: 'none',
    borderBottomStyle: 'none',
    paddingBottom: '50px',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
  },
})

interface IClients {
  title: boolean
  partnerType: ClientType
}

export default function Clients({ title, partnerType }: IClients) {
  const { classes } = useStyles()
  const { clients = [] } = useClients()
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumFormat = useMediaQuery(theme.breakpoints.only('sm'))

  return (
    <Container disableGutters maxWidth='xl' className={classes.container}>
      {title && (
        <Grid container justifyContent='center'>
          <Grid item container xs={10}>
            <MainTitle text={'Ils nous font confiance'} titleColor='orange' />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent='center'>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          spacing={2}
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={12}
        >
          {clients
            .filter(({ partner }) => partner === partnerType)
            .slice(0, isMobileFormat ? 4 : isMediumFormat ? 6 : 8)
            .map(({ logoUrl }, index) => (
              <Grid
                item
                container
                key={index}
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={3}
                justifyContent='center'
                alignItems='center'
              >
                {logoUrl && (
                  <LazyLoadImage
                    effect='opacity'
                    src={logoUrl}
                    alt="Logo d'un client"
                    width='140'
                    height='70'
                    className={classes.image}
                  />
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Container>
  )
}
