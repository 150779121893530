import useSWR from 'swr'
import { NavbarElement } from '../../shared/types/navbar/NavbarElement'

const FETCH_NAVBAR_ELEMENTS_URL = `${process.env.REACT_APP_BASE_URL}/navbar-element`

export async function fetchAllNavbarElements(): Promise<NavbarElement[]> {
  const response = await fetch(`${FETCH_NAVBAR_ELEMENTS_URL}/all`)
  return await response.json()
}

export function useNavbarElements() {
  const { data: navbarElements } = useSWR<NavbarElement[]>(
    '/api/navbar-element/all',
    fetchAllNavbarElements
  )

  return { navbarElements }
}
