import useSWR from 'swr'
import { MultiSolution } from '../../shared/types/multi-solution/MultiSolution'

const FETCH_MULTI_SOLUTIONS_URL = `${process.env.REACT_APP_BASE_URL}/technical-solution`

export async function fetchAllMultiSolutions(): Promise<MultiSolution[]> {
  const response = await fetch(`${FETCH_MULTI_SOLUTIONS_URL}/all`)
  return await response.json()
}

export async function fetchFavoriteMultiSolutions(): Promise<MultiSolution[]> {
  const response = await fetch(`${FETCH_MULTI_SOLUTIONS_URL}/all/favorite`)
  return await response.json()
}

export async function fetchMultiSolutionBySlug(multiSolutionSlug: string): Promise<MultiSolution> {
  const response = await fetch(`${FETCH_MULTI_SOLUTIONS_URL}/slug/${multiSolutionSlug}`)
  return await response.json()
}

export function useMultiSolutions() {
  const { data: multiSolutions } = useSWR<MultiSolution[]>(
    '/api/technical-solution/all',
    fetchAllMultiSolutions
  )

  const { data: favoriteMultiSolutions } = useSWR<MultiSolution[]>(
    '/api/technical-solution/all/favorite',
    fetchFavoriteMultiSolutions
  )
  return { multiSolutions, favoriteMultiSolutions }
}

export function useMultiSolutionBySlug(multiSolutionSlug: string) {
  const { data: multiSolution } = useSWR<MultiSolution>(
    `/api/technical-solution/slug/${multiSolutionSlug}`,
    () => fetchMultiSolutionBySlug(multiSolutionSlug)
  )
  return { multiSolution }
}
