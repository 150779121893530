import { MapMarker } from '../types/map-marker/MapMarker'

export const constantMarkers: MapMarker[] = [
  {
    _id: '1',
    latitude: 48.8755127,
    longitude: 2.4111467,
    firstAddress: '31 Rue Paul Meurice',
    city: '75020 Paris',
    country: 'France',
    email: 'paris@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_PARIS.png',
    business: 'ESTIAM',
  },
  {
    _id: '2',
    latitude: 48.8835868,
    longitude: 2.2630698,
    firstAddress: '171 avenue Charles de Gaulle',
    city: '92200 Neuilly-sur-Seine',
    country: 'France',
    email: 'neuilly@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_NEUILLY.png',
    business: 'ESTIAM',
  },
  {
    _id: '3',
    latitude: 48.9325061,
    longitude: 2.3553797,
    firstAddress: '6-8 place de la Résistance',
    city: '93200 Saint-Denis',
    country: 'France',
    email: 'saintdenis@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_SAINT-DENIS.png',
    business: 'ESTIAM',
  },
  {
    _id: '4',
    latitude: 48.5430464,
    longitude: 2.6566916,
    firstAddress: '36 rue Saint-Barthélémy',
    city: '77000 Melun',
    country: 'France',
    email: 'melun@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_MELUN.png',
    business: 'ESTIAM',
  },
  {
    _id: '5',
    latitude: 47.103258,
    longitude: 2.40522,
    firstAddress: '52 av. de la Libération',
    city: '18000 Bourges',
    country: 'France',
    email: 'bourges@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_BOURGES.png',
    business: 'ESTIAM',
  },
  {
    _id: '6',
    latitude: 45.736241,
    longitude: 4.8368196,
    firstAddress: '181-203 av. Jean-Jaurès',
    city: '69007 Lyon',
    country: 'France',
    email: 'lyon@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_LYON.png',
    business: 'ESTIAM',
  },
  {
    _id: '7',
    latitude: 49.098228,
    longitude: 6.159061,
    firstAddress: '10 rue Monseigneur Heintz',
    city: '57950 Montigny-lès-Metz',
    country: 'France',
    email: 'metz@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_METZ.png',
    business: 'ESTIAM',
  },
  {
    _id: '8',
    latitude: 43.228861,
    longitude: 0.0578791,
    firstAddress: '24 avenue d’Azereix',
    city: '65000 Tarbes',
    country: 'France',
    email: 'tarbes@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_TARBES.png',
    business: 'ESTIAM',
  },
  {
    _id: '9',
    latitude: 46.2056244,
    longitude: 6.1368434,
    firstAddress: '10 Quai du Seujet',
    city: 'CH-1201 Genève',
    country: 'Suisse',
    email: 'geneve@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_GENEVA.png',
    business: 'ESTIAM',
  },
  {
    _id: '10',
    latitude: 38.95589479869739,
    longitude: -77.38896490163141,
    firstAddress: '205 Van Buren St #140',
    city: 'Herndon, VA 20170',
    country: 'États-Unis',
    email: ' washington@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_Washington-DC.png',
    business: 'ESTIAM',
  },
  {
    _id: '11',
    latitude: 13.067403,
    longitude: 77.650961,
    firstAddress: 'DR Bendre Layout, Narayanapura',
    city: 'Bengaluru Karnataka 560077',
    country: 'Inde',
    email: 'bangalore@estiam.com',
    logoUrl: '/logos-estiam/estiam_logo_BANGALORE.png',
    business: 'ESTIAM',
  },
  {
    _id: '12',
    latitude: 48.8755127,
    longitude: 2.4111467,
    firstAddress: '31 Rue Paul Meurice',
    city: '75020 Paris',
    country: 'France',
    logoUrl: '/logos-42c/logo42nav.png',
    phone: '+33 (0)1 45 18 33 00',
    business: '42C',
  },
  {
    _id: '13',
    latitude: 33.5927189,
    longitude: -7.6322875,
    firstAddress: "62 boulevard d'Anfa",
    secondAddress: 'Immeuble Bab Abdelaziz',
    city: 'Casablanca 20250',
    country: 'Maroc',
    logoUrl: '/logos-42c/logo42nav.png',
    phone: '+212 522 42 44 80',
    business: '42C',
  },
]
