import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useEstiam } from '../../hooks/estiam/useEstiam'

export default function Formations() {
  const { estiamData = [] } = useEstiam()
  const { formations } = estiamData[0] ?? {}

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={10} mt={10}>
        <Grid item xs={12}>
          <Box mb={5} component='header'>
            <Typography color='textSecondary' variant='h4' align='left' fontWeight='bold'>
              Les formations
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={7}>
          {formations?.map(({ formationType: id, title, description }) => (
            <Grid item xs={12} md={6} key={id}>
              <Typography
                variant='body2'
                fontWeight='bold'
                color='black'
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
              <Typography
                variant='body2'
                color='black'
                mt={2}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
