import React from 'react'

import { Typography, Box } from '@mui/material'
import colors from '../../../assets/styles/styles.module.scss'
import { makeStyles } from 'tss-react/mui'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const useStyles = makeStyles()({
  modal: {
    background: colors.white,
  },
  title: {
    position: 'absolute',
    bottom: '40px',
    left: '10px',
    color: colors.white,
    borderTop: `1px solid ${colors.white}`,
    width: '80%',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingTop: '5px',
  },
  category: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: colors.white,
    background: colors.orangeGradient,
    padding: '5px 15px',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '12px',
  },
})

interface IReferenceCard {
  _id: string
  title: string
  imageUrl: string
  category: string
  isModal?: boolean
}

export default function ReferenceCard({
  title,
  imageUrl,
  category,
  isModal = false,
}: IReferenceCard) {
  const { classes } = useStyles()

  return (
    <Box className={isModal ? classes.modal : undefined}>
      <Box
        height={{
          xl: '400px',
          md: '350px',
          sm: '400px',
          xs: '300px',
        }}
      >
        {imageUrl && (
          <LazyLoadImage
            effect='opacity'
            src={imageUrl}
            alt={title}
            width={'100%'}
            height={'100%'}
            style={{
              objectFit: 'cover',
            }}
          />
        )}
        <Typography
          align='center'
          className={classes.category}
          dangerouslySetInnerHTML={{
            __html: category,
          }}
        />
        <Typography
          align='center'
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </Box>
    </Box>
  )
}
