import React from 'react'
import { Container, Box, Grid, useMediaQuery } from '@mui/material'

import JobOffers from '../components/common/job-offers/all/JobOffers'
import SocialMedia from '../components/common/social-media/SocialMedia'
import Map from '../components/common/map/Map'
import { theme } from '../assets/themes/theme'

interface ICustomFooter {
  page?: string
}

export default function CustomFooter({ page }: ICustomFooter) {
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Container maxWidth='xl' disableGutters>
        <Box component='section' mb={5} mt={5}>
          <Grid
            item
            container
            justifyContent='center'
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={10}
            sx={{ margin: 'auto' }}
          >
            <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
              <JobOffers page={encodeURIComponent(page ?? '')} />
            </Grid>

            {!isMobileFormat && (
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                lg={6}
                xl={6}
                mt={{ xl: 0, lg: 0, md: 0, sm: 5, xs: 5 }}
              >
                <SocialMedia />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      {!isMobileFormat && (
        <Map businessType='42C' zoom={3} focus={[39.369673648067455, 2.8468790869562737]} />
      )}
    </>
  )
}
