import React from 'react'

import RouterLink from '../../components/common/router-link/RouterLink'
import { Box, Link } from '@mui/material'
import colors from '../../assets/styles/styles.module.scss'
import CustomFooter from '../CustomFooter'
import Banderole from '../../components/common/banderoles/Banderole'
import SuccessStories from '../../components/common/success-stories/SuccessStories'
import JobOffersFilter from '../../components/job-offers/common/JobOffersFilter'
import { useBanderole } from '../../hooks/banderole/useBanderoles'
import { joinUsPage } from '../../shared/constants/pages-titles'
import SEO from '../../components/common/seo/SEO'
import { useViews } from '../../hooks/views/useViews'
import Advantages from '../../components/join-us/Advantages'
import Adventure from '../../components/join-us/Adventure'
import OurJobs from '../../components/join-us/OurJobs'
import Testimony from '../../components/join-us/Testimony'
import MatchCvBtn from '../../components/common/matchCV/MatchCVBtn'

export default function JoinUsPage() {
  const { pageBanderole } = useBanderole('JOIN_US')
  const { imageUrl, title } = pageBanderole ?? {}
  const { view } = useViews('JOIN_US')
  const { metaTitle, metaDescription } = view ?? {}

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <Banderole
        customImageUrl={imageUrl}
        customTitle={title}
        displayContactUsButton={false}
        category={"OFFRES D'EMPLOI"}
      />
      <MatchCvBtn />
      <Link to='/offre-demploi-42c' component={RouterLink}>
        <JobOffersFilter />
      </Link>
      <Adventure />
      <OurJobs />
      <Box
        sx={{
          background: '#f5f5f5',
          paddingTop: '50px',
        }}
      >
        <SuccessStories />
      </Box>
      <Box
        sx={{
          background: colors.white,
          m: '-150px auto auto auto',
        }}
      >
        <Advantages />
      </Box>
      <Testimony />
      <CustomFooter page={joinUsPage} />
    </>
  )
}
