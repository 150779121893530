import React from 'react'

import { Container, useMediaQuery, Grid } from '@mui/material'
import { theme } from '../../../assets/themes/theme'
import colors from '../../../assets/styles/styles.module.scss'
import MainTitle from '../custom-titles/MainTitle'
import SuccessStoriesBtn from './SuccessStoriesBtn'
import SuccessStory from '../reference/Reference'
import Carousel from 'react-material-ui-carousel'
import {
  useSuccessStories,
  useSuccessStoriesFilter,
} from '../../../hooks/success-stories/useSuccessStories'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  gridItem: {
    textAlign: 'center',
  },
  mobileGridItem: {
    zIndex: 0,
    margin: 'auto',
    textAlign: 'center',
  },
})

interface ISuccessStories {
  page?: string
}

export default function SuccessStories({ page }: ISuccessStories) {
  const { favoriteSuccessStories } = useSuccessStories()
  const { filteredSuccessStories } = useSuccessStoriesFilter({
    page,
  })

  const [firstSuccessStory, secondSuccessStories, thirdSuccessStories] =
    filteredSuccessStories?.sort(() => Math.random() - 0.5) ?? []

  const displayedSuccessStories =
    page && filteredSuccessStories?.length
      ? [firstSuccessStory, secondSuccessStories, thirdSuccessStories]
      : favoriteSuccessStories

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { classes } = useStyles()

  return (
    <Container
      maxWidth={isMobileFormat ? 'xs' : 'xl'}
      disableGutters={isMobileFormat ? false : true}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '20px',
      }}
    >
      <Grid
        item
        xs={10}
        container={isMobileFormat ? false : true}
        m='auto'
        pt={isMobileFormat ? 5 : 0}
      >
        {!isMobileFormat && (
          <Grid container mb={4}>
            <Grid item xs={12} sm={6} md={7}>
              <MainTitle text={'Success stories'} titleColor='orange' />
            </Grid>
            <Grid
              item
              container
              justifyContent={{ xs: 'center', sm: 'end' }}
              xs={12}
              sm={6}
              md={5}
              mt={{ xs: 1, sm: 0 }}
              mb={{ xs: 1, sm: 0 }}
            >
              <SuccessStoriesBtn text={'En savoir plus'} />
            </Grid>
          </Grid>
        )}

        {!isMobileFormat && (
          <Grid container>
            {displayedSuccessStories?.map(
              (successStory) =>
                successStory && (
                  <Grid
                    item
                    xs={10}
                    md={4}
                    key={successStory._id}
                    className={isMobileFormat ? classes.mobileGridItem : classes.gridItem}
                  >
                    <Grid container>
                      <SuccessStory
                        _id={successStory._id}
                        title={successStory.title}
                        imageUrl={successStory.imageUrl}
                        category={successStory.category}
                        description={successStory.description}
                        summary={successStory.summary}
                      />
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        )}

        {isMobileFormat && (
          <Grid item>
            <Carousel
              autoPlay
              indicators
              swipe
              cycleNavigation
              animation='slide'
              interval={6000}
              duration={1000}
              indicatorIconButtonProps={{
                style: {
                  margin: '6px',
                  color: colors.white,
                  borderRadius: '50%',
                  border: `1px solid ${colors.white}`,
                  background: colors.white,
                  width: '13px',
                  height: '13px',
                  zIndex: 100,
                  marginTop: '-60px',
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: colors.orange,
                },
              }}
              indicatorContainerProps={{
                style: {
                  margin: 0,
                  zIndex: 100,
                  textAlign: 'center',
                },
              }}
            >
              {displayedSuccessStories?.map(
                (successStory) =>
                  successStory && (
                    <Grid
                      item
                      xs={10}
                      md={4}
                      key={successStory._id}
                      className={isMobileFormat ? classes.mobileGridItem : classes.gridItem}
                    >
                      <Grid>
                        <SuccessStory
                          _id={successStory._id}
                          title={successStory.title}
                          imageUrl={successStory.imageUrl}
                          category={successStory.category}
                          description={successStory.description}
                          summary={successStory.summary}
                        />
                      </Grid>
                    </Grid>
                  )
              )}
            </Carousel>
          </Grid>
        )}

        {isMobileFormat && (
          <Grid container justifyContent={'center'}>
            <Grid item container justifyContent={'center'} xs={8} sm={8} mt={1}>
              <SuccessStoriesBtn text={'En savoir plus'} mobile />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
