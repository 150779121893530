import React from 'react'

import { Container, Grid, Typography, useMediaQuery } from '@mui/material'

import CustomFooter from '../CustomFooter'
import Actualities from '../../components/common/actualities/all/Actualities'
import Banderole from '../../components/common/banderoles/Banderole'
import Clients from '../../components/common/clients/Clients'
import LabMissions from '../../components/lab/LabMissions'
import LabPresentation from '../../components/lab/LabPresentation'
import { useBanderole } from '../../hooks/banderole/useBanderoles'
import { labPage } from '../../shared/constants/pages-titles'
import colors from '../../assets/styles/styles.module.scss'
import { theme } from '../../assets/themes/theme'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'

export default function LabPage() {
  const { pageBanderole } = useBanderole('LAB')
  const { imageUrl, title } = pageBanderole ?? {}
  const { view } = useViews('LAB')
  const { metaTitle, metaDescription } = view ?? {}
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <Banderole
        customImageUrl={imageUrl}
        customTitle={title}
        displayContactUsButton={false}
        category={'LE LAB'}
      />
      <Container maxWidth='lg'>
        <LabPresentation />
        <LabMissions />
      </Container>
      <Grid container justifyContent='center' mt={10}>
        <Grid item xs={10} sm={12}>
          <Typography color='textPrimary' variant='h4' align='center' fontWeight='bold' mb={4}>
            Ils nous font confiance
          </Typography>
        </Grid>
        <Clients title={false} partnerType={'LAB'} />
      </Grid>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={labPage} />
    </>
  )
}
