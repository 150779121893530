import useSWR from 'swr'
import { SocialMediaTwitter } from '../../shared/types/social-media/SocialMediaTwitter'

const FETCH_LATEST_TWEETS_URL = `${process.env.REACT_APP_BASE_URL}/social-media/twitter/tweets`

async function fetchAllLatestTweets() {
  const response = await fetch(`${FETCH_LATEST_TWEETS_URL}/all`)
  return response.json()
}

export function useSocialMediaTwitter() {
  const { data: latestTweets } = useSWR<SocialMediaTwitter[]>(
    '/api/social-media/twitter/tweets/all',
    fetchAllLatestTweets
  )
  return { latestTweets }
}
