import React from 'react'
import { find } from 'lodash'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useEstiam } from '../../hooks/estiam/useEstiam'
import { theme } from '../../assets/themes/theme'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function ComityMembers() {
  const { estiamData = [] } = useEstiam()
  const { comity } = estiamData[0] ?? {}

  const { comityMembers = [] } = comity ?? {}
  const estiamCeo = find(
    comityMembers,
    (comityMember) => comityMember?.firstName === 'Olivier' && comityMember?.lastName === 'Comes'
  )

  const membersWithoutCeo =
    comityMembers &&
    comityMembers.filter(
      (comityMember) => comityMember?.firstName !== 'Olivier' && comityMember?.lastName !== 'Comes'
    )

  const middleIndex = membersWithoutCeo ? Math.ceil(membersWithoutCeo.length / 2) : 0
  const firstHalfMembers = membersWithoutCeo.slice(0, middleIndex)
  const secondHalfMembers = membersWithoutCeo.slice(middleIndex, comityMembers.length)

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container justifyContent='center'>
      <Grid container justifyContent='center' item xs={10} md={11} mt={10}>
        <Grid container justifyContent={isMobileFormat ? 'center' : ''} item xs={12} md={6} xl={5}>
          <Grid
            item
            xs={10}
            sm={5}
            md={9}
            lg={8}
            xl={12}
            container
            justifyContent='center'
            mt={isMobileFormat ? 0 : 5}
          >
            <Grid item xs={12} md={9} xl={6}>
              <Grid item xs={12} md={10}>
                {estiamCeo?.imageUrl && (
                  <LazyLoadImage
                    effect='opacity'
                    src={estiamCeo?.imageUrl ?? '/logos-estiam/estiam.png'}
                    alt="Photo d'Olivier Comes"
                    width='100%'
                    height='100%'
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={12} mt={1}>
                {comity && comityMembers.length > 0 && (
                  <>
                    <Typography
                      color='black'
                      dangerouslySetInnerHTML={{
                        __html: estiamCeo?.quote ?? '',
                      }}
                    />
                    <Typography
                      variant='body2'
                      align='left'
                      mt={1}
                      sx={{
                        color: 'black',
                      }}
                    >
                      {estiamCeo?.firstName + ' ' + estiamCeo?.lastName + ' - ' + estiamCeo?.role}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          xl={5}
          justifyContent='center'
          mt={isMobileFormat ? 8 : 0}
        >
          <Grid item xs={10} md={12} xl={10}>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Les membres du comité
            </Typography>
            <Grid container mt={1} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='body2' fontWeight='bold' color='black' mt={0.5}>
                      {estiamCeo?.firstName + ' ' + estiamCeo?.lastName}
                    </Typography>
                    <Typography variant='body2' color='black'>
                      {estiamCeo?.role}
                    </Typography>
                  </Grid>
                  {firstHalfMembers.map(({ _id: id, firstName, lastName, role }) => (
                    <Grid item xs={12} key={id}>
                      <Typography variant='body2' fontWeight='bold' color='black' mt={0.5}>
                        {firstName + ' ' + lastName}
                      </Typography>
                      <Typography variant='body2' color='black'>
                        {role}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={1}>
                  {secondHalfMembers.map(({ _id: id, firstName, lastName, role }) => (
                    <Grid item xs={12} key={id}>
                      <Typography variant='body2' fontWeight='bold' color='black' mt={0.5}>
                        {firstName + ' ' + lastName}
                      </Typography>
                      <Typography variant='body2' color='black'>
                        {role}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
