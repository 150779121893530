import React from 'react'

import { useMediaQuery, Container, Grid, Typography } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'

import SEO from '../../components/common/seo/SEO'

import Actualities from '../../components/common/actualities/all/Actualities'
import ExpertsList from '../../components/experts/ExpertsList'
import CustomFooter from '../CustomFooter'
import { expertsPage } from '../../shared/constants/pages-titles'
import { useViews } from '../../hooks/views/useViews'
import { makeStyles } from 'tss-react/mui'
import HeaderPage from '../../components/common/header-page/HeaderPage'

const useStyles = makeStyles()({
  button: {
    position: 'relative',
    background: colors.orangeGradient,
    fontFamily: 'IBMPlexSans',
    padding: '5px',
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
  mobileExpertSection: {
    margin: 'auto',
  },
  expertSection: {
    margin: 'auto 11vw 2rem auto',
  },
})

export default function ExpertsPage() {
  const { view } = useViews('EXPERT')
  const { metaTitle, metaDescription } = view ?? {}

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { classes } = useStyles()

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <HeaderPage
        viewCategory='EXPERT'
        pageTitle='Notre <strong>Équipe</strong>'
        contactButton
        withDescription
      />
      <Container maxWidth='xl' disableGutters>
        <Grid
          item
          md={8}
          sm={10}
          xs={11}
          className={isMobileFormat ? classes.mobileExpertSection : classes.expertSection}
        >
          <Grid container justifyContent='center' style={{ margin: 'auto', display: 'flex' }}>
            <Grid
              item
              lg={4}
              md={5}
              sm={8}
              xs={11}
              mt={5}
              mb={6}
              ml={isMobileFormat ? '' : 5}
              container
              justifyContent='start'
            >
              <Typography
                variant={isMobileFormat ? 'h6' : 'h5'}
                align='left'
                sx={{
                  fontWeight: 'bold',
                  color: 'black',
                }}
              />
              <Typography
                variant='body2'
                align='left'
                mt={1}
                sx={{
                  color: 'black',
                }}
              />
            </Grid>
            <ExpertsList />
          </Grid>
        </Grid>
      </Container>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={expertsPage} />
    </>
  )
}
