import React, { useState } from 'react'

import { Box, Grid } from '@mui/material'
import RouterLink from '../../common/router-link/RouterLink'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface ITechnicalSolution {
  slug: string
  imageUrl: string
  subTitle: string
  gifUrl: string
}

export default function MultiSolution({ slug, imageUrl, subTitle, gifUrl }: ITechnicalSolution) {
  const [isHover, setIsHover] = useState(false)

  return (
    <Grid
      item
      md={12}
      xs={12}
      sx={{
        height: { xs: '60vh', sm: '45vh', md: '70vh', lg: '83vh', xl: '80vh' },
      }}
    >
      <RouterLink to={`/multi-solutions/${slug}`}>
        <Box sx={{ cursor: 'pointer' }} role='link' aria-label={subTitle}>
          <LazyLoadImage
            effect='opacity'
            src={isHover ? gifUrl : imageUrl}
            height={'100%'}
            width={'100%'}
            style={{ objectFit: 'cover' }}
            alt={subTitle}
            onMouseEnter={async () => setIsHover(true)}
            onMouseLeave={async () => setIsHover(false)}
          />
        </Box>
      </RouterLink>
    </Grid>
  )
}
