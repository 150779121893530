import { Grid } from '@mui/material'

import SocialMediaTwitter from './twitter/SocialMediaTwitter'
import MainTitle from '../custom-titles/MainTitle'

import { useSocialMediaTwitter } from '../../../hooks/social-media/useSocialMediaTwitter'

const SocialMedia = () => {
  const sectionTitle = 'Suivez-nous @42c'
  const { latestTweets } = useSocialMediaTwitter()

  return (
    <Grid container justifyContent='center'>
      <Grid item container>
        <MainTitle text={sectionTitle} titleColor='orange' />

        <Grid container>
          <Grid item xs={12} md={12} sm={12} xl={12} lg={12}>
            <SocialMediaTwitter
              tweets={latestTweets && latestTweets.length ? latestTweets?.slice(0, 1) : []}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default SocialMedia
