import useSWR from 'swr'
import { Actuality } from '../../shared/types/actuality/Actuality'

const FETCH_ACTUALITIES_URL = `${process.env.REACT_APP_BASE_URL}/actuality`

export async function fetchAllActualities(): Promise<Actuality[]> {
  const response = await fetch(`${FETCH_ACTUALITIES_URL}?perPage=3`)
  return await response.json()
}

export function useActualities() {
  const { data: actualities } = useSWR<Actuality[]>('api/actuality/all', fetchAllActualities)

  return { actualities }
}
