import React from 'react'
import './App.scss'
import './assets/styles/font.scss'
import { Container, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { theme } from './assets/themes/theme'
import Layout from './parts/Layout'
import Routes from './routes/Routes'
import ReactGA from 'react-ga4'

const App = () => {
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container component='main' disableGutters maxWidth={false}>
          <CssBaseline />
          <Layout>
            <Routes />
          </Layout>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default App
