import useSWR from 'swr'
import { JoinUs } from '../../shared/types/join-us/JoinUs'

const FETCH_JOIN_US_URL = `${process.env.REACT_APP_BASE_URL}/join-us`

export async function fetchAllJoinUs(): Promise<JoinUs[]> {
  const response = await fetch(`${FETCH_JOIN_US_URL}/all`)
  return await response.json()
}

export function useJoinUs() {
  const { data: joinUsData } = useSWR<JoinUs[]>('api/join-us/all', fetchAllJoinUs)

  return { joinUsData }
}
