import axios from 'axios'
import { ApplyForm, Apply } from '../../shared/types/apply/ApplyForm'

const APPLY_URL = `${process.env.REACT_APP_BASE_URL}/application`

export function useApplication() {
  async function addApplication(applicationFormValues: ApplyForm): Promise<Apply> {
    const applicationFormData = new FormData()

    Object.entries(applicationFormValues).forEach(([key, value]) => {
      applicationFormData.append(key, value)
    })

    try {
      const { data } = await axios.post<Apply>(APPLY_URL, applicationFormData, {
        headers: {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
        },
      })

      return data
    } catch (error: any) {
      throw error.response.data
    }
  }

  return { addApplication }
}
