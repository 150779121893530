import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import ReactGA from 'react-ga4'
import { Metric } from 'web-vitals'
import ScrollToTop from './shared/utils/ScrollToTop'

const TRACKING_ID = 'G-5CLJ676X6E'
ReactGA.initialize(TRACKING_ID)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function onPerfEntry(metric: Metric) {
  ReactGA.event({
    category: 'Web Vitals',
    action: metric.name,
    value: Math.round(metric.value),
    label: metric.id,
  })
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(onPerfEntry)
