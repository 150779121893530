import React, { useState } from 'react'

import { Button, Grid, useMediaQuery } from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { theme } from '../../../assets/themes/theme'
import colors from '../../../assets/styles/styles.module.scss'
import Carousel from 'react-material-ui-carousel'

import Paragraph from '../../common/custom-paragraphs/Paragraph'
import MainTitle from '../../common/custom-titles/MainTitle'
import MultiSolution from '../single-multi-solution/MultiSolution'

import { useMultiSolutions } from '../../../hooks/multi-solutions/useMultiSolutions'
import { useViews } from '../../../hooks/views/useViews'
import { makeStyles } from 'tss-react/mui'
import RouterLink from '../../common/router-link/RouterLink'

const useStyles = makeStyles()({
  buttonText: {
    border: `1px solid ${colors.orange}`,
    borderRadius: 0,
    color: colors.orange,
    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: colors.orange,
      border: `1px solid ${colors.orange}`,
      color: colors.white,
    },
  },
  buttonAll: {
    backgroundColor: colors.orange,
    border: `1px solid ${colors.orange}`,
    color: 'white',

    fontFamily: 'IBMPlexSans',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    borderRadius: 0,
    '&:hover': {
      border: `1px solid ${colors.orange}`,
      color: colors.orange,
      backgroundColor: colors.white,
    },
  },
  carousel: { minHeight: '100%', position: 'relative' },
})

export default function MultiSolutions() {
  const { classes } = useStyles()

  const { view } = useViews('TECHNICAL_SOLUTION')

  const { title, description } = view ?? {}

  const { favoriteMultiSolutions = [] } = useMultiSolutions()

  const [technicalSolutionIndex, setTechnicalSolutionIndex] = useState(0)

  const [autoPlay, setAutoPlay] = useState(true)

  function FormattedParagraph({ description }: { description: string }) {
    const isMediumFormat = useMediaQuery(theme.breakpoints.down('lg'))
    if (isMediumFormat) {
      return <Paragraph text={description?.slice(0, 300).concat('...') ?? ''} textColor='black' />
    } else {
      return <Paragraph text={description ?? ''} textColor='black' />
    }
  }
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumFormat = useMediaQuery(theme.breakpoints.only('sm'))
  const isLargeFormat = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <Grid container>
        {isLargeFormat && (
          <Grid
            item
            container
            md={6}
            lg={6}
            alignItems='center'
            justifyContent='center'
            pt={{ xl: 4, md: 3 }}
            pb={7}
            mb={3}
            minHeight='45vh'
          >
            <Grid item md={7} lg={7}>
              <Grid>
                <Grid>
                  <MainTitle text={title ?? ''} titleColor='orange' />
                </Grid>
                <Grid>
                  <FormattedParagraph description={description ?? ''} />
                </Grid>
                <Grid container spacing={1}>
                  {favoriteMultiSolutions?.map(({ _id: id, title }, index) => (
                    <Grid item key={id}>
                      <Button
                        className={classes.buttonText}
                        variant='outlined'
                        onClick={() => {
                          setAutoPlay(false)

                          setTechnicalSolutionIndex(index)

                          setTimeout(() => {
                            setAutoPlay(true)
                          }, 10000)
                        }}
                      >
                        {title}
                      </Button>
                    </Grid>
                  ))}
                  <Grid item component={RouterLink} to='/multi-solutions'>
                    <Button className={classes.buttonAll} variant='outlined'>
                      Tout afficher
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {favoriteMultiSolutions.length === 0 ? (
          ''
        ) : (
          <Grid item md={6} lg={6} xs={12} className={classes.carousel}>
            <Carousel
              onChange={(now) => setTechnicalSolutionIndex(now ?? 0)}
              height={isMobileFormat ? '50vh' : isMediumFormat ? '65vh' : '80vh'}
              autoPlay={autoPlay}
              index={technicalSolutionIndex}
              interval={6000}
              duration={1000}
              navButtonsAlwaysVisible={true}
              navButtonsWrapperProps={{
                style: {
                  bottom: '0',
                  position: 'absolute',
                  top: 'unset',
                  height: isMobileFormat ? '40px' : '50px',
                  width: '50%',
                },
              }}
              navButtonsProps={{
                style: {
                  color: 'white',
                  width: '100%',
                  display: 'flow-root',
                  position: 'absolute',
                  borderColor: 'transparent',
                  background: 'transparent',
                  cursor: 'pointer',
                },
              }}
              NavButton={({ onClick, className, style, next, prev }) => {
                return (
                  <button onClick={() => onClick()} className={className} style={style}>
                    {next && (
                      <ArrowForwardIos
                        style={{
                          width: isMobileFormat ? '25px' : '30px',
                          height: isMobileFormat ? '25px' : '30px',
                          padding: '5px',
                          background: 'transparent',
                          border: `1px solid ${colors.white}`,
                          borderRadius: '50%',
                          float: 'left',
                        }}
                      />
                    )}
                    {prev && (
                      <ArrowBackIosNew
                        style={{
                          width: isMobileFormat ? '25px' : '30px',
                          height: isMobileFormat ? '25px' : '30px',
                          padding: '5px',
                          background: 'transparent',
                          border: `1px solid ${colors.white}`,
                          borderRadius: '50%',
                          float: 'right',
                        }}
                      />
                    )}
                  </button>
                )
              }}
              indicatorContainerProps={{
                style: {
                  textAlign: 'right',
                  width: '17px',
                  height: '129px',
                  position: 'absolute',
                  zIndex: 99,
                  top: '33%',
                  left: isLargeFormat ? '55px' : '30px',
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  position: 'relative',
                  left: '-55px',
                  color: colors.orange,
                  borderLeft: '5px solid',
                  borderLeftColor: colors.orange,
                  borderLeftWidth: '50px',
                  paddingLeft: '5px',
                  lineHeight: '2px',
                },
              }}
              IndicatorIcon={favoriteMultiSolutions?.map((_, index) => `0${index + 1}`)}
              indicatorIconButtonProps={{
                style: {
                  color: '#FFF',
                  fontWeight: 'bold',
                  fontFamily: 'IBMPlexSans',
                  fontSize: '15px',
                },
              }}
            >
              {favoriteMultiSolutions?.map(({ slug, imageUrl, subTitle, gifUrl }) => (
                <MultiSolution
                  key={slug}
                  imageUrl={imageUrl}
                  gifUrl={gifUrl ?? imageUrl}
                  subTitle={subTitle}
                  slug={slug}
                />
              ))}
            </Carousel>
          </Grid>
        )}
        {!isLargeFormat && (
          <Grid
            item
            container
            xs={12}
            alignItems='center'
            justifyContent='center'
            pt={{ sm: 3, xs: 4 }}
            pb={1}
            mb={3}
          >
            <Grid item xs={10}>
              <Grid>
                <Grid>
                  <MainTitle text={title ?? ''} titleColor='orange' />
                </Grid>
                <Grid>
                  <FormattedParagraph description={description ?? ''} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
