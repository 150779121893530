import React from 'react'

import { Grid, Container, Typography, useMediaQuery } from '@mui/material'

import CustomFooter from '../CustomFooter'
import Actualities from '../../components/common/actualities/all/Actualities'

import { useViews } from '../../hooks/views/useViews'
import { legalNoticesPage } from '../../shared/constants/pages-titles'
import colors from '../../assets/styles/styles.module.scss'
import { theme } from '../../assets/themes/theme'
import SEO from '../../components/common/seo/SEO'
import HeaderPage from '../../components/common/header-page/HeaderPage'

export default function LegalNoticesPage() {
  const { view } = useViews('LEGAL_NOTICES')

  const { metaTitle, metaDescription, description } = view ?? {}
  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />

      <HeaderPage viewCategory='LEGAL_NOTICES' pageTitle={'<strong>Mentions Légales</strong>'} />

      <Container maxWidth='xl' sx={{ mb: 10 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={10}>
            <Typography
              color='black'
              dangerouslySetInnerHTML={{
                __html: description ?? 'Contenu des mentions légales',
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}
      <CustomFooter page={legalNoticesPage} />
    </>
  )
}
