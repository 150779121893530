import useSWR from 'swr'
import { ActivitySector } from '../../shared/types/activity-sector/ActivitySector'

const FETCH_ACTIVITY_SECTORS_URL = `${process.env.REACT_APP_BASE_URL}/activity-sector`

export async function fetchAllActivitySectors(): Promise<ActivitySector[]> {
  const response = await fetch(`${FETCH_ACTIVITY_SECTORS_URL}/all`)
  return await response.json()
}

export async function fetchFavoriteActivitySectors(): Promise<ActivitySector[]> {
  const response = await fetch(`${FETCH_ACTIVITY_SECTORS_URL}/all/favorite`)

  return await response.json()
}

export async function fetchActivitySectorBySlug(
  activitySectorSlug: string
): Promise<ActivitySector> {
  const response = await fetch(`${FETCH_ACTIVITY_SECTORS_URL}/slug/${activitySectorSlug}`)
  return await response.json()
}

export function useActivitySectors() {
  const { data: activitySectors } = useSWR<ActivitySector[]>(
    '/api/activity-sector/all',
    fetchAllActivitySectors
  )
  const { data: favoriteActivitySectors } = useSWR<ActivitySector[]>(
    '/api/activity-sector/all/favorite',
    fetchFavoriteActivitySectors
  )
  return { activitySectors, favoriteActivitySectors }
}

export function useActivitySectorBySlug(activitySectorSlug: string) {
  const { data: activitySector } = useSWR<ActivitySector>(
    `/api/activity-sector/slug/${activitySectorSlug}`,
    () => fetchActivitySectorBySlug(activitySectorSlug)
  )
  return { activitySector }
}
