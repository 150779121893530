import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'

import { ActivitySector } from '../../shared/types/activity-sector/ActivitySector'
import { MultiSolution } from '../../shared/types/multi-solution/MultiSolution'

import Banderole from '../../components/common/banderoles/Banderole'
import SuccessStories from '../../components/common/success-stories/SuccessStories'
import Actualities from '../../components/common/actualities/all/Actualities'
import SectionTitle from '../../components/common/custom-titles/SectionTitle'
import Paragraph from '../../components/common/custom-paragraphs/Paragraph'
import CustomFooter from '../CustomFooter'

import { useSecondBanderole } from '../../hooks/banderole/useSecondBanderole'
import { useBanderole } from '../../hooks/banderole/useBanderoles'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import moment from 'moment'
import CountUp from 'react-countup'
import { InView } from 'react-intersection-observer'
import convertToSlug from '../../shared/utils/convertToSlug'
import SEO from '../../components/common/seo/SEO'

interface ISubPage {
  content: MultiSolution | ActivitySector
  category: string
}

export default function SubPage({ content, category }: ISubPage) {
  const {
    imageUrl,
    subTitle,
    title,
    description,
    metaTitle,
    metaDescription,
    subDescription,
    slug,
  } = content
  const { cardGifUrl } = content as MultiSolution

  const { category: contentCategory } = content as MultiSolution

  const { pageBanderole } = useBanderole(contentCategory)

  const { imageUrl: banderoleImageUrl } = pageBanderole ?? {}
  const { secondBanderole } = useSecondBanderole()
  const { secondBanderoleStatistics } = secondBanderole ?? {}
  const [firstStats, secondStats] = secondBanderoleStatistics ?? []

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <SEO metaDescription={metaDescription ?? subDescription} metaTitle={metaTitle ?? subTitle} />

      <Banderole
        customImageUrl={cardGifUrl ?? banderoleImageUrl ?? imageUrl}
        customTitle={subTitle}
        displayContactUsButton={false}
        category={category}
        subpage
      />

      <Box>
        <Container disableGutters maxWidth='xl' sx={{ alignItems: 'center' }}>
          <Grid
            container
            item
            alignItems='center'
            sx={{ margin: '4rem auto 4rem auto' }}
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
          >
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} justifyContent='center' container>
              <Stack direction='column' spacing={2}>
                <Grid item>
                  <SectionTitle text={title} titleColor='orange' />
                </Grid>

                <Grid item>
                  <Paragraph text={description} textColor='black' />
                </Grid>
              </Stack>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              container
              alignItems='center'
              direction={isMobileFormat ? 'row' : 'column'}
            >
              <Grid
                item
                xs={6}
                sm={6}
                container
                direction='column'
                alignItems='center'
                pt={6}
                pb={3}
              >
                <Grid>
                  <LazyLoadImage
                    effect='opacity'
                    src='/img/orangeTarget.png'
                    alt='Icône cible'
                    width={'70px'}
                    height={'70px'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InView>
                    {({ inView, ref }) => (
                      <Typography
                        variant='h4'
                        ref={ref}
                        color='textPrimary'
                        textAlign='center'
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {inView ? (
                          <CountUp useEasing={true} end={firstStats?.number} duration={3} />
                        ) : (
                          '0'
                        )}
                        {firstStats?.unit}
                      </Typography>
                    )}
                  </InView>
                  <Typography fontSize={15} textAlign='center' color='textPrimary'>
                    CA/ {moment().format('YYYY')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                container
                alignItems='center'
                direction='column'
                pt={6}
                pb={3}
              >
                <Grid item xs={6}>
                  <LazyLoadImage
                    effect='opacity'
                    src='/img/orangeStatIcon.png'
                    alt='logo'
                    width={'70px'}
                    height={'70px'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InView>
                    {({ inView, ref }) => (
                      <Typography
                        variant='h4'
                        ref={ref}
                        color='textPrimary'
                        textAlign='center'
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {inView ? (
                          <CountUp useEasing={true} end={secondStats?.number} duration={3} />
                        ) : (
                          '0'
                        )}
                        {secondStats?.unit} ans
                      </Typography>
                    )}
                  </InView>
                  <Typography fontSize={15} textAlign='center' color='textPrimary'>
                    de croissance
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Box
          sx={{
            background: isMobileFormat ? colors.orangeGradient : '#f5f5f5',
            marginBottom: isMobileFormat ? 0 : '-10rem',
            paddingTop: isMobileFormat ? 0 : '50px',
          }}
        >
          <SuccessStories page={encodeURIComponent(convertToSlug(title))} />
        </Box>
        {!isMobileFormat && (
          <Box
            sx={{
              background: colors.orangeGradient,
              padding: '15rem 0 5rem 0',
            }}
          >
            <Actualities />
          </Box>
        )}

        <CustomFooter page={slug} />
      </Box>
    </>
  )
}
