import React from 'react'

import { Grid, Typography, Box } from '@mui/material'
import SectionTitle from '../common/custom-titles/SectionTitle'
import { useLab } from '../../hooks/lab/useLab'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function LabPresentation() {
  const { labData = [] } = useLab()
  const { title, description, imageUrl } = labData[0] ?? {}

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={10} sm={10} md={8} lg={7} xl={8} mt={10}>
        <SectionTitle text='Présentation' titleColor='orange' />

        <Typography
          variant='body2'
          mt={5}
          fontWeight='bold'
          color='black'
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />

        <Typography
          variant='body2'
          color='black'
          mt={2}
          mb={4}
          dangerouslySetInnerHTML={{
            __html: description ?? '',
          }}
        />

        {imageUrl && (
          <Box sx={{ position: 'relative' }}>
            <LazyLoadImage
              effect='opacity'
              src={imageUrl}
              alt='Image de présentation du Lab'
              width={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
