import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Grid,
  Container,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import CustomFooter from '../CustomFooter'
import colors from '../../assets/styles/styles.module.scss'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'
import { useMatchingCv } from '../../hooks/matching-cv/useMatchingCv'

const useStyles = makeStyles()({
  button: {
    background: colors.orangeGradient,
    border: `1px solid ${colors.orangeGradient}`,
    padding: '5px',
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: 'bold',
    color: colors.white,
    borderRadius: 0,
    textTransform: 'uppercase',
    textDecoration: 'none',
    margin: 'auto',
    '&:hover': {
      color: colors.orange,
      boxShadow: 'none',
      background: colors.white,
      border: `1px solid ${colors.orange}`,
    },
  },
})
export default function MatchCVPage() {
  const { view } = useViews('MATCHING_CV')
  const { metaTitle, metaDescription } = view ?? {}
  const [isChecked, setIsChecked] = useState(false)
  const [file, setFile] = useState<any>([])
  const [showError, setShowError] = useState<boolean>(false)
  const [loading, setloading] = useState<boolean>(false)

  const errorMessage = 'Une erreur est survenue lors de la récupération des ressources.'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    const bodyFormData = new FormData()
    bodyFormData.append('resume', file)
    setloading(true)
    fetchMatchingCv(bodyFormData)
      .then((filteredJobOffers: any) => {
        setloading(false)
        navigate('/matche-ton-cv/resultats', {
          state: { filteredJobOffers: filteredJobOffers.jobs_matched },
        })
      })
      .catch(() => {
        setloading(false)
        setIsChecked(false)
        setShowError(true)
      })
  }
  const filesAccepted = [
    'application/pdf',
    'image/*',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noClick: true,
    multiple: false,
    accept: { filesAccepted },
    onDrop: (files) => {
      setFile(files[0])
    },
  })

  const files = acceptedFiles.map((file: any) => (
    <Typography mt={1} variant='body2' color='textPrimary' key={file.path}>
      - {file.path}
    </Typography>
  ))

  const { classes } = useStyles()
  const { fetchMatchingCv } = useMatchingCv()
  const navigate = useNavigate()

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ background: colors.orangeGradient }}
        pb={10}
      >
        <Container maxWidth='lg' disableGutters>
          <Grid container justifyContent='center' alignItems='center' mt={10}>
            <Grid item xs={12}>
              <Typography align='center' variant='h4' color='white' fontWeight='bold'>
                Matcher votre CV
              </Typography>
            </Grid>
            <Grid item xs={10} sm={5} md={4} mt={1}>
              <Typography align='center' variant='body1' color='white' fontWeight='bold'>
                Chargez votre CV et découvrez les offres qui pourraient vous correspondre
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              lg={12}
              mt={5}
              pt={6}
              pb={3}
              textAlign='center'
              sx={{
                background: 'white',
              }}
              {...getRootProps()}
            >
              <Grid item xs={12}>
                <img
                  src='/img/cloudUploadIcon.png'
                  width='100px'
                  height='80px'
                  alt='Icône de téléchargement'
                />
              </Grid>
              <Grid item xs={7} sm={3} md={2} mt={2} mr={'auto'} ml={'auto'}>
                <Typography variant='body2' color='black' fontWeight='bold' textAlign='center'>
                  Glisser-déposer votre CV dans cette zone ou
                </Typography>
                <Grid item xs={12} mt={2}>
                  <label htmlFor='up'>
                    <Button variant='outlined' component='label' className={classes.button}>
                      Charger un fichier
                      <input type='file' hidden {...getInputProps()} />
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography mt={2} fontWeight='bold' variant='body2' color='textPrimary'>
                  Fichier chargé :
                </Typography>
                {files}
              </Grid>
              <Grid container justifyContent='center' mt={5}>
                <Grid item xs={11}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <FormControlLabel
                      label={
                        <Typography variant='body2' color='gray'>
                          Vous acceptez la politique de confidentialité et de partager avec nos
                          services vos données personnelles présentes sur votre CV. L’analyse de vos
                          données permettra de trouver les offres en adéquation avec vos
                          expériences, métiers, compétences...
                        </Typography>
                      }
                      control={
                        <Checkbox
                          value='true'
                          size='small'
                          sx={{ color: 'gray' }}
                          checked={isChecked}
                          onChange={handleChange}
                        />
                      }
                    />
                  )}
                </Grid>
                {showError && (
                  <Grid item xs={11}>
                    <Typography sx={{ fontStyle: 'italic' }}>{errorMessage}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <CustomFooter />
    </>
  )
}
