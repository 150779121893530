import React from 'react'
import JobOffersPage from '../parts/pages/JobOffersPage'
import JobOfferPage from '../parts/pages/JobOfferPage'
import { useParams } from 'react-router-dom'

export default function JobOffers() {
  return <JobOffersPage />
}

export function JobOfferDetails() {
  const { slug = '' } = useParams()
  const [host, setHost] = React.useState('')

  React.useEffect(() => {
    setHost(window.location.hostname)
  }, [])

  return <JobOfferPage jobOfferSlug={slug} host={host} />
}
