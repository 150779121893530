import React from 'react'

import { Grid } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function EcovadisLogo() {
  return (
    <Grid container>
      <Grid alignItems='center' p={1}>
        <LazyLoadImage
          effect='opacity'
          alt='Pastille écovadis'
          src='/img/ecovadis.png'
          width='70px'
          height='70px'
        />
      </Grid>
    </Grid>
  )
}
