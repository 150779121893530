import React from 'react'
import colors from '../../assets/styles/styles.module.scss'
import { Container, Button, Typography, Grid } from '@mui/material'
import RouterLink from '../common/router-link/RouterLink'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  retryBtn: {
    background: 'transparent',
    border: `1px solid ${colors.white}`,
    color: colors.white,
    fontWeight: 'bold',
    fontSize: '12px',
    boxShadow: 'none',
    justifyContent: 'center',
    borderRadius: '0px',
    marginTop: '15px',
    padding: '7px 15px',
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.red,
      boxShadow: 'none',
    },
  },
})

interface IMatchCVResultBanderole {
  offersLength: number
}

export default function MatchCVResultBanderole({ offersLength }: IMatchCVResultBanderole) {
  const { classes } = useStyles()
  return (
    <Container
      style={{
        background: colors.orangeGradient,
        position: 'relative',
        height: '40vh',
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
      }}
      maxWidth={false}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h4' fontWeight='bold' color='white' textAlign='center' mb={1}>
            Matchez votre CV
          </Typography>
          {/* FIXME: Changer dynamiquement le nombre de compétences trouvées */}
          <Typography
            variant='body1'
            fontWeight='bold'
            color='white'
            textAlign='center'
            mt={1}
            mb={1}
          >
            30 compétences et {offersLength} offres trouvées
          </Typography>
          <Grid item xs={12} container justifyContent='center'>
            <Button component={RouterLink} to='/matche-ton-cv' className={classes.retryBtn}>
              Relancer la recherche
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
