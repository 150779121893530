import React, { useEffect, useState } from 'react'

import RouterLink from '../../common/router-link/RouterLink'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import classes from './MultiSolutionCard.module.scss'
import { theme } from '../../../assets/themes/theme'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface IMultiSolutionCard {
  slug: string
  subTitle: string
  subDescription?: string
  imageUrl: string
  gifUrl: string
  category: string
}

export default function MultiSolutionCard({
  slug,
  subTitle,
  subDescription,
  imageUrl,
  gifUrl,
}: IMultiSolutionCard) {
  const location = useLocation()
  const [img, setImg] = useState(imageUrl)
  const [displayedSubDescription, setDisplayedSubDescription] = useState('')

  const isSmFormat = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (subDescription) {
      setDisplayedSubDescription(subDescription)
    }
  }, [subDescription])

  return (
    <Grid
      component={RouterLink}
      to={`${location.pathname}/${slug}`}
      item
      sx={{ cursor: 'pointer', position: 'relative' }}
      onMouseEnter={async () => setImg(gifUrl)}
      onMouseLeave={async () => setImg(imageUrl)}
    >
      <Grid container>
        {imageUrl && (
          <LazyLoadImage
            height='100%'
            width='100%'
            style={{ objectFit: 'cover' }}
            src={img}
            alt={"Image d'une multi-solution : " + subTitle}
          />
        )}
      </Grid>
      <Grid
        container
        item
        alignItems='center'
        className={classes.cardText}
        sx={{ minHeight: isSmFormat ? '200px' : '270px' }}
      >
        <Grid container alignItems='center'>
          <Typography
            color='textPrimary'
            variant='h6'
            className={classes.subTitle}
            dangerouslySetInnerHTML={{
              __html: subTitle,
            }}
          />
        </Grid>
        <Grid container alignItems='center'>
          <Typography
            variant='body2'
            mt={1}
            className={classes.subDescription}
            dangerouslySetInnerHTML={{
              __html: displayedSubDescription,
            }}
          />
        </Grid>
        <Button size='small' variant='contained' className={classes.button}>
          Découvrir
        </Button>
      </Grid>
    </Grid>
  )
}
