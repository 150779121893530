/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'

import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'
import colors from '../../assets/styles/styles.module.scss'
import Reference from '../../components/common/reference/Reference'
import CustomFooter from '../CustomFooter'
import Actualities from '../../components/common/actualities/all/Actualities'
import HeaderPage from '../../components/common/header-page/HeaderPage'

import { useTalkingAboutUs } from '../../hooks/talking-about-us/useTalkingAboutUs'
import { TalkingAboutUs } from '../../shared/types/talking-about-us/TalkingAboutUs'

import InfiniteScroll from 'react-infinite-scroller'
import { talkingAboutUsPage } from '../../shared/constants/pages-titles'
import { makeStyles } from 'tss-react/mui'
import { useViews } from '../../hooks/views/useViews'
import SEO from '../../components/common/seo/SEO'

const useStyles = makeStyles()({
  gridItem: {
    textAlign: 'center',
  },
  mobileGridItem: {
    margin: 'auto',
    textAlign: 'center',
    marginBottom: '10px',
  },
})

export default function TalkingAboutUsPage() {
  const { talkingAboutUs } = useTalkingAboutUs()
  const { view } = useViews('TALKING_ABOUT_US')
  const { metaDescription, metaTitle } = view ?? {}

  const [hasMore, setHasMore] = useState(true)
  const [displayedTalkingAboutUs, setDisplayedTalkingAboutUs] = useState<TalkingAboutUs[]>([])

  function displayMore() {
    if (talkingAboutUs) {
      const toAddElementsLength =
        talkingAboutUs.length - displayedTalkingAboutUs.length > 6
          ? 6
          : talkingAboutUs.length - displayedTalkingAboutUs.length

      if (displayedTalkingAboutUs.length < talkingAboutUs.length) {
        setTimeout(() => {
          setDisplayedTalkingAboutUs([
            ...displayedTalkingAboutUs,
            ...talkingAboutUs?.slice(
              displayedTalkingAboutUs.length,
              displayedTalkingAboutUs.length + toAddElementsLength
            ),
          ])
        }, 1000)
      } else {
        setHasMore(false)
      }
    }
  }

  useEffect(() => {
    if (talkingAboutUs) {
      if (talkingAboutUs.length <= 6) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      setDisplayedTalkingAboutUs([...talkingAboutUs?.slice(0, 6)])
    }
  }, [talkingAboutUs])

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))
  const { classes } = useStyles()

  return (
    <>
      <SEO
        {...{
          metaTitle,
          metaDescription,
        }}
      />
      <HeaderPage
        viewCategory='TALKING_ABOUT_US'
        pageTitle={'ON PARLE DE <strong>NOUS</strong>'}
        withDescription
      />

      <Grid mb={10}>
        <Container maxWidth='xl' disableGutters>
          <Grid container justifyContent='center'>
            <Grid item xs={10}>
              <InfiniteScroll
                loadMore={displayMore}
                hasMore={hasMore}
                loader={
                  <Box display='flex' alignItems='center' justifyContent='center' key={'loader'}>
                    <Typography variant='body1' color='textPrimary' fontWeight='bold' mt={3}>
                      Chargement en cours ...
                    </Typography>
                  </Box>
                }
              >
                <Grid container>
                  {displayedTalkingAboutUs ? (
                    <Grid container item xs={12} justifyContent='center'>
                      {displayedTalkingAboutUs.map(
                        ({
                          _id: id,
                          imageUrl,
                          title,
                          category,
                          summary,
                          description,
                          link,
                          fileUrl,
                        }) => (
                          <Grid
                            item
                            xs={10}
                            md={4}
                            key={id}
                            className={isMobileFormat ? classes.mobileGridItem : classes.gridItem}
                          >
                            <Grid container>
                              <Reference
                                title={title}
                                imageUrl={imageUrl}
                                category={category}
                                _id={id}
                                description={description ?? summary}
                                summary={summary}
                                articleLink={link}
                                fileLink={fileUrl}
                              />
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {!isMobileFormat && (
        <Grid
          sx={{
            background: colors.orangeGradient,
            padding: '5rem 0',
          }}
        >
          <Actualities />
        </Grid>
      )}

      <CustomFooter page={talkingAboutUsPage} />
    </>
  )
}
