import React from 'react'
import SocialNavbar from '../components/common/navbar/SocialNavbar'
import MenuNavbar from '../components/common/navbar/MenuNavbar'

export default function Navbar() {
  return (
    <>
      <SocialNavbar />
      <MenuNavbar />
    </>
  )
}
