import React from 'react'

import { Tweet } from 'react-twitter-widgets'
import { SocialMediaTwitter } from '../../../../shared/types/social-media/SocialMediaTwitter'

interface ILatestTweet {
  tweet: SocialMediaTwitter
}

export default function LatestTweet({ tweet }: ILatestTweet) {
  const tweetID: string = tweet.url.split('/')[5]

  return <Tweet tweetId={tweetID} options={{ lang: 'fr', width: 350 }} />
}
