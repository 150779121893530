import React from 'react'

import { CardMedia, Grid, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../assets/themes/theme'

import { useJoinUs } from '../../hooks/joins-us/useJoinUs'

export default function Testimony() {
  const { joinUsData = [] } = useJoinUs()
  const { testimony } = joinUsData[0] ?? {}

  const isMobileFormat = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container justifyContent='center' alignItems='center' mt={6} mb={6}>
      <Grid item xs={0} md={2} />
      <Grid item xs={10} sm={10} md={7} xl={5} container>
        <Grid item xs={12}>
          <Typography variant='h4' textAlign='center' color='textPrimary' fontWeight='bold'>
            {testimony?.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} mt={4} container justifyContent='center' alignItems='center'>
            {testimony && (
              <CardMedia
                component='iframe'
                src={'https://www.youtube.com/embed/' + testimony?.videoUrl}
                sx={{
                  width: '100%',
                  height: {
                    xl: '30rem',
                    lg: '30rem',
                    md: '25rem',
                    sm: '25rem',
                    xs: '15rem',
                  },
                  border: '0',
                }}
              />
            )}

            <Grid item container spacing={7} mt={2}>
              {testimony?.quotes.map(({ _id: id, quote, firstName, lastName, role }) => (
                <Grid item xs={12} sm={6} key={id}>
                  <Typography
                    variant={isMobileFormat ? 'h6' : 'h5'}
                    align='left'
                    sx={{
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    “{quote}”
                  </Typography>
                  <Typography
                    variant='body2'
                    align='left'
                    mt={1}
                    sx={{
                      color: 'black',
                    }}
                  >
                    {firstName + ' ' + lastName + ' - ' + role}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
