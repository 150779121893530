import useSWR from 'swr'
import { Advantage } from '../../shared/types/advantage/Advantage'

const FETCH_ADVANTAGES_URL = `${process.env.REACT_APP_BASE_URL}/advantage`

export async function fetchAllAdvantages(): Promise<Advantage[]> {
  const response = await fetch(`${FETCH_ADVANTAGES_URL}/all`)
  return await response.json()
}

export function useAdvantages() {
  const { data: advantages } = useSWR<Advantage[]>('api/advantage/all', fetchAllAdvantages)

  return { advantages }
}
